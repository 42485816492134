<template>
  <div class="border-none shadow-none max-w-[800px] bg-card"> 
    <template v-if="notifications">
      <div class="flex items-center justify-between p-4 border-b">
        <h2 class="text-lg font-semibold">Notificações</h2>
        <div class="space-x-2">
          <Button v-if="!hasScrollArea" @click="readAllNotifications" variant="ghost" size="sm">
            Ler todos
          </Button>
          <Button @click="deleteAllNotifications" v-if="notifications.data.length > 0" variant="ghost" size="sm">
            Limpar tudo
          </Button>
        </div>
      </div>
      <ScrollArea :class="hasScrollArea ? 'h-[400px]' : ''">
        <template v-if="notifications.data.length === 0">
          <div class="flex flex-col items-center justify-center h-[300px] text-center">
            <BellOff class="h-12 w-12 text-muted-foreground mb-2" />
            <p class="text-muted-foreground">Nenhuma notificação</p>
          </div>
        </template>
        <template v-else>
          <div
            v-for="(notification, index) in notifications.data"
            :key="notification.id"
            class="group"
            @click="openModal(notification)"
          >
            <div
              :class="[
                'flex items-start gap-4 p-4 hover:bg-muted/50 transition-colors',
                { 'bg-muted/20': !!notification.readAt }
              ]"
            >
              <div class="flex-shrink-0 mt-1">
                <div :class="[
                  'w-2 h-2 rounded-full',
                  notification.status == NotificationStatusEnum.READ ? '' : 'bg-primary animate-pulse'
                ]"></div>
              </div>
              <div class="flex-1 min-w-0">
                <h3 class="font-medium text-sm truncate">{{ notification.title }}</h3>
                <p class="text-sm text-muted-foreground line-clamp-2">{{ notification.message }}</p>
                <p class="text-xs text-muted-foreground mt-1">{{ timeAgo(notification.createdAt) }}</p>
              </div>
            </div>
            <Separator v-if="index < notifications.data.length - 1" />
          </div>
          <Button
            v-if="notifications.pagination.nextPage"
            @click="$emit('showMore')"
            :disabled="isLoadingNotifications"
            variant="ghost"
            :class="hasScrollArea ? 'max-w-xs' : ''"
            class="w-full transition-all duration-300 ease-in-out transform hover:scale-105"
          >
            <Loader2 v-if="isLoadingNotifications" class="w-4 h-4 mr-2 animate-spin" />
            <span v-else>Ver mais notificações</span>
          </Button>
        </template>
      </ScrollArea>
    </template>
    <SpinnerLoader v-else />

    <!-- Notification Modal -->
    <Dialog v-if="selectedNotification" v-model:open="isModalOpen">
      <DialogContent class="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{{ selectedNotification.title }}</DialogTitle>
          <DialogDescription>
            {{ timeAgo(selectedNotification.createdAt) }}
          </DialogDescription>
        </DialogHeader>
        <div class="mt-4">
          <p class="text-sm text-muted-foreground">{{ selectedNotification.message }}</p>
        </div>
        <DialogFooter>
          <Button @click="closeModal">Fechar</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
</div>
</template>

<script lang="ts" setup>
import { Button } from "@/components/ui/button";
import SpinnerLoader from "@/components/ui/loaders/SpinnerLoader.vue";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useDate } from "@/composables/useDate";
import { useNotification } from "@/composables/useNotification";
import { NotificationStatusEnum } from "@/enums/notifications/NotificationStatusEnum";
import { Notification } from "@/interfaces/notifications/Notification";
import { Pagination } from "@/interfaces/Pagination";
import { BellOff, Check, Loader2 } from "lucide-vue-next";
import { Separator } from "radix-vue";
import { ref } from "vue";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";

const emit = defineEmits(['showMore'])
const { timeAgo } = useDate()
const props = withDefaults(
  defineProps<{ hasScrollArea?: boolean }>(),
  {
    hasScrollArea: false
  }
)
const { notifications, deleteAllNotifications, isLoadingNotifications, readNotification, readAllNotifications } = useNotification()
const isModalOpen = ref(false)
const selectedNotification = ref<Notification | null>(null)

const closeModal = () => {
  isModalOpen.value = false
  selectedNotification.value = null
}

const openModal = (notification: Notification) => {
  selectedNotification.value = notification
  isModalOpen.value = true
  if (notification.status == NotificationStatusEnum.UNREAD) {
    readNotification(notification.id)
    notification.readAt =  new Date().toISOString()
    notification.status = NotificationStatusEnum.READ
  }
}
</script>