import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { type HTMLAttributes, provide } from 'vue'
import { useId } from 'radix-vue'
import { FORM_ITEM_INJECTION_KEY } from './injectionKeys'
import { cn } from '@/lib/utils'


export default /*@__PURE__*/_defineComponent({
  __name: 'FormItem',
  props: {
    class: {}
  },
  setup(__props: any) {

const props = __props

const id = useId()
provide(FORM_ITEM_INJECTION_KEY, id)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_unref(cn)('space-y-2', props.class))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})