import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { type HTMLAttributes, computed } from 'vue'
import { PaginationFirst, type PaginationFirstProps } from 'radix-vue'
import { ChevronsLeft } from 'lucide-vue-next'
import {
  Button,
} from '@/components/ui/button'
import { cn } from '@/lib/utils'


export default /*@__PURE__*/_defineComponent({
  __name: 'PaginationFirst',
  props: {
    asChild: { type: Boolean, default: true },
    as: {},
    class: {}
  },
  setup(__props: any) {

const props = __props

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(PaginationFirst), _normalizeProps(_guardReactiveProps(delegatedProps.value)), {
    default: _withCtx(() => [
      _createVNode(_unref(Button), {
        class: _normalizeClass(_unref(cn)('w-10 h-10 p-0', props.class)),
        variant: "outline"
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, () => [
            _createVNode(_unref(ChevronsLeft), { class: "h-4 w-4" })
          ])
        ]),
        _: 3
      }, 8, ["class"])
    ]),
    _: 3
  }, 16))
}
}

})