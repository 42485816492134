<template>
  <div class="bg-background p-4 rounded-lg">
    <ProfileComponent :topic="{ ...comment, author: { ...comment.author, avatar: authorAvatar } }" @delete="isDeleteConfirmationDialogOpen = true" />
    <p>{{ comment.content }}</p>
  </div>

  <Dialog :open="isDeleteConfirmationDialogOpen" @update:open="(open) => isDeleteConfirmationDialogOpen = open">
    <DialogContent class="sm:max-w-md">
      <DialogHeader>
        <div class="flex gap-2 items-center">
          <AlertTriangle class="h-6 w-6" />
          <DialogTitle class="text-2xl">Excluir Comentário</DialogTitle>
        </div>
        <DialogDescription class="py-2">
          Você está prestes a excluir esta comentário. Essa ação é irreversível e todos os dados relacionados a este comentário serão permanentemente removidos.
        </DialogDescription>
      </DialogHeader>
      <div class="flex items-center space-x-2">
        
      </div>
      <DialogFooter class="sm:justify-start">
        <DialogClose as-child>
          <Button type="button" variant="secondary">
            Cancelar
          </Button>
        </DialogClose>
        <Button type="button" :disabled="isDeletingComment" @click="deleteComment()">
          <Loader2 v-if="isDeletingComment" class="w-4 h-4 mr-2 animate-spin" />
          Excluir Comentário
        </Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>

<script setup lang="ts">
import { Button } from '@/components/ui/button';
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { toast } from '@/components/ui/toast';
import { useAxios } from '@/composables/useAxios';
import { ProfileMediaTypeEnum } from '@/enums/profiles/ProfileMediaTypeEnum';
import { ProfileComment } from '@/interfaces/profiles/ProfileComment';
import { AlertTriangle, Loader2, MoreHorizontalIcon, Trash2 } from 'lucide-vue-next';
import { computed, ref } from 'vue';
import ProfileComponent from '../../ProfileComponent.vue';

const props = defineProps<{ comment: ProfileComment }>()
const authorAvatar = computed(() =>
  props.comment.author.media?.find((m) => m.type === ProfileMediaTypeEnum.AVATAR)
);
const isDeletingComment = ref(false)
const axios = useAxios()
const isDeleteConfirmationDialogOpen = ref(false)
const emit = defineEmits(['delete'])

function deleteComment() {
  isDeletingComment.value = true

  axios.delete(`/profile/${props.comment.authorId}/comments/${props.comment.id}`)
    .then(() => {
      toast({
        title: 'Comentário excluido com sucesso',
        class: 'bg-green-500 text-white'
      })
      isDeleteConfirmationDialogOpen.value = false
      emit('delete')
    })
    .finally(() => isDeletingComment.value = false)
}
</script>