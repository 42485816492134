import { ref } from "vue";
import { useAxios } from "./useAxios";
import { Pagination } from "@/interfaces/Pagination";
import { TournamentRanking } from "@/interfaces/tournaments/TournamentRanking";

const axios = useAxios()
export const rankings = ref<Pagination<TournamentRanking>>()

export function useRanking() {
  async function getRankings(tournamentId: number, page = 1) {
    const response = await axios.get(`/tournaments/${tournamentId}/rankings`, { params: { page } })

    rankings.value = response.data
  }
  
  return {
    rankings,
    getRankings,
  }
}