<template>
  <NotificationCard @show-more="handleGetMoreNotifications" />
</template>

<script lang="ts" setup>
import { useNotification } from '@/composables/useNotification';
import NotificationCard from './NotificationCard.vue';

const { notifications, getMoreNotifications } = useNotification()

function handleGetMoreNotifications() {
  getMoreNotifications()
    .then((data) => {
      notifications.value = { data: [...notifications.value!.data, ...data.data ], pagination: data.pagination }
    })
}
</script>