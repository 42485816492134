<template>
  <div class="space-y-4">
    <template v-if="bankAccounts">
      <div v-if="bankAccounts.length > 0">
        <h3 class="text-lg font-semibold mb-2">Contas Bancárias Cadastradas</h3>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Banco</TableHead>
              <TableHead>Agência</TableHead>
              <TableHead>Conta</TableHead>
              <TableHead>Titular</TableHead>
              <TableHead>Tipo</TableHead>
              <TableHead>Ações</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow v-for="account in bankAccounts" :key="account.id">
              <TableCell>{{ getBankName(Number(account.bankId)) }}</TableCell>
              <TableCell>{{ account.agency }}</TableCell>
              <TableCell>{{ account.account }}-{{ account.accountDigit }}</TableCell>
              <TableCell>{{ account.holder }}</TableCell>
              <TableCell>{{ bankAccountTypeLabels[account.type] }}</TableCell>
              <TableCell>
                <Button variant="destructive" size="sm" @click="selectedBankAccountToDeleteId = account.id; isDeleteDialogConfirmationOpen = true">
                  Excluir
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <div v-else class="text-center py-4 text-gray-500">
        Nenhuma conta bancária cadastrada.
      </div>
    </template>
    <SpinnerLoader v-else />
    <form @submit.prevent="handleCreateBankAccount">
      <div class="space-y-4">
        <div class="space-y-2">
          <Label for="bankId">Banco</Label>
          <Select  v-model="newBankAccount.bankId">
            <SelectTrigger id="status" aria-label="Selecionar status">
              <SelectValue placeholder="Selecionar" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem v-for="bank in banks" :key="bank.id" :value="String(bank.id)">
                {{ bank.name }}
              </SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div class="grid sm:grid-cols-2 gap-4">
          <div class="space-y-2">
            <Label for="holder">Titular da Conta</Label>
            <Input id="holder" v-model="newBankAccount.holder" required placeholder="ex: João Silva" />
          </div>
          <div class="space-y-2">
            <Label for="document">CPF/CNPJ</Label>
            <Input id="document" v-model="newBankAccount.document" v-maska="newBankAccount.document.length <= 14 ? '###.###.###-###' : '##.###.###/####-##'" required placeholder="ex: 123.456.789-00" />
          </div>
        </div>
        <div class="grid sm:grid-cols-2 gap-4">
          <div class="space-y-2">
            <Label for="agency">Agência</Label>
            <Input id="agency" v-model="newBankAccount.agency" maxlength="4" required placeholder="ex: 1234" />
          </div>
          <div class="space-y-2">
            <Label for="account">Conta</Label>
            <Input id="account" v-model="newBankAccount.account" required placeholder="ex: 56789" />
          </div>
        </div>
        <div class="grid sm:grid-cols-2 gap-4">
          <div class="space-y-2">
            <Label for="accountDigit">Dígito da Conta</Label>
            <Input id="accountDigit" type="number" v-model="newBankAccount.accountDigit" required placeholder="ex: 0" maxlength="1" />
          </div>
          <div class="space-y-2">
            <Label for="type">Tipo de Conta</Label>
            <Select v-model="newBankAccount.type" placeholder="Selecionar">
              <SelectTrigger id="type" aria-label="Selecionar tipo de conta">
                <SelectValue placeholder="Selecionar" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="cashing">Corrente</SelectItem>
                <SelectItem value="saving">Poupança</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        <Button type="submit" :disabled="isCreatingBankAccount || isDisabled">
          <Loader2 v-if="isCreatingBankAccount" class="w-4 h-4 mr-2 animate-spin" />
          Adicionar Conta Bancária
        </Button>
      </div>
    </form>
    <Dialog v-if="selectedBankAccountToDeleteId" :open="isDeleteDialogConfirmationOpen" @update:open="(open) => isDeleteDialogConfirmationOpen = open">
      <DialogContent class="sm:max-w-md">
        <DialogHeader>
          <div class="flex gap-2 items-center">
            <AlertTriangle class="h-6 w-6" />
            <DialogTitle class="text-2xl">Cuidado</DialogTitle>
          </div>
          <DialogDescription class="py-2">
            Deseja mesmo deletar essa conta?
          </DialogDescription>
        </DialogHeader>
        <div class="flex items-center space-x-2">
          
        </div>
        <DialogFooter class="sm:justify-start">
          <DialogClose as-child>
            <Button type="button" variant="secondary">
              Cancelar
            </Button>
          </DialogClose>
          <Button type="button" :disabled="isDeletingBankAccount" @click="handleDeleteBankAccount">
            <Loader2 v-if="isDeletingBankAccount" class="w-4 h-4 mr-2 animate-spin" />
            Confirmar
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from "vue";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select } from "@/components/ui/select";
import { AlertTriangle, Loader2 } from "lucide-vue-next";
import { useToast } from "@/components/ui/toast";
import { useBank } from "@/composables/useBank";
import SelectTrigger from "@/components/ui/select/SelectTrigger.vue";
import SelectValue from "@/components/ui/select/SelectValue.vue";
import SelectContent from "@/components/ui/select/SelectContent.vue";
import SelectItem from "@/components/ui/select/SelectItem.vue";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import SpinnerLoader from "@/components/ui/loaders/SpinnerLoader.vue";
import { useValidation } from "@/composables/useValidation";
import { vMaska } from "maska/vue"
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";

interface Bank {
  id: number;
  name: string;
}

const bankAccountTypeLabels = {
  cashing: 'Corrente',
  saving: 'Poupança',
  digital_wallet: 'Carteira digital',
}

interface BankAccount {
  id: number;
  bankId: string;
  holder: string;
  document: string;
  agency: string;
  account: string;
  accountDigit: string;
  type: 'cashing' | 'saving' | 'digital_wallet';
}
const { isCPFValid, isCNPJValid } = useValidation()
const { toast } = useToast()

const isDisabled = computed(() => {
  const { bankId, holder, document, agency, account, accountDigit, type } = newBankAccount.value;

  // Verificar se todos os campos obrigatórios estão preenchidos
  if (!bankId || !holder || !document || !agency || !account || !accountDigit || !type) {
    return true;
  }

  // Verificar o comprimento da agência e do dígito da conta
  if (agency.length !== 4 || accountDigit.length !== 1) {
    return true;
  }

  // Verificar se o tipo da conta é válido
  const validTypes = ['cashing', 'saving', 'digital_wallet'];
  if (!validTypes.includes(type)) {
    return true;
  }

  return false; // Habilitar se todas as validações forem atendidas
});
const { getAllBankAccounts, getAllBanks, createBankAccount, deleteBankAccount, isDeletingBankAccount, isCreatingBankAccount } = useBank()

const banks = ref<Bank[]>([]);
const bankAccounts = ref<BankAccount[]>();
const isDeleteDialogConfirmationOpen = ref(false)
const selectedBankAccountToDeleteId = ref<number>()

const newBankAccount = ref<Omit<BankAccount, 'id'>>({
  bankId: '0',
  holder: '',
  document: '',
  agency: '',
  account: '',
  accountDigit: '',
  type: 'cashing'
});

const getBankName = (bankId: number): string => {
  const bank = banks.value.find(b => b.id === bankId);
  return bank ? bank.name : 'Banco não encontrado';
};

const handleCreateBankAccount = async () => {
  if (newBankAccount.value.document!.length <= 14) {
    if (!isCPFValid(newBankAccount.value.document!)) {
      toast({
        title: 'Cadastro falhou',
        description: 'Documento CPF inválido',
        variant: 'destructive',
        duration: 2000
      })

      return null
    } 
  } else {
    if (!isCNPJValid(newBankAccount.value.document!)) {
      toast({
        title: 'Cadastro falhou',
        description: 'Documento CNPJ inválido',
        variant: 'destructive',
        duration: 2000
      })

      return null
    } 
  }

  const bankAccount = await createBankAccount(newBankAccount.value)
  bankAccounts.value?.push(bankAccount)
}

const handleDeleteBankAccount = async () => {
  await deleteBankAccount(selectedBankAccountToDeleteId.value!)
  isDeleteDialogConfirmationOpen.value = false
  bankAccounts.value = bankAccounts.value?.filter(ba => ba.id != selectedBankAccountToDeleteId.value)
}

onMounted(async () => {
  getAllBankAccounts().then(data => { bankAccounts.value = data })
  getAllBanks().then(data => { banks.value = data })
});
</script>

<style>
/* Add any necessary styling here */
</style>
