<template>
  <div class="text-white p-4 sm:p-8" v-if="tournament">
    <div class="max-w-4xl mx-auto bg-card rounded-lg overflow-hidden">
      <div class="relative h-64 bg-gradient-to-r from-blue-600 to-emerald-600">
        <div class="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <h1 class="text-4xl font-bold text-white px-4">{{ tournament.name }}</h1>
        </div>
        <button @click="$router.go(-1)" class="absolute top-4 left-4 bg-white bg-opacity-20 p-2 rounded-full hover:bg-opacity-30 transition-all duration-300">
          <ArrowLeftIcon class="h-6 w-6 text-white" />
        </button>
      </div>
      
      <div class="p-4 sm:p-8">
        <div class="flex flex-wrap items-center justify-between mb-8">
          <div class="flex items-center space-x-4 mb-4 sm:mb-0">
            <span :class="{
              'bg-emerald-500': tournament.status === TournamentStatusEnum.FINISHED,
              'bg-blue-500': tournament.status === TournamentStatusEnum.OPEN,
              'bg-red-500': tournament.status === TournamentStatusEnum.CLOSED
            }" class="px-3 py-1 rounded-full text-sm font-semibold">
              {{ tournamentStatusLabels[tournament.status] }}
            </span>
            <span v-if="topPrize" class="flex items-center text-yellow-400">
              <TrophyIcon class="h-5 w-5 mr-2" />
              {{ topPrize.type === TournamentPrizeTypeEnum.PIX ? topPrize.amount?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : topPrize.description }}
            </span>
          </div>
          <button class="bg-primary font-bold py-2 px-6 rounded-full hover:from-emerald-600 hover:to-blue-600 transition-all duration-300 transform hover:scale-105">
            Se juntar ao torneio
          </button>
        </div>

        <p class="text-gray-300 mb-8">{{ tournament.description }}</p>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div class="bg-zinc-700 p-4 rounded-lg">
            <h3 class="text-lg font-semibold mb-2 flex items-center">
              <CalendarIcon class="h-5 w-5 mr-2 text-emerald-400" />
              Datas do torneio
            </h3>
            <p class="text-gray-300">Começa em: {{ formatDate(new Date(tournament.startAt)) }}</p>
            <p class="text-gray-300">Termina em: {{ formatDate(new Date(tournament.endAt)) }}</p>
          </div>
          <div class="bg-zinc-700 p-4 rounded-lg">
            <h3 class="text-lg font-semibold mb-2 flex items-center">
              <UsersIcon class="h-5 w-5 mr-2 text-blue-400" />
              Participantes
            </h3>
            <p class="text-gray-300" v-if="tournamentParticipants?.pagination.total">{{ tournamentParticipants?.pagination.total }} aluno(s)</p>
            <p class="text-gray-300" v-else-if="!tournamentParticipants?.pagination.total">Nenhum participante, por enquanto.</p>
            <div class="mt-2 flex -space-x-2">
              <Avatar v-for="(participant, index) in tournamentParticipants?.data" :key="participant.id">
                <AvatarImage v-if="participant.profile.avatar" :src="participant.profile.avatar.bucketLocation" :alt="participant.profile.name" />
                <AvatarFallback>{{ participant.profile.name?.split(' ').map(word => word.charAt(0).toUpperCase()).join('') }}</AvatarFallback>
              </Avatar>
              <span v-if="tournament.participantCount > 5" class="flex items-center justify-center w-8 h-8 rounded-full bg-gray-600 border-2 border-gray-800 text-xs font-medium">
                +{{ tournament.participantCount - 5 }}
              </span>
            </div>
          </div>
        </div>
        
        <!-- <div>
          <h3 class="text-xl font-semibold mb-4">Tournament Rules</h3>
          <ul class="list-disc list-inside text-gray-300 space-y-2">
            <li>All trades must be executed within the tournament's timeframe.</li>
            <li>Participants must use the designated trading platform.</li>
            <li>The use of automated trading bots is strictly prohibited.</li>
            <li>Risk management rules apply: max 2% risk per trade.</li>
            <li>The trader with the highest percentage gain at the end wins.</li>
          </ul>
        </div> -->
      </div>
    </div>
    <PrizeList v-if="tournament.prizes.length" :prizes="tournament.prizes" />
    <RankingView :tournament-id="tournamentId" />
  </div>
  <SpinnerLoader v-else />
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import { format } from 'date-fns'
import { ArrowLeft as ArrowLeftIcon, Trophy as TrophyIcon, Calendar as CalendarIcon, Users as UsersIcon } from 'lucide-vue-next'
import { Tournament } from '@/interfaces/tournaments/Tournament';
import { TournamentParticipant } from '@/interfaces/tournaments/TournamentParticipant';
import { useAxios } from '@/composables/useAxios';
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue';
import { Pagination } from '@/interfaces/Pagination';
import { TournamentStatusEnum, tournamentStatusLabels } from '@/enums/tournaments/TournamentStatusEnum';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import PrizeList from './components/PrizeList.vue';
import RankingView from '@/views/ranking/RankingView.vue';
import { TournamentPrizeTypeEnum } from '@/enums/tournaments/prizes/TournamentPrizeTypeEnum';
import { ptBR } from 'date-fns/locale';

const props = defineProps<{ tournamentId: number }>()
const tournament = ref<Tournament>()
const tournamentParticipants = ref<Pagination<TournamentParticipant>>()
const axios = useAxios()

const topPrize = computed(() => tournament.value?.prizes.find((prize) => prize.position === 1))
const formatDate = (date: Date) => {
  return format(date, "MMMM d, yyyy", { locale: ptBR })
}

function getTournament() {
  axios.get(`/tournaments/${props.tournamentId}`)
    .then((res) => {
      tournament.value = res.data
    })
}

function getTournamentParticipants() {
  axios.get(`/tournaments/${props.tournamentId}/participants`)
    .then((res) => {
      tournamentParticipants.value = res.data
    })
}

onMounted(() => {
  getTournament()
  getTournamentParticipants()
})
</script>