import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-gray-100 p-4" }
const _hoisted_2 = { class: "sm:container mx-auto" }
const _hoisted_3 = { class: "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = {
  key: 1,
  class: "w-full h-48 bg-zinc-700"
}
const _hoisted_7 = { class: "p-4" }
const _hoisted_8 = { class: "text-xl font-semibold mb-2" }
const _hoisted_9 = { class: "text-gray-400 text-sm mb-4" }
const _hoisted_10 = {
  key: 1,
  class: "col-span-12 flex flex-col items-center justify-center bg-card rounded-lg p-12 text-center"
}
const _hoisted_11 = { class: "relative mb-6" }
const _hoisted_12 = {
  key: 1,
  class: "flex justify-center w-full col-span-4"
}

import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useProfile } from '@/composables/useProfile';
import { Profile } from '@/interfaces/profiles/Profile';
import { Pagination } from '@/interfaces/Pagination';
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue';
import PaginationApi from '@/components/ui/pagination/PaginationApi.vue';
import { ChartLineIcon, UserIcon } from 'lucide-vue-next';


export default /*@__PURE__*/_defineComponent({
  __name: 'TradersView',
  setup(__props) {

const router = useRouter()
const { getTraders } = useProfile()
const traders = ref<Pagination<Profile>>()

function openProfile(id: number) {
  router.push(`/profile/${id}`)
}

onMounted(async () => {
  traders.value = await getTraders()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "text-2xl font-semibold tracking-tight mb-6" }, "Escolha um Trader", -1)),
      _createElementVNode("div", _hoisted_3, [
        (traders.value)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (traders.value.data.length)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(traders.value.data, (trader) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: trader.id,
                      class: "bg-card rounded-lg shadow-lg overflow-hidden cursor-pointer transition-transform duration-300 hover:scale-105",
                      onClick: ($event: any) => (openProfile(trader.id))
                    }, [
                      (trader.avatar)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            src: trader.avatar?.bucketLocation,
                            alt: trader.name,
                            class: "w-full h-48 object-cover"
                          }, null, 8, _hoisted_5))
                        : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(trader.name.split(' ').map(word => word.charAt(0).toUpperCase()).join('')), 1)),
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("h2", _hoisted_8, _toDisplayString(trader.name), 1),
                        _createElementVNode("p", _hoisted_9, _toDisplayString(trader.description), 1)
                      ])
                    ], 8, _hoisted_4))
                  }), 128))
                : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_unref(UserIcon), { class: "w-16 h-16 text-gray-600 animate-pulse" }),
                      _createVNode(_unref(ChartLineIcon), { class: "w-8 h-8 text-gray-700 absolute -right-2 -bottom-2" })
                    ]),
                    _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "text-xl font-semibold mb-3 bg-gradient-to-r bg-clip-text" }, " Nenhum trader disponível no momento ", -1)),
                    _cache[1] || (_cache[1] = _createElementVNode("p", { class: "text-gray-400 max-w-md mb-8" }, " Estamos trabalhando para trazer novos traders para a plataforma. Fique atento para atualizações! ", -1))
                  ]))
            ], 64))
          : (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createVNode(SpinnerLoader)
            ]))
      ]),
      (!!traders.value?.data.length)
        ? (_openBlock(), _createBlock(PaginationApi, {
            key: 0,
            data: traders.value.pagination,
            paginate: async (page) => traders.value = await _unref(getTraders)(page)
          }, null, 8, ["data", "paginate"]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})