import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-gray-100" }
const _hoisted_2 = { class: "md:pl-[10rem] md:pr-[10rem] py-6 flex" }

import FeedMenu from '@/views/feed/components/menu/FeedMenu.vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'CommunitiesLayout',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", _hoisted_2, [
      _createVNode(FeedMenu),
      _createVNode(_component_RouterView)
    ])
  ]))
}
}

})