import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useNotification } from '@/composables/useNotification';
import NotificationCard from './NotificationCard.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationsView',
  setup(__props) {

const { notifications, getMoreNotifications } = useNotification()

function handleGetMoreNotifications() {
  getMoreNotifications()
    .then((data) => {
      notifications.value = { data: [...notifications.value!.data, ...data.data ], pagination: data.pagination }
    })
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(NotificationCard, { onShowMore: handleGetMoreNotifications }))
}
}

})