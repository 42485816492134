import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full max-w-lg mx-auto"
}
const _hoisted_2 = { class: "space-y-4" }
const _hoisted_3 = { class: "flex flex-col space-y-2" }
const _hoisted_4 = { class: "space-y-1 leading-none" }
const _hoisted_5 = { class: "space-y-1 leading-none" }
const _hoisted_6 = { class: "space-y-1 leading-none" }

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { toast } from '@/components/ui/toast'
import * as z from 'zod'
import { toTypedSchema } from '@vee-validate/zod'
import { Loader2 } from 'lucide-vue-next'
import { useAxios } from '@/composables/useAxios'
import { onMounted, ref } from 'vue'
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import Toaster from '@/components/ui/toast/Toaster.vue'
import { Checkbox } from '@/components/ui/checkbox'
import { Label } from '@/components/ui/label'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Course } from '@/interfaces/Course'
import { Community } from '@/interfaces/courses/communities/Community'
import { useForm } from 'vee-validate'
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue'

type FormSchemaType = z.infer<typeof zodSchema>;


export default /*@__PURE__*/_defineComponent({
  __name: 'EditCommunityView',
  props: {
    communityId: {}
  },
  setup(__props: any) {

const community = ref<Community>()
const props = __props
const isCreatingCommunity = ref(false)
const axios = useAxios()
const courses = ref<Course[]>()

const requiredMessage = {
  required_error: "Este campo é obrigatório",
}

const zodSchema = z.object({
  name: z.string(requiredMessage).min(3, "O nome deve ter pelo menos 3 caracteres"),
  allowReactions: z.boolean(),
  allowPosts: z.boolean(),
  allowChat: z.boolean(),
  courseId: z.string(requiredMessage),
})

const formSchema = toTypedSchema(zodSchema);

const form = useForm({
  validationSchema: formSchema,
})

const onSubmit = form .handleSubmit((values) => {
  form.validate().then(() => {
    updateCommunity(values as FormSchemaType);
  });
})

function updateCommunity(payload: FormSchemaType) {
  isCreatingCommunity.value = true
  const { courseId, ...data } = payload

  axios.patch(`/courses/${courseId}/communities/${community.value!.id}`, data)
    .then(() => {
      toast({
        title: 'Comunidade editada com sucesso!',
        class: 'bg-green-500 text-white'
      })
    })
    .catch(() => {
      toast({
        title: 'Erro ao editar comunidade',
        description: 'Verifique os dados e tente novamente',
        variant: 'destructive'
      })
    })
    .finally(() => isCreatingCommunity.value = false)
}

function getCourses() {
  axios.get(`/courses?perPage=99999`)
    .then((res) => {
      courses.value = res.data.data
    })
}

function getCommunity() {
  community.value = undefined

  axios.get(`/courses/${props.communityId}/communities/${props.communityId}`)
    .then((res) => {
      community.value = res.data
      form.setValues({
        name: community.value!.name,
        allowReactions: !!community.value!.allowReactions,
        allowPosts: !!community.value!.allowPosts,
        courseId: String(community.value!.courseId)
      })
    })
}

onMounted(() => {
  getCourses()
  getCommunity()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (community.value)
      ? (_openBlock(), _createElementBlock("main", _hoisted_1, [
          _createElementVNode("form", {
            onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_unref(onSubmit) && _unref(onSubmit)(...args)), ["prevent"])),
            class: "space-y-8"
          }, [
            _cache[11] || (_cache[11] = _createElementVNode("div", { class: "space-y-2 text-center" }, [
              _createElementVNode("h1", { class: "text-3xl font-bold tracking-tighter text-[#000] dark:text-white" }, "Editar comunidade"),
              _createElementVNode("p", { class: "text-sm text-[#666] dark:text-[#888]" }, "Atualize os dados e permissões da sua comunidade")
            ], -1)),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_unref(FormField), { name: "name" }, {
                default: _withCtx(({ componentField }) => [
                  _createVNode(_unref(FormItem), null, {
                    default: _withCtx(() => [
                      _createVNode(_unref(FormLabel), { class: "text-sm font-medium text-[#000] dark:text-white" }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode("Nome da Comunidade")
                        ])),
                        _: 1
                      }),
                      _createVNode(_unref(FormControl), null, {
                        default: _withCtx(() => [
                          _createVNode(_unref(Input), _mergeProps({
                            type: "text",
                            placeholder: "Digite o nome da comunidade"
                          }, componentField), null, 16)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_unref(FormMessage))
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_unref(FormField), { name: "allowReactions" }, {
                  default: _withCtx(({ field }) => [
                    _createVNode(_unref(FormItem), { class: "flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4" }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(FormControl), null, {
                          default: _withCtx(() => [
                            _createVNode(_unref(Checkbox), _mergeProps(field, {
                              checked: _unref(form).values.allowReactions,
                              "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => (_unref(form).setFieldValue('allowReactions', $event)))
                            }), null, 16, ["checked"])
                          ]),
                          _: 2
                        }, 1024),
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_unref(FormLabel), null, {
                            default: _withCtx(() => _cache[5] || (_cache[5] = [
                              _createTextVNode("Permitir Reações")
                            ])),
                            _: 1
                          }),
                          _createVNode(_unref(FormDescription), null, {
                            default: _withCtx(() => _cache[6] || (_cache[6] = [
                              _createTextVNode(" Permite que os usuários reajam às postagens nesta comunidade. ")
                            ])),
                            _: 1
                          })
                        ])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 1
                }),
                _createVNode(_unref(FormField), { name: "allowPosts" }, {
                  default: _withCtx(({ field }) => [
                    _createVNode(_unref(FormItem), { class: "flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4" }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(FormControl), null, {
                          default: _withCtx(() => [
                            _createVNode(_unref(Checkbox), _mergeProps(field, {
                              checked: _unref(form).values.allowPosts,
                              "onUpdate:checked": _cache[1] || (_cache[1] = ($event: any) => (_unref(form).setFieldValue('allowPosts', $event)))
                            }), null, 16, ["checked"])
                          ]),
                          _: 2
                        }, 1024),
                        _createElementVNode("div", _hoisted_5, [
                          _createVNode(_unref(FormLabel), null, {
                            default: _withCtx(() => _cache[7] || (_cache[7] = [
                              _createTextVNode("Permitir Comentários")
                            ])),
                            _: 1
                          }),
                          _createVNode(_unref(FormDescription), null, {
                            default: _withCtx(() => _cache[8] || (_cache[8] = [
                              _createTextVNode(" Permite que os usuários comentem nas postagens desta comunidade. ")
                            ])),
                            _: 1
                          })
                        ])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 1
                }),
                _createVNode(_unref(FormField), { name: "allowChat" }, {
                  default: _withCtx(({ field }) => [
                    _createVNode(_unref(FormItem), { class: "flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4" }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(FormControl), null, {
                          default: _withCtx(() => [
                            _createVNode(_unref(Checkbox), _mergeProps(field, {
                              checked: _unref(form).values.allowChat,
                              "onUpdate:checked": _cache[2] || (_cache[2] = ($event: any) => (_unref(form).setFieldValue('allowChat', $event)))
                            }), null, 16, ["checked"])
                          ]),
                          _: 2
                        }, 1024),
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_unref(FormLabel), null, {
                            default: _withCtx(() => _cache[9] || (_cache[9] = [
                              _createTextVNode("Permitir Chat")
                            ])),
                            _: 1
                          }),
                          _createVNode(_unref(FormDescription), null, {
                            default: _withCtx(() => _cache[10] || (_cache[10] = [
                              _createTextVNode(" Permite que os usuários visualizem e comentem no chat ")
                            ])),
                            _: 1
                          })
                        ])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 1
                })
              ]),
              _createVNode(_unref(Button), {
                disabled: isCreatingCommunity.value,
                type: "submit",
                class: "w-full text-white dark:text-[#000] font-medium py-2 px-4 rounded-md transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#000] dark:focus:ring-white focus:ring-opacity-50"
              }, {
                default: _withCtx(() => [
                  (isCreatingCommunity.value)
                    ? (_openBlock(), _createBlock(_unref(Loader2), {
                        key: 0,
                        class: "w-4 h-4 mr-2 animate-spin"
                      }))
                    : _createCommentVNode("", true),
                  _createTextVNode(" " + _toDisplayString(isCreatingCommunity.value ? 'Salvando...' : 'Salvar'), 1)
                ]),
                _: 1
              }, 8, ["disabled"])
            ])
          ], 32)
        ]))
      : (_openBlock(), _createBlock(SpinnerLoader, { key: 1 })),
    _createVNode(Toaster)
  ], 64))
}
}

})