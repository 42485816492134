import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createVNode as _createVNode, withCtx as _withCtx, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { type HTMLAttributes, computed } from 'vue'
import { SelectIcon, SelectTrigger, type SelectTriggerProps, useForwardProps } from 'radix-vue'
import { ChevronDown } from 'lucide-vue-next'
import { cn } from '@/lib/utils'


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectTrigger',
  props: {
    disabled: { type: Boolean },
    asChild: { type: Boolean },
    as: {},
    class: {}
  },
  setup(__props: any) {

const props = __props

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwardedProps = useForwardProps(delegatedProps)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(SelectTrigger), _mergeProps(_unref(forwardedProps), {
    class: _unref(cn)(
      'flex h-10 w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 [&>span]:truncate text-start',
      props.class,
    )
  }), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default"),
      _createVNode(_unref(SelectIcon), { "as-child": "" }, {
        default: _withCtx(() => [
          _createVNode(_unref(ChevronDown), { class: "w-4 h-4 opacity-50 shrink-0" })
        ]),
        _: 1
      })
    ]),
    _: 3
  }, 16, ["class"]))
}
}

})