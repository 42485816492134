<template>
  <div class="flex items-center justify-between">
    <div class="flex items-center space-x-4">
      <RouterLink :to="{ name: 'profile', params: { profileId: topic.authorId } }">
        <Avatar>
          <AvatarImage v-if="topic.author.avatar" :src="topic.author.avatar.bucketLocation" :alt="topic.author.name" />
          <AvatarFallback>{{ topic.author?.name.split(' ').map(word => word.charAt(0).toUpperCase()).join('') }}</AvatarFallback>
        </Avatar>
      </RouterLink>
      <div>
        <RouterLink class="flex gap-2" :to="{ name: 'profile', params: { profileId: topic.authorId} }">
          <CardTitle class="text-lg font-semibold text-gray-100">{{ topic.author?.name }}</CardTitle>
          <Handshake
              v-if="topic.author.isTrader" 
              class="text-green-500 w-5 h-5 relative" 
              title="Trader Verificado"
            />
        </RouterLink>
        <CardDescription class="text-sm text-gray-400 flex items-center">
          <ClockIcon class="h-3 w-3 mr-1" />
          {{ timeAgo(topic.createdAt) }}
          <!-- <GlobeIcon v-if="post.visibility === 'public'" class="h-3 w-3 ml-2 mr-1" /> -->
          <!-- <LockIcon v-else class="h-3 w-3 ml-2 mr-1" /> -->
          <!-- {{ post.visibility }} -->
        </CardDescription>
      </div>
    </div>
    <DropdownMenu v-if="!hideDropdown && (topic.authorId == userAuthenticated.userId || userAuthenticated?.roles.find((role: string) => role === 'PRODUCER'))">
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon">
          <MoreHorizontalIcon class="h-5 w-5" :stroke-width="1.25" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent class="w-56">
        <!-- <DropdownMenuItem>
          <BookmarkIcon class="mr-2 h-4 w-4" :stroke-width="1.25" />
          <span>Salvar post</span>
        </DropdownMenuItem>
        <DropdownMenuItem>
          <BellIcon class="mr-2 h-4 w-4" :stroke-width="1.25" />
          <span>Ativar notificações</span>
        </DropdownMenuItem>
        <DropdownMenuSeparator /> -->
        <DropdownMenuItem class="text-red-500" @click="$emit('delete')">
          <Trash2 class="mr-2 h-4 w-4" :stroke-width="1.25" />
          <span>Excluír</span>
        </DropdownMenuItem>
        <!-- <DropdownMenuItem class="text-red-500">
          <FlagIcon class="mr-2 h-4 w-4" :stroke-width="1.25" />
          <span>Reportar</span>
        </DropdownMenuItem> -->
      </DropdownMenuContent>
    </DropdownMenu>
  </div>
</template>

<script lang="ts" setup>
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { CardDescription, CardTitle } from '@/components/ui/card';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { useAuth } from '@/composables/useAuth';
import { useDate } from '@/composables/useDate';
import { ClockIcon, Handshake, MoreHorizontalIcon, Trash2 } from 'lucide-vue-next';

const { userAuthenticated } = useAuth()
defineEmits(['delete'])
const props = defineProps<{ 
  topic: {
    authorId: number,
    author: {
      name: string,
      isTrader: boolean,
      avatar?: {
        bucketLocation: string
      }
    },
    createdAt: string,
  },
  hideDropdown?: boolean
}>()
const { timeAgo } = useDate()
</script>