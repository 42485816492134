<template>
  <div class="p-8">
    <div class="max-w-7xl mx-auto">
      <h1 class="text-2xl font-bold text-white mb-8">Conquistas</h1>
      
      <div v-if="achievements" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div v-for="achievement in achievements" 
             :key="achievement.id" 
             class="bg-card rounded-lg p-6 transition-transform hover:scale-105">
          <div class="flex items-start space-x-4">
            <div class="p-3 bg-purple-500 bg-opacity-20 rounded-lg">
              <component 
                :is="iconMap[achievement.icon]" 
                class="w-8 h-8 text-purple-400"
              />
            </div>
            <div class="flex-1">
              <h3 class="text-xl font-semibold text-white mb-2">
                {{ achievement.title }}
              </h3>
              <p class="text-gray-400 text-sm">
                {{ achievement.description }}
              </p>
            </div>
          </div>
          <!-- <div class="mt-4 flex items-center space-x-2">
            <div class="h-2 flex-1 bg-gray-700 rounded-full overflow-hidden">
              <div 
                class="h-full bg-purple-500 rounded-full transition-all duration-300"
                :style="{ width: `${achievement.progress}%` }"
              ></div>
            </div>
            <span class="text-sm text-gray-400">{{ achievement.progress }}%</span>
          </div> -->
        </div>
      </div>
      <SpinnerLoader v-else />
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { 
  Trophy, 
  Star, 
  Target, 
  Award,
  BookOpen,
  Users,
  Rocket,
  Heart,
  Lightning,
  ZapIcon,
  BarChart4Icon,
  TrendingUpIcon
} from 'lucide-vue-next'
import { useAchievement } from '@/composables/useAchievement'
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue';

// Mapeamento de ícones
const iconMap = {
  TrophyIcon: Trophy,
  StarIcon: Star,
  TargetIcon: Target,
  AwardIcon: Award,
  BookOpenIcon: BookOpen,
  UsersIcon: Users,
  RocketIcon: Rocket,
  HeartIcon: Heart,
  LightningIcon: Lightning,
  ZapIcon: ZapIcon,
  BarChart4Icon: BarChart4Icon,
  TrendingUpIcon: TrendingUpIcon
}

const achievements = ref()

const { getAllAchievements } = useAchievement()

onMounted(() => {
  getAllAchievements()
    .then(data => achievements.value = data)
})
</script>