<template>
  <div class="min-h-screen flex items-center justify-center px-4">
    <div class="max-w-md w-full space-y-8 text-center">
      <div class="space-y-4">
        <h1 class="text-5xl font-extrabold text-white">404</h1>
        <h2 class="text-3xl font-bold text-gray-200">Página Não Encontrada</h2>
      </div>
      
      <div class="mt-8 space-y-6">
        <svg class="mx-auto h-40 w-40 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        
        <p class="text-gray-400 text-lg">
          Oops! A página que você está procurando não existe ou foi removida.
        </p>
      </div>
      
      <!-- <div class="mt-8">
        <button 
          class="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out"
        >
          <HomeIcon class="h-5 w-5 mr-2" />
          Go back home
        </button>
      </div> -->
    </div>
    
  </div>
</template>

<script setup lang="ts">
</script>