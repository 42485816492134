export enum LessonContentTypeEnum {
  YOUTUBE = 'youtube',
  VIMEO = 'vimeo',
  PANDA_VIDEO = 'panda_video',
  EPLAY = 'eplay',
}

export const lessonContentTypeLabels = {
  [LessonContentTypeEnum.VIMEO]: 'Vimeo',
  [LessonContentTypeEnum.PANDA_VIDEO]: 'Panda Video',
  [LessonContentTypeEnum.YOUTUBE]: 'Youtube',
  [LessonContentTypeEnum.EPLAY]: 'Eplay',
}