import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "border-none shadow-none max-w-[800px] bg-card" }
const _hoisted_2 = { class: "flex items-center justify-between p-4 border-b" }
const _hoisted_3 = { class: "space-x-2" }
const _hoisted_4 = {
  key: 0,
  class: "flex flex-col items-center justify-center h-[300px] text-center"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "flex-shrink-0 mt-1" }
const _hoisted_7 = { class: "flex-1 min-w-0" }
const _hoisted_8 = { class: "font-medium text-sm truncate" }
const _hoisted_9 = { class: "text-sm text-muted-foreground line-clamp-2" }
const _hoisted_10 = { class: "text-xs text-muted-foreground mt-1" }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "mt-4" }
const _hoisted_13 = { class: "text-sm text-muted-foreground" }

import { Button } from "@/components/ui/button";
import SpinnerLoader from "@/components/ui/loaders/SpinnerLoader.vue";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useDate } from "@/composables/useDate";
import { useNotification } from "@/composables/useNotification";
import { NotificationStatusEnum } from "@/enums/notifications/NotificationStatusEnum";
import { Notification } from "@/interfaces/notifications/Notification";
import { Pagination } from "@/interfaces/Pagination";
import { BellOff, Check, Loader2 } from "lucide-vue-next";
import { Separator } from "radix-vue";
import { ref } from "vue";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";


export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationCard',
  props: {
    hasScrollArea: { type: Boolean, default: false }
  },
  emits: ['showMore'],
  setup(__props: any, { emit: __emit }) {

const emit = __emit
const { timeAgo } = useDate()
const props = __props
const { notifications, deleteAllNotifications, isLoadingNotifications, readNotification, readAllNotifications } = useNotification()
const isModalOpen = ref(false)
const selectedNotification = ref<Notification | null>(null)

const closeModal = () => {
  isModalOpen.value = false
  selectedNotification.value = null
}

const openModal = (notification: Notification) => {
  selectedNotification.value = notification
  isModalOpen.value = true
  if (notification.status == NotificationStatusEnum.UNREAD) {
    readNotification(notification.id)
    notification.readAt =  new Date().toISOString()
    notification.status = NotificationStatusEnum.READ
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(notifications))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_2, [
            _cache[4] || (_cache[4] = _createElementVNode("h2", { class: "text-lg font-semibold" }, "Notificações", -1)),
            _createElementVNode("div", _hoisted_3, [
              (!_ctx.hasScrollArea)
                ? (_openBlock(), _createBlock(_unref(Button), {
                    key: 0,
                    onClick: _unref(readAllNotifications),
                    variant: "ghost",
                    size: "sm"
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode(" Ler todos ")
                    ])),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true),
              (_unref(notifications).data.length > 0)
                ? (_openBlock(), _createBlock(_unref(Button), {
                    key: 1,
                    onClick: _unref(deleteAllNotifications),
                    variant: "ghost",
                    size: "sm"
                  }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode(" Limpar tudo ")
                    ])),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createVNode(_unref(ScrollArea), {
            class: _normalizeClass(_ctx.hasScrollArea ? 'h-[400px]' : '')
          }, {
            default: _withCtx(() => [
              (_unref(notifications).data.length === 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_unref(BellOff), { class: "h-12 w-12 text-muted-foreground mb-2" }),
                    _cache[5] || (_cache[5] = _createElementVNode("p", { class: "text-muted-foreground" }, "Nenhuma notificação", -1))
                  ]))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(notifications).data, (notification, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: notification.id,
                        class: "group",
                        onClick: ($event: any) => (openModal(notification))
                      }, [
                        _createElementVNode("div", {
                          class: _normalizeClass([
                'flex items-start gap-4 p-4 hover:bg-muted/50 transition-colors',
                { 'bg-muted/20': !!notification.readAt }
              ])
                        }, [
                          _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("div", {
                              class: _normalizeClass([
                  'w-2 h-2 rounded-full',
                  notification.status == _unref(NotificationStatusEnum).READ ? '' : 'bg-primary animate-pulse'
                ])
                            }, null, 2)
                          ]),
                          _createElementVNode("div", _hoisted_7, [
                            _createElementVNode("h3", _hoisted_8, _toDisplayString(notification.title), 1),
                            _createElementVNode("p", _hoisted_9, _toDisplayString(notification.message), 1),
                            _createElementVNode("p", _hoisted_10, _toDisplayString(_unref(timeAgo)(notification.createdAt)), 1)
                          ])
                        ], 2),
                        (index < _unref(notifications).data.length - 1)
                          ? (_openBlock(), _createBlock(_unref(Separator), { key: 0 }))
                          : _createCommentVNode("", true)
                      ], 8, _hoisted_5))
                    }), 128)),
                    (_unref(notifications).pagination.nextPage)
                      ? (_openBlock(), _createBlock(_unref(Button), {
                          key: 0,
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('showMore'))),
                          disabled: _unref(isLoadingNotifications),
                          variant: "ghost",
                          class: _normalizeClass([_ctx.hasScrollArea ? 'max-w-xs' : '', "w-full transition-all duration-300 ease-in-out transform hover:scale-105"])
                        }, {
                          default: _withCtx(() => [
                            (_unref(isLoadingNotifications))
                              ? (_openBlock(), _createBlock(_unref(Loader2), {
                                  key: 0,
                                  class: "w-4 h-4 mr-2 animate-spin"
                                }))
                              : (_openBlock(), _createElementBlock("span", _hoisted_11, "Ver mais notificações"))
                          ]),
                          _: 1
                        }, 8, ["disabled", "class"]))
                      : _createCommentVNode("", true)
                  ], 64))
            ]),
            _: 1
          }, 8, ["class"])
        ], 64))
      : (_openBlock(), _createBlock(SpinnerLoader, { key: 1 })),
    (selectedNotification.value)
      ? (_openBlock(), _createBlock(_unref(Dialog), {
          key: 2,
          open: isModalOpen.value,
          "onUpdate:open": _cache[1] || (_cache[1] = ($event: any) => ((isModalOpen).value = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(DialogContent), { class: "sm:max-w-[425px]" }, {
              default: _withCtx(() => [
                _createVNode(_unref(DialogHeader), null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(DialogTitle), null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(selectedNotification.value.title), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_unref(DialogDescription), null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(timeAgo)(selectedNotification.value.createdAt)), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("p", _hoisted_13, _toDisplayString(selectedNotification.value.message), 1)
                ]),
                _createVNode(_unref(DialogFooter), null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(Button), { onClick: closeModal }, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createTextVNode("Fechar")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["open"]))
      : _createCommentVNode("", true)
  ]))
}
}

})