import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full max-w-full pb-10"
}

import { computed } from 'vue'
import { LineChart } from '@/components/ui/chart-line';
import { TournamentDailyScore } from '@/interfaces/tournaments/TournamentDailyScore';


export default /*@__PURE__*/_defineComponent({
  __name: 'PerformanceChart',
  props: {
    tournamentDailyScores: {}
  },
  setup(__props: any) {

const props = __props

const chartData = computed(() => props.tournamentDailyScores.map((score, index) => {
  const total = props.tournamentDailyScores.reduce((acc, score, reduceIndex) => ((reduceIndex < index ? score.amount : 0) + acc), 0)
  const percentage = ((score.amount * 100) / total).toFixed(2)

  return { day: new Date(score.registrationAt).getDay(), lucro: score.amount, porcentagem: `%${percentage}` }
}))

return (_ctx: any,_cache: any) => {
  return (_ctx.tournamentDailyScores.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_unref(LineChart), {
          class: "w-full",
          categories: ['lucro'],
          index: 'day',
          data: chartData.value,
          "x-formatter": (tick, i) => {
        return `Dia ${tick}`
      },
          "y-formatter": (tick, i) => {
        return typeof tick === 'number'
          ? `${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(tick)}`
          : ''
      }
        }, null, 8, ["data", "x-formatter", "y-formatter"])
      ]))
    : _createCommentVNode("", true)
}
}

})