<template>
  <div class="p-8">
    <div class="max-w-7xl mx-auto">
      <h1 class="text-2xl font-bold mb-8">Gerenciamento de Afiliados</h1>
      
      <template v-if="affiliatesSummary && affiliateLinks">
        <!-- Widget de Desempenho Geral -->
        <div class="bg-card rounded-xl shadow-lg p-6 mb-8">
          <h2 class="text-xl font-semibold mb-6">Desempenho Geral</h2>
          <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div class="bg-blue-50 p-6 rounded-xl border border-blue-100">
              <p class="text-sm font-medium text-blue-600 mb-1">Total de Cliques</p>
              <p class="text-3xl font-bold text-blue-800">{{ affiliatesSummary?.totalClicks }}</p>
            </div>
            <div class="bg-green-50 p-6 rounded-xl border border-green-100">
              <p class="text-sm font-medium text-green-600 mb-1">Comissões Totais</p>
              <p class="text-3xl font-bold text-green-800">R$ {{ affiliatesSummary?.totalCommissions }}</p>
            </div>
            <div class="bg-purple-50 p-6 rounded-xl border border-purple-100">
              <p class="text-sm font-medium text-purple-600 mb-1">Taxa de Conversão</p>
              <p class="text-3xl font-bold text-purple-800">0%</p>
            </div>
          </div>
        </div>
  
        <!-- Lista de Links de Afiliados -->
        <div class="bg-card rounded-xl shadow-lg p-6 mb-8">
          <h2 class="text-2xl font-semibold mb-6">Seus Links de Afiliados</h2>
          <ul class="space-y-4">
            <template v-if="affiliateLinks?.length">
              <li v-for="link in affiliateLinks" :key="link.id" class="flex items-center justify-between bg-card p-4 rounded-xl border border-zinc-800">
                <div>
                  <p class="font-medium">{{ link.title }}</p>
                  <p class="text-sm text-muted-foreground">{{ link.url }}</p>
                </div>
                <button
                  @click="copyToClipboard(link.url)"
                  class="ml-4 p-2 text-gray-300 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 rounded-md transition-colors duration-200"
                  :title="'Copiar link: ' + link.title"
                >
                  <CopyIcon class="w-5 h-5" />
                </button>
              </li>
            </template>
            <div v-else class="flex flex-col items-center gap-2">
              <p class="text-zinc-400 font-medium">Não há links de afiliado, por enquanto</p>
              <Link2Off class="w-14 h-14" />
            </div>
          </ul>
        </div>
  
        <!-- Tabela de Transações Recentes -->
        <div v-if="affiliateReferrals" class="bg-card rounded-xl shadow-lg p-6">
          <h2 class="text-2xl font-semibold mb-6">Indicações Recentes</h2>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Cliente</TableHead>
                <TableHead>Data</TableHead>
                <TableHead>Comissão</TableHead>
                <TableHead>Status</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <template v-if="affiliateReferrals.length">
                <TableRow v-for="(referral, index) in affiliateReferrals" :key="index">
                  <TableCell>{{ referral.client.name }}</TableCell>
                  <TableCell>{{ new Date(referral.order.createdAt).toLocaleDateString() }}</TableCell>
                  <TableCell>{{ referral.amount.commission.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', }) }}</TableCell>
                  <TableCell>
                    <Badge class="text-white" :class="paymentStatusDetails[(referral.order.statusId as PaymentStatusEnum)].bgClass">
                      {{ paymentStatusDetails[(referral.order.statusId as PaymentStatusEnum)].name }}
                    </Badge>
                  </TableCell>
                </TableRow>
              </template>
              <template v-else class="relative">
                <TableRow>
                  <TableCell colspan="5" class="text-center font-medium py-8">
                    Nenhuma indicação encontrada.
                  </TableCell>
                </TableRow>
              </template>
            </TableBody>
          </Table>
        </div>
      </template>
      <SpinnerLoader v-else />
    </div>
    <Toaster />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useAffiliate } from '@/composables/useAffiliate'
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue'
import { Table, TableHeader, TableBody, TableHead, TableRow, TableCell } from '@/components/ui/table'
import { Badge } from '@/components/ui/badge'
import { CopyIcon, Link2Off } from 'lucide-vue-next'
import { toast } from '@/components/ui/toast'
import Toaster from '@/components/ui/toast/Toaster.vue'
import { links } from '@unovis/ts/components/sankey/style'
import { paymentStatusDetails, PaymentStatusEnum } from '@/enums/payment/PaymentStatusEnum'
// import { LineChart } from '@/components/ui/chart-line'
const affiliatesSummary = ref<{ 
  totalClicks: number,
  totalCommissions: number
  totalPagesClicks: number
  totalVariantsClicks: number
}>()
const { getAffiliatesSummary, getAffiliateLinks, getAffiliateReferrals } = useAffiliate()

// Estado
const affiliateLinks = ref<{ id: number, title: string, url: string }[]>()
const affiliateReferrals = ref<any[]>()

const getBadgeVariant = (status: string) => {
  switch (status) {
    case 'Aprovado':
      return 'success'
    case 'Pendente':
      return 'warning'
    case 'Rejeitado':
      return 'destructive'
    default:
      return 'secondary'
  }
}

const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text)
    // You can add a toast notification here to inform the user that the link was copied
    toast({
      title: 'Link copiado com sucesso!',
      description: 'O link do afiliado copiado para a área de transferência.',
      class: 'bg-green-500 text-white',
      duration: 800
    })
  } catch (err) {
    console.error('Falha ao copiar o link: ', err)
  }
}

onMounted(async () => {
  affiliatesSummary.value = await getAffiliatesSummary()
  affiliateLinks.value = await getAffiliateLinks()
  affiliateReferrals.value = await getAffiliateReferrals()
})
</script>