import { useAxios } from "./useAxios"

const axios = useAxios()

export function useAchievement() {
  async function getAllAchievements() {
    const response = await axios.get('/achievements')

    return response.data
  }

  return {
    getAllAchievements
  }
}