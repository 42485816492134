<template>
  <div class="sm:py-4">
    <Card class="col-span-12 p-6 mx-auto max-w-[1700px]">
      <!-- music -->
      <div class="flex items-center justify-between">
        <div class="space-y-1">
          <h2 class="text-2xl font-semibold tracking-tight">
            Meus cursos
          </h2>
          <p class="text-sm text-muted-foreground">
            Explore todos os cursos que você adquiriu e comece sua jornada de aprendizado!
          </p>
        </div>
      </div>
      <Separator class="my-4" />

      <!-- Condição mais simples para garantir que courses.data existe e tem itens -->
      <div v-if="courses" class="relative">
        <div v-if="courses?.data && courses?.data.length > 0" class="relative">
          <div class="flex flex-col gap-14 sm:gap-0 sm:flex-row sm:space-x-2 md:space-x-4 flex-wrap pb-4">
            <a class="cursor-pointer" @click="redirectToCourse(course)" v-for="course in courses.data"
              :key="course.title">
              <CourseArtwork :course="course" class="w-full sm:w-[250px]" aspect-ratio="portrait" :width="250"
                :height="330" />
            </a>
          </div>
        </div>

        <!-- Caso contrário, exibe a mensagem "Nenhum curso encontrado" -->
        <div v-else class="relative">
          <div class="flex flex-col gap-14 sm:gap-0 sm:flex-row sm:space-x-2 md:space-x-4 flex-wrap pb-4">
            Nenhum curso encontrado.
          </div>
        </div>
      </div>

      <SpinnerLoader v-else />
      <!-- <div class="mt-6 space-y-1">
        <h2 class="text-2xl font-semibold tracking-tight">
          Made for You
        </h2>
        <p class="text-sm text-muted-foreground">
          Your personal playlists. Updated daily.
        </p>
      </div>
      <Separator class="my-4" />
      <div class="relative">
        <ScrollArea>
          <div class="flex space-x-4 pb-4">
            <RouterLink :to="{ name: 'courses.show' }" v-for="album in madeForYouAlbums">
              <CourseArtwork
                :key="album.name"
                :album="album"
                class="w-[150px]"
                aspect-ratio="square"
                :width="150"
                :height="150"
              />
            </RouterLink>
          </div>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      </div> -->
      <!-- music -->
      <PaginationApi v-if="courses && (courses?.pagination.total ?? 0) > 1" :data="courses.pagination"
        :paginate="(page) => { courses = undefined; getCourses(page) }" />
    </Card>
  </div>
</template>

<script setup lang="ts">
import Card from '@/components/ui/card/Card.vue';
import { useAxios } from '@/composables/useAxios';
import { Course } from '@/interfaces/Course';
import CourseArtwork from '@/views/home/components/CourseArtwork.vue';
import { Separator } from 'radix-vue';
import { onMounted, ref } from 'vue';
import { Pagination as IPagination } from '@/interfaces/Pagination'
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue';
import PaginationApi from '@/components/ui/pagination/PaginationApi.vue';
import router from '@/router';

const axios = useAxios()
const courses = ref<IPagination<Course>>()

function redirectToCourse(course: Course) {
  axios.get(`/courses/${course.id}/modules/lessons/latest-progress`)
    .then((res) => {
      router.push({ name: 'lessons.show', params: { lessonId: res.data.lesson.id, moduleId: res.data.lesson.moduleId, courseId: course.id } })
    })
}

function getCourses(page = 1) {
  axios.get('/courses', { params: { page, owner: false } })
    .then((res) => {
      courses.value = res.data
    })
}

onMounted(() => {
  getCourses()
})
</script>