<template>
  <main class="w-full max-w-lg mx-auto">
    <Form
      v-slot="{ meta, values, validate, resetForm }"
      as="" 
      keep-values 
      :validation-schema="toTypedSchema(formSchema)"
    >
      <form @submit.prevent="handleSubmit(meta, values as FormSchemaType, validate, resetForm)" class="space-y-8">
        <div class="space-y-2 text-center">
          <h1 class="text-3xl font-bold tracking-tighter text-[#000] dark:text-white">Nova comunidade</h1>
          <p class="text-sm text-[#666] dark:text-[#888]">Crie uma nova comunidade para conectar pessoas</p>
        </div>
        
        <div class="space-y-4">
          <!-- Campo Nome da Comunidade -->
          <FormField v-slot="{ componentField }" name="name">
            <FormItem>
              <FormLabel class="text-sm font-medium text-[#000] dark:text-white">Nome da Comunidade</FormLabel>
              <FormControl>
                <Input
                  type="text"
                  placeholder="Digite o nome da comunidade"
                  v-bind="componentField"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          </FormField>

          <div class="flex flex-col space-y-2">
            <FormField v-slot="{ componentField }" name="courseId">
              <Label for="courseId">Curso</Label>
              <Select v-bind="componentField">
                <SelectTrigger id="courseId" aria-label="Selecionar curso">
                  <SelectValue placeholder="Selecionar" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem v-for="course in courses" :value="String(course.id)">{{ course.title }}</SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormField>
          </div>

          <div class="flex flex-col space-y-2">
             <!-- Campo Permitir Reações -->
             <FormField v-slot="{ field }" name="allowReactions">
              <FormItem class="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                <FormControl>
                  <Checkbox v-bind="field" />
                </FormControl>
                <div class="space-y-1 leading-none">
                  <FormLabel>Permitir Reações</FormLabel>
                  <FormDescription>
                    Permite que os usuários reajam às postagens nesta comunidade.
                  </FormDescription>
                </div>
              </FormItem>
            </FormField>

            <!-- Campo Permitir Comentários -->
            <FormField v-slot="{ field }" name="allowPosts">
              <FormItem class="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                <FormControl>
                  <Checkbox v-bind="field" />
                </FormControl>
                <div class="space-y-1 leading-none">
                  <FormLabel>Permitir Comentários</FormLabel>
                  <FormDescription>
                    Permite que os usuários comentem nas postagens desta comunidade.
                  </FormDescription>
                </div>
              </FormItem>
            </FormField>

            <!-- Campo para Habilitar Chat -->
            <FormField v-slot="{ field }" name="allowChat">
              <FormItem class="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                <FormControl>
                  <Checkbox v-bind="field" />
                </FormControl>
                <div class="space-y-1 leading-none">
                  <FormLabel>Permitir Chat</FormLabel>
                  <FormDescription>
                    Permite que os usuários visualizem e comentem no chat
                  </FormDescription>
                </div>
              </FormItem>
            </FormField>
          </div>

          <!-- Botão de Enviar -->
          <Button 
            :disabled="isCreatingCommunity" 
            type="submit" 
            class="w-full text-white dark:text-[#000] font-medium py-2 px-4 rounded-md transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#000] dark:focus:ring-white focus:ring-opacity-50"
          >
            <Loader2 v-if="isCreatingCommunity" class="w-4 h-4 mr-2 animate-spin" />
            {{ isCreatingCommunity ? 'Criando...' : 'Adicionar Comunidade' }}
          </Button>
        </div>
      </form>
    </Form>
  </main>
  <Toaster />
</template>

<script lang="ts" setup>
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { toast } from '@/components/ui/toast'
import * as z from 'zod'
import { toTypedSchema } from '@vee-validate/zod'
import { Loader2 } from 'lucide-vue-next'
import { useAxios } from '@/composables/useAxios'
import { onMounted, ref } from 'vue'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import Toaster from '@/components/ui/toast/Toaster.vue'
import router from '@/router'
import { Checkbox } from '@/components/ui/checkbox'
import { Label } from '@/components/ui/label'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Course } from '@/interfaces/Course'

const isCreatingCommunity = ref(false)
const axios = useAxios()
const courses = ref<Course[]>()

const requiredMessage = {
  required_error: "Este campo é obrigatório",
}

const formSchema = z.object({
  name: z.string(requiredMessage).min(3, "O nome deve ter pelo menos 3 caracteres"),
  allowReactions: z.boolean().default(true),
  allowPosts: z.boolean().default(true),
  allowChat: z.boolean().default(true),
  courseId: z.string(requiredMessage)
});

type FormSchemaType = z.infer<typeof formSchema>

function handleSubmit(meta: any, values: FormSchemaType, validate: () => void, resetForm: () => void) {
  validate();
  if (meta.valid) {
    createCommunity(values, resetForm);
  }
}

function createCommunity(payload: FormSchemaType, resetForm: () => void) {
  isCreatingCommunity.value = true
  const { courseId, ...data } = payload

  axios.post(`/courses/${courseId}/communities`, data)
    .then(() => {
      toast({
        title: 'Comunidade criada com sucesso!',
        description: 'Sua nova comunidade está pronta para uso.',
        class: 'bg-green-500 text-white'
      })
      resetForm()
    })
    .catch(() => {
      toast({
        title: 'Erro ao criar comunidade',
        description: 'Verifique os dados e tente novamente',
        variant: 'destructive'
      })
    })
    .finally(() => isCreatingCommunity.value = false)
}

function getCourses() {
  axios.get(`/courses?perPage=99999`)
    .then((res) => {
      courses.value = res.data.data
      console.log(res)
    })
}

onMounted(() => {
  getCourses()
})
</script>