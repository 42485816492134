import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-y-4 mt-4 sm:flex-row sm:gap-x-4 sm:gap-y-0" }

import { cn } from '@/lib/utils'
import { CalendarRoot, type CalendarRootEmits, type CalendarRootProps, useForwardPropsEmits } from 'radix-vue'
import { computed, type HTMLAttributes } from 'vue'
import { CalendarCell, CalendarCellTrigger, CalendarGrid, CalendarGridBody, CalendarGridHead, CalendarGridRow, CalendarHeadCell, CalendarHeader, CalendarHeading, CalendarNextButton, CalendarPrevButton } from '.'


export default /*@__PURE__*/_defineComponent({
  __name: 'Calendar',
  props: {
    modelValue: {},
    multiple: { type: Boolean },
    defaultValue: {},
    defaultPlaceholder: {},
    placeholder: {},
    pagedNavigation: { type: Boolean },
    preventDeselect: { type: Boolean },
    weekStartsOn: {},
    weekdayFormat: {},
    calendarLabel: {},
    fixedWeeks: { type: Boolean },
    maxValue: {},
    minValue: {},
    locale: {},
    numberOfMonths: {},
    disabled: { type: Boolean },
    readonly: { type: Boolean },
    initialFocus: { type: Boolean },
    isDateDisabled: { type: Function },
    isDateUnavailable: { type: Function },
    dir: {},
    nextPage: { type: Function },
    prevPage: { type: Function },
    asChild: { type: Boolean },
    as: {},
    class: {}
  },
  emits: ["update:modelValue", "update:placeholder"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emits = __emit

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(CalendarRoot), _mergeProps({
    class: _unref(cn)('p-3', props.class)
  }, _unref(forwarded)), {
    default: _withCtx(({ grid, weekDays }) => [
      _createVNode(_unref(CalendarHeader), null, {
        default: _withCtx(() => [
          _createVNode(_unref(CalendarPrevButton)),
          _createVNode(_unref(CalendarHeading)),
          _createVNode(_unref(CalendarNextButton))
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(grid, (month) => {
          return (_openBlock(), _createBlock(_unref(CalendarGrid), {
            key: month.value.toString()
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(CalendarGridHead), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(CalendarGridRow), null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(weekDays, (day) => {
                        return (_openBlock(), _createBlock(_unref(CalendarHeadCell), { key: day }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(day), 1)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024),
              _createVNode(_unref(CalendarGridBody), null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(month.rows, (weekDates, index) => {
                    return (_openBlock(), _createBlock(_unref(CalendarGridRow), {
                      key: `weekDate-${index}`,
                      class: "mt-2 w-full"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(weekDates, (weekDate) => {
                          return (_openBlock(), _createBlock(_unref(CalendarCell), {
                            key: weekDate.toString(),
                            date: weekDate
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(CalendarCellTrigger), {
                                day: weekDate,
                                month: month.value
                              }, null, 8, ["day", "month"])
                            ]),
                            _: 2
                          }, 1032, ["date"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ])
    ]),
    _: 1
  }, 16, ["class"]))
}
}

})