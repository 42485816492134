<template>
  <div class="flex flex-col sm:gap-4 sm:py-4">
    <main class="grid flex-1 items-start gap-4 sm:py-0 md:gap-8 max-w-[1700px] mx-auto w-full">
      <Tabs default-value="all">
        <div class="flex items-center">
          <div class="ml-auto flex items-center gap-2">
            <Button @click="$router.push({ name: 'tournaments.create' })" size="sm" class="h-9 gap-1">
              <PlusCircle class="h-3.5 w-3.5" />
              <span class="sr-only sm:not-sr-only sm:whitespace-nowrap">
                Adicionar Torneio
              </span>
            </Button>
          </div>
        </div>
        <TabsContent value="all">
          <Card>
            <CardHeader>
              <CardTitle>Torneios</CardTitle>
              <CardDescription>
                Gerencie, crie e edite os seus torneios
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Nome</TableHead>
                    <TableHead>Descrição</TableHead>
                    <TableHead>Duração</TableHead>
                    <TableHead>Status</TableHead>
                    <TableHead>
                      Ações
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody v-if="tournaments">
                  <template v-if="tournaments && !!tournaments.data.length">
                    <TableRow v-for="tournament in tournaments.data">
                      <TableCell class="font-medium">
                        {{ tournament.name }}
                      </TableCell>
                      <TableCell class="font-medium truncate">
                        {{ tournament.description }}
                      </TableCell>
                      <TableCell class="font-medium">
                        {{ new Date(tournament.startAt).toLocaleDateString() }} - 
                        {{ new Date(tournament.endAt).toLocaleDateString() }}
                      </TableCell>
                      <TableCell>
                        {{ tournamentStatusLabels[tournament.status] }}
                      </TableCell>
                      <TableCell>
                      <div class="flex gap-2">
                        <button class="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 w-10 rounded-lg"
                        @click="$router.push({ name: 'tournaments.edit', params: { tournamentId: tournament.id } })">
                          <Pencil class="w-5 h-5" />
                        </button>
                        <button class="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 w-10 rounded-lg"
                        @click="isDeleteTournamentConfirmationDialogOpen = true; selectedTournamentToDeleteId = tournament.id">
                          <Trash2 class="w-5 h-5" />
                        </button>
                      </div>
                      </TableCell>
                    </TableRow>
                  </template>
                  <template v-else class="relative">
                    <TableRow>
                      <TableCell colspan="5" class="text-center font-medium py-8">
                        Nenhum torneio encontrado.
                      </TableCell>
                    </TableRow>
                  </template>
                </TableBody>
              </Table>
              <SpinnerLoader v-if="!tournaments" />
              <PaginationApi v-if="tournaments" :data="tournaments.pagination" :paginate="(page) => { tournaments = undefined; getTournaments(page) }" />
            </CardContent>
            <CardFooter>
              <div v-if="tournaments" class="text-xs text-muted-foreground">
                Exibindo <strong>1-{{ tournaments.data.length }}</strong> de <strong>{{ tournaments.pagination.total }}</strong>
                resultados
              </div>
            </CardFooter>
          </Card>
          <Dialog v-if="selectedTournamentToDeleteId" :open="isDeleteTournamentConfirmationDialogOpen" @update:open="(open) => isDeleteTournamentConfirmationDialogOpen = open">
            <DialogContent class="sm:max-w-md">
              <DialogHeader>
                <div class="flex gap-2 items-center">
                  <AlertTriangle class="h-6 w-6" />
                  <DialogTitle class="text-2xl">Cuidado</DialogTitle>
                </div>
                <DialogDescription class="py-2">
                  Deseja mesmo deletar esse torneio?
                </DialogDescription>
              </DialogHeader>
              <div class="flex items-center space-x-2">
                
              </div>
              <DialogFooter class="sm:justify-start">
                <DialogClose as-child>
                  <Button type="button" variant="secondary">
                    Cancelar
                  </Button>
                </DialogClose>
                <Button type="button" :disabled="isDeletingTournament" @click="deleteTournament(selectedTournamentToDeleteId)">
                  <Loader2 v-if="isDeletingTournament" class="w-4 h-4 mr-2 animate-spin" />
                  Confirmar
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </TabsContent>
      </Tabs>
      <Toaster />
    </main>
  </div>
</template>

<script setup lang="ts">
import {
  PlusCircle,
  AlertTriangle,
  Loader2,
  Pencil,
  Trash2,
} from 'lucide-vue-next'

import { Button } from '@/components/ui/button'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import {
  Tabs,
  TabsContent,
} from '@/components/ui/tabs'

import { onMounted, ref } from 'vue'
import { useAxios } from '@/composables/useAxios'
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue'
import { Pagination as IPagination }from '@/interfaces/Pagination'
import PaginationApi from '@/components/ui/pagination/PaginationApi.vue'
import { Dialog } from '@/components/ui/dialog'
import DialogContent from '@/components/ui/dialog/DialogContent.vue'
import DialogHeader from '@/components/ui/dialog/DialogHeader.vue'
import DialogTitle from '@/components/ui/dialog/DialogTitle.vue'
import DialogDescription from '@/components/ui/dialog/DialogDescription.vue'
import DialogFooter from '@/components/ui/dialog/DialogFooter.vue'
import DialogClose from '@/components/ui/dialog/DialogClose.vue'
import { toast, Toaster } from '@/components/ui/toast'
import { Tournament } from '@/interfaces/tournaments/Tournament'
import { tournamentStatusLabels } from '@/enums/tournaments/TournamentStatusEnum'

const axios = useAxios()
const tournaments = ref<IPagination<Tournament>>()
const isDeleteTournamentConfirmationDialogOpen = ref(false)
const selectedTournamentToDeleteId = ref<number>()
const isDeletingTournament = ref(false)

function getTournaments(page = 1) {
  axios.get('/tournaments', { params: { page } })
    .then((res) => {
      tournaments.value = res.data
    })
}

function deleteTournament(id: number) {
  isDeletingTournament.value = true

  axios.delete(`/tournaments/${id}`)
    .then(() => {
      toast({
        title: 'Torneio excluído com sucesso',
        class: 'bg-green-500 text-white'
      })

      tournaments.value!.data = tournaments.value!.data.filter((tournament) => tournament.id !== id)
    })
    .catch(() => isDeletingTournament.value = false)
    .finally(() => {
      isDeletingTournament.value = false
      isDeleteTournamentConfirmationDialogOpen.value = false
    })
}

onMounted(() => {
  getTournaments()
})
</script>