import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import type { HTMLAttributes } from 'vue'
import { AvatarRoot } from 'radix-vue'
import { type AvatarVariants, avatarVariant } from '.'
import { cn } from '@/lib/utils'


export default /*@__PURE__*/_defineComponent({
  __name: 'Avatar',
  props: {
    class: {},
    size: { default: 'sm' },
    shape: { default: 'circle' }
  },
  setup(__props: any) {

const props = __props

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(AvatarRoot), {
    class: _normalizeClass(_unref(cn)(_unref(avatarVariant)({ size: _ctx.size, shape: _ctx.shape }), props.class))
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["class"]))
}
}

})