import { useToast } from "@/components/ui/toast";
import { useAxios } from "./useAxios";
import { ref } from "vue";

export function useBank() {
  const isCreatingBankAccount = ref(false)
  const isDeletingBankAccount = ref(false)
  const axios = useAxios(process.env.VUE_APP_API_REST_BASE_URL)
  const { toast } = useToast()
  const organizationId = localStorage.getItem("organizationId")

  const getAllBanks = async () => {
    try {
      const response = await axios.get('/static/banks');
      return response.data;
    } catch (error) {
      console.error('Error fetching banks:', error);
      toast({
        title: 'Erro',
        description: 'Não foi possível carregar a lista de bancos',
        variant: 'destructive'
      });
    }
  };
  
  const getAllBankAccounts = async () => {
    try {
      const response = await axios.get(`/organizations/${organizationId}/bank-accounts`);
      return response.data;
    } catch (error) {
      console.error('Error fetching bank accounts:', error);
      toast({
        title: 'Erro',
        description: 'Não foi possível carregar as contas bancárias',
        variant: 'destructive'
      });
    }
  };
  
  const createBankAccount = async (payload: any) => {
    isCreatingBankAccount.value = true;

    try {
      const response = await axios.post(`/organizations/${organizationId}/bank-accounts`, payload);
      
      toast({
        title: 'Sucesso',
        description: 'Conta bancária adicionada com sucesso',
        class: 'bg-green-500 text-white'
      });

      return response.data
    } catch (error) {
      console.error('Error adding bank account:', error);
      toast({
        title: 'Erro',
        description: 'Não foi possível adicionar a conta bancária',
        variant: 'destructive'
      });
    } finally {
      isCreatingBankAccount.value = false;
    }
  };
  
  const deleteBankAccount = async (id: number) => {
    isDeletingBankAccount.value = true

    try {
      await axios.delete(`/organizations/${organizationId}/bank-accounts/${id}`);
      toast({
        title: 'Sucesso',
        description: 'Conta bancária excluída com sucesso',
        class: 'bg-green-500 text-white'
      });
    } catch (error) {
      console.error('Error deleting bank account:', error);
      toast({
        title: 'Erro',
        description: 'Não foi possível excluir a conta bancária',
        variant: 'destructive'
      });
    } finally {
      isDeletingBankAccount.value = false
    }
  };
  

  return {
    getAllBanks,
    getAllBankAccounts,
    createBankAccount,
    deleteBankAccount,
    isCreatingBankAccount,
    isDeletingBankAccount
  }
}