<template>
  <Card class="overflow-hidden transition-shadow duration-300 hover:shadow-lg">
    <CardHeader>
      <ProfileComponent :topic="{ authorId: profile.userId, ...topic, author: { ...profile, avatar: profileAvatar } }" @delete="isConfirmDeleteModalOpen = true" />
    </CardHeader>
    <CardContent>
      <p class="text-gray-100 mb-4 whitespace-pre-wrap">{{ topic.content }}</p>
      <!-- <img v-if="post.image" :src="post.image" :alt="post.content" class="rounded-lg w-full object-cover max-h-96" /> -->
    </CardContent>
    <CardFooter class="flex flex-col space-y-4">
      <div class="flex items-center w-full text-gray-400 text-sm" :class="{ 'justify-between': (topic.reactions.length > 0), 'justify-end': !topic.reactions.length }">
        <div v-if="topic.reactions.length > 0" class="flex items-center space-x-2">
          <div class="flex -space-x-1">
            <div v-for="(reaction, index) in distinctReactions" :key="index" class="rounded-full bg-blue-500 w-5 h-5 flex items-center justify-center text-xs text-white">
              {{ reaction }}
            </div>
          </div>
          <span>{{ topic.reactions.length }}</span>
        </div>
        <div v-if="!!topic.postsCount" class="flex space-x-4">
          <span>{{ topic.postsCount }} comentários</span>
          <!-- <span>{{ topic.reactionsCount }} compartilhamentos</span> -->
        </div>
      </div>
      <Separator class="bg-gray-700" />
      <div class="flex justify-between w-full">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button variant="ghost" class="flex-1" @click="toggleReaction(ProfileTopicReactionContentEnum.LIKE)"
              :class="{ 
                'text-blue-500 hover:text-blue-500': alreadyReacted?.content === ProfileTopicReactionContentEnum.LIKE,
                'text-red-500 hover:text-red-500': alreadyReacted?.content === ProfileTopicReactionContentEnum.LOVE,
              }">
                <component
                  :is="alreadyReacted ? profileTopicReactionIcons[alreadyReacted.content] : ThumbsUpIcon"
                  :stroke-width="1.25"
                  class="h-5 w-5 mr-2 transition-colors duration-200"
                  :class="{ 
                    'text-blue-500 fill-blue-500': alreadyReacted?.content === ProfileTopicReactionContentEnum.LIKE,
                    'text-red-500 fill-red-500': alreadyReacted?.content === ProfileTopicReactionContentEnum.LOVE,
                  }"
                />
                <!-- <div>{{ alreadyReacted ? alreadyReacted.content : '👍' }}</div> -->
                {{ alreadyReacted ? profileTopicReactionLabels[alreadyReacted?.content] : 'Curtir' }}
              </Button>
            </TooltipTrigger>
            <TooltipContent class="bg-gray-800 border-gray-700">
              <div class="flex space-x-2">
                <button @click="toggleReaction(reaction)" v-for="reaction in ProfileTopicReactionContentEnum" :key="reaction" class="cursor-pointer text-xl hover:scale-125 transition-transform duration-200">
                  {{ reaction }}
                </button>
              </div>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <Button variant="ghost" class="flex-1" @click="$router.push({ name: 'profile.topics.show', params: { profileId: profile.id, profileTopicId: topic.id } })">
          <MessageCircleIcon class="h-5 w-5 mr-2" :stroke-width="1.25" />
          Comentar
        </Button>
        <!-- <Button variant="ghost" class="flex-1" @click="sharePost">
          <ShareIcon class="h-5 w-5 mr-2" :stroke-width="1.25" />
          Compartilhar
        </Button> -->
      </div>
      <!-- <Collapse v-if="showComments">
        <Separator class="bg-gray-700 my-4" />
        <div class="space-y-4">
          <div v-for="comment in post.comments" :key="comment.id" class="flex space-x-3">
            <Avatar>
              <AvatarImage :src="comment.author.avatar" :alt="comment.author.name" />
              <AvatarFallback>{{ comment.author.initials }}</AvatarFallback>
            </Avatar>
            <div class="flex-1">
              <div class="bg-gray-700 rounded-lg p-3">
                <p class="font-semibold text-gray-100">{{ comment.author.name }}</p>
                <p class="text-gray-300">{{ comment.content }}</p>
              </div>
              <div class="flex items-center mt-2 text-sm text-gray-400 space-x-4">
                <button class="hover:text-gray-100 transition-colors duration-200">Curtir</button>
                <button class="hover:text-gray-100 transition-colors duration-200">Responder</button>
                <span>{{ comment.timestamp }}</span>
              </div>
            </div>
          </div>
          <div class="flex items-center space-x-3">
            <Avatar>
              <AvatarImage src="/placeholder.svg?height=32&width=32" alt="Seu avatar" />
              <AvatarFallback>YA</AvatarFallback>
            </Avatar>
            <Input 
              placeholder="Escreva um comentário..." 
              class="bg-gray-700 border-gray-600 text-gray-100 focus:ring-blue-500 focus:border-blue-500"
              @keyup.enter="addComment"
            />
          </div>
        </div>
      </Collapse> -->
    </CardFooter>
    <Dialog :open="isConfirmDeleteModalOpen" @update:open="(open) => isConfirmDeleteModalOpen = open">
      <DialogContent class="sm:max-w-md">
        <DialogHeader>
          <div class="flex gap-2 items-center">
            <AlertTriangle class="h-6 w-6" />
            <DialogTitle class="text-2xl">Excluir Publicação</DialogTitle>
          </div>
          <DialogDescription class="py-2">
            Você está prestes a excluir esta publicação. Essa ação é irreversível e todos os dados relacionados a este publicação serão permanentemente removidos.
          </DialogDescription>
        </DialogHeader>
        <div class="flex items-center space-x-2">
          
        </div>
        <DialogFooter class="sm:justify-start">
          <DialogClose as-child>
            <Button type="button" variant="secondary">
              Cancelar
            </Button>
          </DialogClose>
          <Button type="button" :disabled="isDeletingTopic" @click="deleteTopic()">
            <Loader2 v-if="isDeletingTopic" class="w-4 h-4 mr-2 animate-spin" />
            Excluir Publicação
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  </Card>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from '@/components/ui/card'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import { 
  DropdownMenu, 
  DropdownMenuTrigger, 
  DropdownMenuContent, 
  DropdownMenuItem, 
} from '@/components/ui/dropdown-menu'
import { Tooltip, TooltipTrigger, TooltipContent } from '@/components/ui/tooltip'
// import { Collapse } from '@/components/ui/collapse'
import { 
  ThumbsUpIcon, 
  MessageCircleIcon, 
  ShareIcon, 
  MoreHorizontalIcon,
  ClockIcon,
  Trash2,
  AlertTriangle,
  Loader2
} from 'lucide-vue-next'
import { Separator } from 'radix-vue'
import TooltipProvider from '@/components/ui/tooltip/TooltipProvider.vue'
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader } from '@/components/ui/dialog'
import DialogTitle from '@/components/ui/dialog/DialogTitle.vue'
import DialogDescription from '@/components/ui/dialog/DialogDescription.vue'
import { useAxios } from '@/composables/useAxios'
import { toast } from '@/components/ui/toast'
import { useDate } from '@/composables/useDate'
import { computed } from 'vue'
import { ProfileTopic } from '@/interfaces/profiles/profileTopics/ProfileTopic'
import { Profile } from '@/interfaces/profiles/Profile'
import { ProfileMediaTypeEnum } from '@/enums/profiles/ProfileMediaTypeEnum'
import { ProfileTopicReactionContentEnum, profileTopicReactionIcons, profileTopicReactionLabels } from '@/enums/profiles/profileTopics/reactions/ProfileTopicReactionContentEnum'
import ProfileComponent from './ProfileComponent.vue'

const emit = defineEmits(['delete'])
const props = defineProps<{ topic: ProfileTopic, profile: Profile }>()
const userAuthenticated = JSON.parse(localStorage.getItem('authUser') ?? '')
const profileAvatar = computed(() =>
  props.profile.media.find((m) => m.type === ProfileMediaTypeEnum.AVATAR)
);
const isAuthenticatedUserProfile = userAuthenticated.userId == props.profile.userId
const { timeAgo } = useDate()
const isConfirmDeleteModalOpen = ref(false)
const isDeletingTopic = ref(false)
const axios = useAxios()
const alreadyReacted = computed(() => props.topic.reactions.find((r) => r.userId === userAuthenticated.userId))
const distinctReactions = computed(() => {
  return Array.from(new Set(props.topic.reactions.map(reaction => reaction.content))) as ProfileTopicReactionContentEnum[];
})

function deleteTopic() {
  isDeletingTopic.value = true

  axios.delete(`/profile/${props.topic.profileId}/topics/${props.topic.id}`)
    .then(() => {
      toast({
        title: 'Publicação excluida com sucesso',
        class: 'bg-green-500 text-white'
      })
      isConfirmDeleteModalOpen.value = false
      emit('delete')
    })
    .finally(() => isDeletingTopic.value = false)
}

const toggleReaction = async (content: ProfileTopicReactionContentEnum) => {
  axios.post(`/profile/${props.topic.profileId}/topics/${props.topic.id}/reactions`, { content })

  if (!alreadyReacted.value) {
    props.topic.reactions.push({
      profileTopicId: props.topic.id,
      content,
      userId: userAuthenticated.userId
    })
  } else {
    props.topic.reactions = props.topic.reactions.filter((r) => r.userId !== userAuthenticated.userId)
  }
}
</script>

<style scoped>
/* Adicione estilos específicos aqui, se necessário */
</style>