import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { type HTMLAttributes, computed } from 'vue'
import {
  AccordionHeader,
  AccordionTrigger,
  type AccordionTriggerProps,
} from 'radix-vue'
import { ChevronDown } from 'lucide-vue-next'
import { cn } from '@/lib/utils'


export default /*@__PURE__*/_defineComponent({
  __name: 'AccordionTrigger',
  props: {
    asChild: { type: Boolean },
    as: {},
    class: {}
  },
  setup(__props: any) {

const props = __props

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(AccordionHeader), { class: "flex" }, {
    default: _withCtx(() => [
      _createVNode(_unref(AccordionTrigger), _mergeProps(delegatedProps.value, {
        class: 
        _unref(cn)(
          'flex flex-1 items-center justify-between py-4 font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-180',
          props.class,
        )
      
      }), {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default"),
          _renderSlot(_ctx.$slots, "icon", {}, () => [
            _createVNode(_unref(ChevronDown), { class: "h-4 w-4 shrink-0 transition-transform duration-200" })
          ])
        ]),
        _: 3
      }, 16, ["class"])
    ]),
    _: 3
  }))
}
}

})