import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createVNode as _createVNode, withCtx as _withCtx, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { type HTMLAttributes, computed } from 'vue'
import type { CheckboxRootEmits, CheckboxRootProps } from 'radix-vue'
import { CheckboxIndicator, CheckboxRoot, useForwardPropsEmits } from 'radix-vue'
import { Check } from 'lucide-vue-next'
import { cn } from '@/lib/utils'


export default /*@__PURE__*/_defineComponent({
  __name: 'Checkbox',
  props: {
    defaultChecked: { type: Boolean },
    checked: { type: [Boolean, String] },
    disabled: { type: Boolean },
    required: { type: Boolean },
    name: {},
    value: {},
    id: {},
    asChild: { type: Boolean },
    as: {},
    class: {}
  },
  emits: ["update:checked"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emits = __emit

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(CheckboxRoot), _mergeProps(_unref(forwarded), {
    class: 
      _unref(cn)('peer h-4 w-4 shrink-0 rounded-sm border border-primary ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground',
         props.class)
  }), {
    default: _withCtx(() => [
      _createVNode(_unref(CheckboxIndicator), { class: "flex h-full w-full items-center justify-center text-current" }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, () => [
            _createVNode(_unref(Check), { class: "h-4 w-4" })
          ])
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 16, ["class"]))
}
}

})