import { Profile } from "@/interfaces/profiles/Profile";
import { useAxios } from "./useAxios";
import { Pagination } from "@/interfaces/Pagination";

const axios = useAxios()

export function useProfile() {
  async function getTraders(page = 1): Promise<Pagination<Profile>> {
    const response = await axios.get('/profile/traders', { params: { page } })

    return response.data
  }

  return {
    getTraders
  }
}