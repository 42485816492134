import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { type HTMLAttributes, computed } from 'vue'
import { TabsContent, type TabsContentProps } from 'radix-vue'
import { cn } from '@/lib/utils'


export default /*@__PURE__*/_defineComponent({
  __name: 'TabsContent',
  props: {
    value: {},
    forceMount: { type: Boolean },
    asChild: { type: Boolean },
    as: {},
    class: {}
  },
  setup(__props: any) {

const props = __props

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(TabsContent), _mergeProps({
    class: _unref(cn)('mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2', props.class)
  }, delegatedProps.value), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 16, ["class"]))
}
}

})