export enum LessonTypeEnum {
  VIDEO = 'video',
  FILE = 'file',
  TEXT = 'text',
}

export const lessonTypeLabels = {
  [LessonTypeEnum.VIDEO]: 'Vídeo',
  [LessonTypeEnum.FILE]: 'Arquivo',
  [LessonTypeEnum.TEXT]: 'Texto',
}