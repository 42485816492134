<template>
  <div class="space-y-8">
    <Card class="w-full">
      <CardContent class="p-0">
        <!-- Cover Photo -->
        <div class="h-48 bg-gray-400 relative">
          <input v-if="userAuthenticated.userId === user.userId" class="hidden" accept="image/png, image/jpg, image/jpeg, image/gif, image/avif" type="file" @change="onAvatarChange" id="avatar">
          <input v-if="userAuthenticated.userId === user.userId" class="hidden" accept="image/png, image/jpg, image/jpeg, image/gif, image/avif" type="file" @change="onBannerChange" id="banner">
          <label
            for="banner"
            class="absolute top-8 -translate-y-1/2 -translate-x-1/2 left-1/2 bg-muted rounded-full p-4 cursor-pointer"
            v-if="userAuthenticated.userId === user.userId"
          >
            <ImageUp
              v-if="
                !banner?.bucketLocation &&
                !bannerPreview
              "
              class="w-4 h-4"
            />
            <Pencil v-else class="w-4 h-4" />
          </label>
          <img
            v-if="banner || bannerPreview"
            :src="bannerPreview ? bannerPreview : banner!.bucketLocation"
            alt="Cover Photo"
            class="w-full h-full object-cover"
          />
          <label for="avatar">
            <Avatar
              class="absolute bottom-0 left-4 transform translate-y-1/2 border-4 border-white"
              size="lg"
            >
              <AvatarImage
                v-if="avatar?.bucketLocation || avatarPreview"
                :src="avatarPreview ? avatarPreview : avatar!.bucketLocation"
              />
              <AvatarFallback>{{
                user.name
                  .split(" ")
                  .map((word) => word.charAt(0).toUpperCase())
                  .join("")
              }}</AvatarFallback>
            </Avatar>
          </label>
        </div>
  
        <!-- Profile Info -->
        <div class="pt-16 px-4">
          <div class="flex justify-between items-start mb-4">
            <div>
              <div class="flex items-bottom gap-2">
                <h2 class="text-2xl font-bold">{{ user.name }}</h2>
                <Handshake 
                  v-if="user.isTrader" 
                  class="text-green-500 w-7 h-7 relative" 
                  title="Trader Verificado"
                />
              </div>
              <!-- <p class="text-gray-500">{{ user.description }}</p> -->
            </div>
            <Button v-if="userAuthenticated.userId !== user.userId" @click="isSendMessageDialogOpen = true">Enviar mensagem</Button>
            <div class="flex gap-2 flex-row flex-wrap" v-if="userAuthenticated.userId === user.userId">
              <Button @click="openEditModal">Editar perfil</Button>
              <Button v-if="!!profilePayload.avatar || !!profilePayload.banner" @click="updateProfile" class="bg-emerald-500 text-white hover:bg-emerald-600">
                <Loader2 v-if="isUpdatingProfile" class="w-4 h-4 mr-2 animate-spin" />
                Salvar alterações
              </Button>
            </div>
          </div>
  
          <p class="mb-4">{{ user.description }}</p>
  
          <!-- Stats -->
          <div class="flex items-center gap-8 mb-6">
            <div class="text-center">
              <p class="font-semibold mb-2">Publicações</p>
              <p class="font-semibold w-[38px] h-[38px] text-center min-w-full">{{ topics?.pagination.total }}</p>
            </div>
            <!-- Achievements section -->
            <div class="" v-if="user.achievements.length">
              <h3 class="font-semibold mb-2">Conquistas</h3>
              <div class="flex flex-wrap gap-2">
                <TooltipProvider>
                  <Tooltip
                    v-for="achievement in user.achievements"
                    :key="achievement.id"
                  >
                    <TooltipTrigger>
                      <Badge variant="secondary" class="p-2">
                        <component :is="achievementCriteriaTypeIcons[achievement.criteriaType]" class="h-5 w-5" />
                      </Badge>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p class="font-semibold">{{ achievement.title }}</p>
                      <p>{{ achievement.description }}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            </div>
          </div>
  
        </div>
      </CardContent>

      <Dialog v-model:open="isSendMessageDialogOpen">
        <DialogContent class="w-full max-w-[500px] grid-rows-[auto_minmax(0,1fr)_auto]">
          <DialogHeader>
            <DialogTitle>Enviar mensagem</DialogTitle>
          </DialogHeader>
          <Textarea id="message" placeholder="ex: Olá..." v-model="message" />
          <Button :disabled="isSendingMessage || !message" @click="() => sendMessage(message, user.userId, () => {
            toast({
              title: 'Sucesso',
              description: 'Mensagem enviada',
              class: 'bg-green-500 text-white',
              duration: 1500
            });
            isSendMessageDialogOpen = false
            message = ''
          })">
            <Loader2 v-if="isSendingMessage" class="w-4 h-4 mr-2 animate-spin" />
            Enviar mensagem
          </Button>
        </DialogContent>
      </Dialog>
  
      <!-- Edit Profile Modal -->
      <Dialog v-if="userAuthenticated.userId === user.userId" v-model:open="isEditModalOpen">
        <DialogScrollContent class="w-full max-w-[800px] grid-rows-[auto_minmax(0,1fr)_auto]">
          <DialogHeader>
            <DialogTitle>Editar perfil</DialogTitle>
            <DialogDescription>
              Atualize as informações do seu perfil, dados bancários, senha, e
              preferências de notificação.
            </DialogDescription>
          </DialogHeader>
          <Tabs default-value="profile" class="w-full">
            <TabsList class="grid w-full grid-cols-3">
              <TabsTrigger value="profile">Perfil</TabsTrigger>
              <TabsTrigger value="bank">Dados bancários</TabsTrigger>
              <TabsTrigger value="password">Senha</TabsTrigger>
              <!-- <TabsTrigger value="notifications">Notificações</TabsTrigger> -->
            </TabsList>
            <TabsContent value="profile">
              <form @submit.prevent="updateProfile">
                <div class="space-y-4">
                  <div class="space-y-2">
                    <Label for="name">Nome</Label>
                    <Input id="name" v-model="profilePayload.name" required />
                  </div>
                  <div class="space-y-2">
                    <Label for="phoneNumber">Número de telefone</Label>
                    <Input v-maska="'(##) # ####-####'" type="tel" id="phoneNumber" v-model="profilePayload.phoneNumber" required />
                  </div>
                  <div class="space-y-2">
                    <Label for="description">Descrição</Label>
                    <Textarea id="description" v-model="profilePayload.description" />
                  </div>
                  <Button @click="updateProfile" type="submit" 
                  :disabled="!profilePayload.name ||
                  !profilePayload.description ||
                  (!!profilePayload.phoneNumber && profilePayload.phoneNumber?.length < 16)">
                    <Loader2 v-if="isUpdatingProfile" class="w-4 h-4 mr-2 animate-spin" />
                    Salvar
                  </Button>
                </div>
              </form>
            </TabsContent>
            <TabsContent value="bank">
              <ProfileBankAccounts />
            </TabsContent>
            <TabsContent value="password">
              <form @submit.prevent>
                <div class="space-y-4">
                  <div class="space-y-2">
                    <Label for="current-password">Senha atual</Label>
                    <Input
                      id="current-password"
                      type="password"
                      v-model="changePasswordPayload.passwordOld"
                      required
                    />
                  </div>
                  <div class="space-y-2">
                    <div>
                      <Label for="new-password">Nova senha</Label>
                      <p class="text-xs text-zinc-500">Deve conter no mínimo 8 caracteres</p>
                    </div>
                    <Input
                      id="new-password"
                      type="password"
                      v-model="changePasswordPayload.password"
                      required
                    />
                  </div>
                  <div class="space-y-2">
                    <Label for="confirm-password">Confirmar nova senha</Label>
                    <Input
                      id="confirm-password"
                      type="password"
                      v-model="changePasswordPayload.passwordConfirm"
                      required
                    />
                  </div>
                  <Button :disabled="
                  isChangingPassword || 
                  !changePasswordPayload.passwordOld || 
                  !changePasswordPayload.password || 
                  !changePasswordPayload.passwordConfirm || 
                  changePasswordPayload.password !== changePasswordPayload.passwordConfirm ||
                  changePasswordPayload.password.length < 8 ||
                  changePasswordPayload.passwordConfirm.length < 8"
                    @click="changePassword(
                      changePasswordPayload, 
                      () => {
                        toast({
                          title: 'Senha alterada com sucesso',
                          class: 'bg-green-500 text-white'
                        })
                        changePasswordPayload = {
                          passwordOld: '',
                          password: '',
                          passwordConfirm: '',
                        }
                      }, 
                      () => toast({
                        title: 'Senha atual incorreta',
                        description: 'Tente novamente',
                        variant: 'destructive'
                      })
                    )" 
                    type="submit"
                  >
                    <Loader2 v-if="isChangingPassword" class="w-4 h-4 mr-2 animate-spin" />
                    Salvar
                  </Button>
                </div>
              </form>
            </TabsContent>
          </Tabs>
        </DialogScrollContent>
      </Dialog>
    </Card>
    <Card class="w-full">
      <PerformanceChart :tournament-daily-scores="user.tournamentDailyScores" />
    </Card>
    <Card class="w-full">
      <!-- Tabs -->
      <Tabs default-value="posts" class="w-full">
        <TabsContent value="posts">
          <div v-if="userAuthenticated.userId === user.userId" class="">
            <CardHeader>
              <CardTitle class="text-[1.1rem]">Criar Publicação</CardTitle>
            </CardHeader>
            <CardContent class="grid gap-6">
              <Textarea v-model="topicPayload.content" placeholder="No que você está pensando?" />
            </CardContent>
            <CardFooter class="flex justify-between">
              <Button :disabled="!topicPayload.content || isCreatingTopic" @click="createTopic">
                <Loader2 v-if="isCreatingTopic" class="w-4 h-4 mr-2 animate-spin" />
                Publicar
              </Button>
            </CardFooter>
          </div>
          <div v-if="!!topics" class="p-4 space-y-4">
             <ProfileTopicComponent v-for="topic in topics.data" :profile="user" :key="topic.id" :topic="topic" @delete="topics.data = topics.data.filter((t) => t.id !== topic.id)" />
              <PaginationApi :data="topics.pagination" :paginate="getTopics" />
          </div>
        </TabsContent>
      </Tabs>
    </Card>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter,
} from "@/components/ui/card";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogScrollContent,
} from "@/components/ui/dialog";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  Pencil,
  ImageUp,
  Loader2,
  Handshake,
} from "lucide-vue-next";
import { useToast } from "@/components/ui/toast";
import { Profile } from "@/interfaces/profiles/Profile";
import { ProfileMediaTypeEnum } from "@/enums/profiles/ProfileMediaTypeEnum";
import { useAxios } from "@/composables/useAxios";
import { ProfileTopic } from "@/interfaces/profiles/profileTopics/ProfileTopic";
import { Pagination } from "@/interfaces/Pagination";
import ProfileTopicComponent from "./ProfileTopicComponent.vue";
import PaginationApi from "@/components/ui/pagination/PaginationApi.vue";
import { vMaska } from "maska/vue"
import { useAuth } from "@/composables/useAuth";
import PerformanceChart from "./PerformanceChart.vue";
import { achievementCriteriaTypeIcons } from "@/enums/achievements/AchievementCriteriaTypeEnum";
import { useChat } from "@/composables/useChat";
import ProfileBankAccounts from "./ProfileBankAccounts.vue";

const { userAuthenticated, changePassword, isChangingPassword } = useAuth()
const emit = defineEmits(['update'])
const props = defineProps<{ user: Profile }>();
const { toast } = useToast();
const axios = useAxios()
const avatar = computed(() =>
  props.user.media.find((m) => m.type === ProfileMediaTypeEnum.AVATAR)
);
const banner = computed(() =>
  props.user.media.find((m) => m.type === ProfileMediaTypeEnum.BANNER)
);
const avatarPreview = ref<string>()
const bannerPreview = ref<string>()
const isEditModalOpen = ref(false);
const isUpdatingProfile = ref(false);
const editForm = ref({
  bank: "",
  agency: "",
  account: "",
  document: "",
  notifications: {
    email: false,
    push: false,
    sms: false,
  },
});
const changePasswordPayload = ref({
  password: '',
  passwordConfirm: '',
  passwordOld: '',
})
const profilePayload = ref<{
  avatar?: File
  banner?: File
  name?: string
  description?: string,
  phoneNumber?: string
}>({
  name: props.user.name,
  description: props.user.description,
  phoneNumber: props.user.phoneNumber,
})
const topics = ref<Pagination<ProfileTopic>>()
const topicPayload = ref<{ content: string }>({ content: '' })
const isCreatingTopic = ref(false)
const { sendMessage, isSendingMessage } = useChat()
const isSendMessageDialogOpen = ref(false)
const message = ref('')

const openEditModal = () => {
  editForm.value = {
    bank: "",
    agency: "",
    account: "",
    document: "",
    notifications: {
      email: false,
      push: false,
      sms: false,
    },
  };
  isEditModalOpen.value = true;
};

const updateBankDetails = () => {
  // Here you would typically send the bank details to your backend
  toast({
    title: "Success",
    description: "Bank details updated successfully",
  });
  isEditModalOpen.value = false;
};

const updateNotifications = () => {
  // Here you would typically send the notification preferences to your backend
  toast({
    title: "Success",
    description: "Notification preferences updated successfully",
  });
  isEditModalOpen.value = false;
};

function onAvatarChange(e: Event) {
  const file = (e.target as HTMLInputElement).files![0]

  profilePayload.value.avatar = file

  avatarPreview.value = URL.createObjectURL(file)
}

function onBannerChange(e: Event) {
  const file = (e.target as HTMLInputElement).files![0]

  profilePayload.value.banner = file

  bannerPreview.value = URL.createObjectURL(file)
}

function updateProfile() {
  isUpdatingProfile.value = true

  const formData = new FormData()

  for (const key in profilePayload.value) {
    if (profilePayload.value[key as keyof typeof profilePayload.value]) {
      formData.append(key, profilePayload.value[key as keyof typeof profilePayload.value] as any)
    }
  }

  axios.patch(`/profile`, formData)
    .then(() => {
      toast({
        title: 'Perfil atualizado com sucesso',
        class: 'bg-green-500 text-white'
      })
      emit('update')
      isEditModalOpen.value = false;
    })
    .finally(() => isUpdatingProfile.value = false)
}

function createTopic() {
  isCreatingTopic.value = true

  axios.post(`/profile/${props.user.id}/topics`, topicPayload.value)
    .then((res) => {
      getTopics()
      topicPayload.value = { content: '' }
    })
    .finally(() => isCreatingTopic.value = false)
}

function getTopics(page = 1) {
  axios.get(`/profile/${props.user.id}/topics`, { params: { page } })
    .then((res) => {
      topics.value = res.data
    })
}

onMounted(() => {
  getTopics()
})
</script>
