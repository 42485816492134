import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-white p-0 bg-card rounded-lg max-w-4xl mx-auto mt-8" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "p-4 sm:p-8" }
const _hoisted_4 = { class: "grid grid-cols-1 md:grid-cols-2 gap-6" }
const _hoisted_5 = { class: "flex items-center justify-between mb-4" }
const _hoisted_6 = { class: "text-lg font-semibold text-yellow-400" }
const _hoisted_7 = {
  key: 0,
  class: "text-3xl font-bold mb-2"
}
const _hoisted_8 = { class: "text-sm text-gray-400" }

import { ArrowLeft as ArrowLeftIcon, Trophy as TrophyIcon, Calendar as CalendarIcon, Users as UsersIcon, Gift as GiftIcon } from 'lucide-vue-next'
import { TournamentPrize } from '@/interfaces/tournaments/TournamentPrize';


export default /*@__PURE__*/_defineComponent({
  __name: 'PrizeList',
  props: {
    prizes: {}
  },
  setup(__props: any) {

const props = __props

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "text-2xl font-bold mb-6 text-center bg-gradient-to-r from-yellow-400 to-yellow-200 bg-clip-text text-transparent" }, " Distribuição de prêmios ", -1)),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.prizes, (prize, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "bg-zinc-700 rounded-lg p-6 transform transition-all duration-300 hover:scale-105 hover:shadow-lg hover:shadow-yellow-500/20"
            }, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(prize.position) + " Lugar ", 1),
                _createVNode(_unref(GiftIcon), {
                  class: _normalizeClass([
                'h-6 w-6',
                index === 0 ? 'text-yellow-400' : 
                index === 1 ? 'text-gray-300' : 
                index === 2 ? 'text-yellow-700' : 'text-blue-400'
              ])
                }, null, 8, ["class"])
              ]),
              (prize.amount)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(prize.amount?.toLocaleString('pt-BR', { style: 'currency', currency: 'brl' })), 1))
                : _createCommentVNode("", true),
              _createElementVNode("p", _hoisted_8, _toDisplayString(prize.description), 1)
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}
}

})