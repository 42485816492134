<template>
  <div v-if="tournamentDailyScores.length" class="w-full max-w-full pb-10">
    <LineChart
      class="w-full"
      :categories="['lucro']"
      :index="'day'"
      :data="chartData"
      :x-formatter="(tick, i) => {
        return `Dia ${tick}`
      }"
      :y-formatter="(tick, i) => {
        return typeof tick === 'number'
          ? `${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(tick)}`
          : ''
      }" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { LineChart } from '@/components/ui/chart-line';
import { TournamentDailyScore } from '@/interfaces/tournaments/TournamentDailyScore';

const props = defineProps<{ tournamentDailyScores: TournamentDailyScore[] }>()

const chartData = computed(() => props.tournamentDailyScores.map((score, index) => {
  const total = props.tournamentDailyScores.reduce((acc, score, reduceIndex) => ((reduceIndex < index ? score.amount : 0) + acc), 0)
  const percentage = ((score.amount * 100) / total).toFixed(2)

  return { day: new Date(score.registrationAt).getDay(), lucro: score.amount, porcentagem: `%${percentage}` }
}))
</script>

<style lang="css">
.unovis-xy-container {
  width: 100%
}

/* 
<template>
  <div class="w-full max-w-full">
    <LineChart
      class="w-full"
      :categories="['lucro']"
      :index="'day'"
      :data="chartData"
      :x-formatter="(tick, i) => {
        return ``
      }"
      :y-formatter="(tick, i) => {
        return typeof tick === 'number'
          ? `$ ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(tick)}`
          : ''
      }" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { LineChart } from '@/components/ui/chart-line';
import { TournamentDailyScore } from '@/interfaces/tournaments/TournamentDailyScore';
// import { Chart, registerables } from 'chart.js'

// Chart.register(...registerables)

const props = defineProps<{ tournamentDailyScores: TournamentDailyScore[] }>()

const chartData = computed(() => props.tournamentDailyScores.map((score) => ({ day: new Date(score.registrationAt).getDay(), lucro: score.amount })))

//daily scores example
/*
[
        {
            "id": 2,
            "participantId": 3,
            "tournamentId": 1,
            "amount": 50,
            "registrationAt": "2024-11-15T03:00:00.000Z",
            "createdAt": "2024-11-17T16:54:01.543Z",
            "updatedAt": "2024-11-17T16:54:01.543Z",
            "userId": 23
        },
        {
            "id": 3,
            "participantId": 3,
            "tournamentId": 1,
            "amount": -23.5,
            "registrationAt": "2024-11-16T03:00:00.000Z",
            "createdAt": "2024-11-17T16:54:01.543Z",
            "updatedAt": "2024-11-17T16:54:01.543Z",
            "userId": 23
        },
        {
            "id": 5,
            "participantId": 3,
            "tournamentId": 1,
            "amount": 10,
            "registrationAt": "2024-11-17T03:00:00.000Z",
            "createdAt": "2024-11-17T17:41:07.429Z",
            "updatedAt": "2024-11-17T17:41:07.429Z",
            "userId": 23
        },
        {
            "id": 1,
            "participantId": 3,
            "tournamentId": 1,
            "amount": 100,
            "registrationAt": "2024-11-14T03:00:00.000Z",
            "createdAt": "2024-11-17T16:54:01.543Z",
            "updatedAt": "2024-11-17T16:54:01.543Z",
            "userId": 23
        }
    ]
*/
</style>