import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ErrorMessage } from 'vee-validate'
import { toValue } from 'vue'
import { useFormField } from './useFormField'


export default /*@__PURE__*/_defineComponent({
  __name: 'FormMessage',
  setup(__props) {

const { name, formMessageId } = useFormField()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(ErrorMessage), {
    id: _unref(formMessageId),
    as: "p",
    name: toValue(_unref(name)),
    class: "text-sm font-medium text-destructive"
  }, null, 8, ["id", "name"]))
}
}

})