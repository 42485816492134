import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import type { BulletLegendItemInterface } from '@unovis/ts'
import { buttonVariants } from '@/components/ui/button'
import { BulletLegend } from '@unovis/ts'
import { VisBulletLegend } from '@unovis/vue'
import { nextTick, onMounted, ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ChartLegend',
  props: {
    items: { default: () => [] }
  },
  emits: ["legendItemClick", "update:items"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emits = __emit

const elRef = ref<HTMLElement>()

onMounted(() => {
  const selector = `.${BulletLegend.selectors.item}`
  nextTick(() => {
    const elements = elRef.value?.querySelectorAll(selector)
    const classes = buttonVariants({ variant: 'ghost', size: 'xs' }).split(' ')
    elements?.forEach(el => el.classList.add(...classes, '!inline-flex', '!mr-2'))
  })
})

function onLegendItemClick(d: BulletLegendItemInterface, i: number) {
  emits('legendItemClick', d, i)
  const isBulletActive = !props.items[i].inactive
  const isFilterApplied = props.items.some(i => i.inactive)
  if (isFilterApplied && isBulletActive) {
    // reset filter
    emits('update:items', props.items.map(item => ({ ...item, inactive: false })))
  }
  else {
    // apply selection, set other item as inactive
    emits('update:items', props.items.map(item => item.name === d.name ? ({ ...d, inactive: false }) : { ...item, inactive: true }))
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "elRef",
    ref: elRef,
    class: "w-max"
  }, [
    _createVNode(_unref(VisBulletLegend), {
      items: _ctx.items,
      "on-legend-item-click": onLegendItemClick
    }, null, 8, ["items"])
  ], 512))
}
}

})