import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import type { HTMLAttributes } from 'vue'
import { cn } from '@/lib/utils'


export default /*@__PURE__*/_defineComponent({
  __name: 'CardTitle',
  props: {
    class: {}
  },
  setup(__props: any) {

const props = __props

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("h3", {
    class: _normalizeClass(
      _unref(cn)('text-2xl font-semibold leading-none tracking-tight', props.class)
    )
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})