<template>
  <div class="flex h-screen bg-card border">
    <!-- Lista de Contatos -->
    <div class="w-1/4 border-r border-gray-800 overflow-y-auto">
      <div class="p-4 border-b border-gray-800">
        <h2 class="text-xl font-semibold">Conversas</h2>
      </div>
      <div v-if="loadingRooms" class="flex justify-center items-center h-32">
        <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
      <div v-else-if="roomsError" class="p-4 text-red-500">
        {{ roomsError }}
      </div>
      <ul v-else>
        <li v-for="room in rooms" :key="room.id" @click="selectRoom(room)"
          class="hover:bg-gray-100 cursor-pointer">
          <div class="flex items-center p-4" :class="{ 'bg-blue-500': selectedRoom?.id === room.id || room.unreadCount}">
            <div class="w-12 h-12 rounded-full bg-gray-300 flex-shrink-0">
              <img :src="room.profilePicture === '' || !room?.profilePicture ? '/assets/img/no-profile-picture.jpg' : room.profilePicture" :alt="room.name" class="w-full h-full rounded-full object-cover" />
            </div>
            <div class="ml-4 flex-grow">
              <h3 class="text-sm font-medium">{{ room.name }}</h3>
              <p class="text-xs text-gray-300 truncate">{{ room.lastMessage }}</p>
            </div>
            <div v-if="room.unreadCount"
              class="bg-blue-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
              {{ room.unreadCount }}
            </div>
          </div>
        </li>
      </ul>
    </div>

    <!-- Área de Chat -->
    <div class="flex-1 flex flex-col relative">
      <div v-if="selectedRoom" class="flex-1 overflow-y-auto p-4 space-y-4" ref="chatContainer" id="chatContainer">
        <div v-if="loadingMessages" class="flex justify-center items-center">
          <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
        </div>

        <div v-else-if="messagesError" class="p-4 text-red-500">
          {{ messagesError }}
        </div>

        <template v-else>
          <div v-for="message in messages" :key="message.id" class="flex"
            :class="{ 'justify-end': message.sender === 'me' }">
            <div class="max-w-xs lg:max-w-md xl:max-w-lg">
              <div class="rounded-lg p-3 mb-1"
                :class="message.sender === 'me' ? 'bg-blue-500 text-white' : 'bg-gray-200'">
                {{ message.message }}
              </div>
              <div class="flex items-center justify-between text-xs text-gray-500">
                <span>{{ formatTime(message.timestamp) }}</span>
                <!-- <div class="flex items-center space-x-2">
                  <button @click="toggleReaction(message)" class="hover:text-gray-700 focus:outline-none">
                    {{ message.reaction ? '❤️' : '🤍' }}
                  </button>
                  <span v-if="message.reactionCount > 0">{{ message.reactionCount }}</span>
                </div> -->
              </div>
            </div>
          </div>
        </template>
      </div>
        
      <!-- Exibindo "digitando..." -->
      <div v-if="typingStatus" class="text-gray-500 text-sm inset-x-0 bottom-0 left-0">
        {{ typingStatus }}
      </div>

      <div v-if="!selectedRoom" class="flex-1 flex items-center justify-center text-gray-500">
        Selecione uma conversa para começar
      </div>

      <div class="border-t border-gray-200 p-4">

        <form @submit.prevent="sendMessage" class="flex space-x-2">
          <input v-model="newMessage" type="text" placeholder="Digite sua mensagem..."
            class="flex-1 rounded-full border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            :disabled="!selectedRoom || sendingMessage" @input="onTyping" />
          <button type="submit"
            class="bg-blue-500 text-white rounded-full px-6 py-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            :disabled="!selectedRoom || !newMessage.trim() || sendingMessage">
            {{ sendingMessage ? 'Enviando...' : 'Enviar' }}
          </button>
        </form>
      </div>
    </div>

    <!-- Notificações -->
    <div class="fixed bottom-4 right-4">
      <transition-group name="notification" tag="div" class="space-y-2">
        <div v-for="notification in notifications" :key="notification.id"
          :class="`p-4 rounded-lg shadow-lg ${notification.type === 'error' ? 'bg-red-500' : 'bg-green-500'} text-white`">
          {{ notification.message }}
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, nextTick } from 'vue'
import { useAxios } from '@/composables/useAxios'
import { useWebSocket } from '@/composables/useWebSocket'

const axios = useAxios()
const webSocket = useWebSocket()

const rooms = ref([])
const selectedRoom = ref(null)
const messages = ref([])
const newMessage = ref('')
const typingStatus = ref('')
const socket = ref(null)
const loadingRooms = ref(true)
const loadingMessages = ref(false)
const roomsError = ref(null)
const messagesError = ref(null)
const sendingMessage = ref(false)
const notifications = ref([])

const fetchRooms = async () => {
  try {
    loadingRooms.value = true
    roomsError.value = null

    const res = await axios.get('/chat/rooms', { params: { page: 1 } })
    rooms.value = res.data.data
  } catch (error) {
    roomsError.value = 'Erro ao carregar contatos. Por favor, tente novamente.'
    console.error('Erro ao buscar contatos:', error)
  } finally {
    loadingRooms.value = false
  }
}

const fetchMessages = async (roomId) => {
  try {
    loadingMessages.value = true
    messagesError.value = null

    const res = await axios.get(`/chat/messages/${roomId}`, { params: { page: 1 } })
    messages.value = res.data.data
  } catch (error) {
    messagesError.value = 'Erro ao carregar mensagens. Por favor, tente novamente.'
    console.error('Erro ao buscar mensagens:', error)
  } finally {
    loadingMessages.value = false
  }
}

const selectRoom = async (room) => {
  selectedRoom.value = room

  await fetchMessages(room.id)

  nextTick(() => {
        const chatContainer = document.querySelector('#chatContainer');
        if (chatContainer) {
          // Move o scroll para o final
          chatContainer.scrollTop = chatContainer.scrollHeight;
        }
      });

  // Marca as mensagens como lidas
  if (room.unreadCount > 0) {
    selectedRoom.value.unreadCount = 0

    webSocket.send(JSON.stringify({
        type: 'read_messages',
        chatId: selectedRoom.value.id
      }))
  }
}

const sendMessage = async () => {
  if (newMessage.value.trim() && selectedRoom.value && !sendingMessage.value) {
    const message = newMessage.value
    newMessage.value = ''
    sendingMessage.value = true

    try {
      webSocket.send(JSON.stringify({
        type: 'send_message',
        chatId: selectedRoom.value.id,
        message: message
      }))
    } catch (error) {
      console.error('Erro ao enviar mensagem:', error)
      addNotification('Erro ao enviar mensagem. Por favor, tente novamente.', 'error')
      newMessage.value = message // Restaura a mensagem não enviada
    } finally {
      sendingMessage.value = false
    }
  }
}

const onTyping = () => {
  if (selectedRoom.value) {
    // Envia evento de digitação
    webSocket.send(JSON.stringify({
      type: 'typing',
      chatId: selectedRoom.value.id
    }))
  }
}

// const toggleReaction = async (message) => {
//   try {
//     webSocket.send(JSON.stringify({
//       type: 'reaction',
//       chatId: selectedRoom.value.id,
//       reaction: !message.reaction
//     }))
//   } catch (error) {
//     console.error('Erro ao atualizar reação:', error)
//     addNotification('Erro ao atualizar reação. Por favor, tente novamente.', 'error')
//   }
// }

const formatTime = (timestamp) => {
  const date = new Date(timestamp)
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
}

const addNotification = (message, type = 'success') => {
  const id = Date.now()
  notifications.value.push({ id, message, type })
  setTimeout(() => {
    notifications.value = notifications.value.filter(n => n.id !== id)
  }, 5000)
}

onMounted(async () => {
  await fetchRooms()

  socket.value = webSocket

  socket.value.on("typing", (data, data2) => {
    if (data.chatId === selectedRoom?.value?.id) {
      typingStatus.value = `${data.profile.name} está digitando...`
      setTimeout(() => {
        typingStatus.value = ''
      }, 2000)
    }
  });

  socket.value.on("new_message", (data) => {
    if (selectedRoom.value && data.chatId === selectedRoom?.value?.id) {
      // inserir a mensagem na variavel
      messages.value.push(data)

      // ao receber mensagens e estiver dentro do chat, marcar como lida
      webSocket.send(JSON.stringify({
        type: 'read_messages',
        chatId: selectedRoom.value.id
      }))
      
      // descer para a última mensagem
      nextTick(() => {
        const chatContainer = document.querySelector('#chatContainer');
        if (chatContainer) {
          // Move o scroll para o final
          chatContainer.scrollTop = chatContainer.scrollHeight;
        }
      });
    }
  });
})

onUnmounted(() => {
  if (socket.value) {
    socket.value.close()
  }
})
</script>

<style scoped>
.notification-enter-active,
.notification-leave-active {
  transition: all 0.5s ease;
}

.notification-enter-from,
.notification-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>