import { ThumbsUpIcon, HeartIcon, LaughIcon, FrownIcon, AngryIcon } from "lucide-vue-next";

export enum CommunityTopicReactionContentEnum {
  LIKE = '👍',
  LOVE = '❤️',
  HAHA = '😆',
  // WOW = '😮',
  SAD = '😢',
  ANGRY = '😡'
}

export const communityTopicReactionLabels = {
  [CommunityTopicReactionContentEnum.LIKE]: 'Curtir',
  [CommunityTopicReactionContentEnum.LOVE]: 'Amei',
  [CommunityTopicReactionContentEnum.HAHA]: 'Haha',
  // [CommunityTopicReactionContentEnum.WOW]: 'Uau',
  [CommunityTopicReactionContentEnum.SAD]: 'Triste',
  [CommunityTopicReactionContentEnum.ANGRY]: 'Grr'
};

export const communityTopicReactionIcons = {
  [CommunityTopicReactionContentEnum.LIKE]: ThumbsUpIcon,
  [CommunityTopicReactionContentEnum.LOVE]: HeartIcon,
  [CommunityTopicReactionContentEnum.HAHA]: LaughIcon,
  // [CommunityTopicReactionContentEnum.WOW]: Suprised,
  [CommunityTopicReactionContentEnum.SAD]: FrownIcon,
  [CommunityTopicReactionContentEnum.ANGRY]: AngryIcon
};