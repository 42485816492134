import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "space-y-4"
}

import { onMounted, ref } from 'vue'
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card'
import { Textarea } from '@/components/ui/textarea'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/toast'
import { useAxios } from '@/composables/useAxios'
import { Profile } from '@/interfaces/profiles/Profile'
import { Pagination } from '@/interfaces/Pagination'
import { ProfileComment } from '@/interfaces/profiles/ProfileComment'
import ProfileCommentComponent from './components/ProfileComment.vue'
import PaginationApi from '@/components/ui/pagination/PaginationApi.vue'
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue'
import { Loader2 } from 'lucide-vue-next'


export default /*@__PURE__*/_defineComponent({
  __name: 'CommentsSection',
  props: {
    profile: {}
  },
  setup(__props: any) {

const axios = useAxios()
const props = __props
const comments = ref<Pagination<ProfileComment>>()
const payload = ref({ content: '' })
const { toast } = useToast()
const isCreatingComment = ref(false)

function getComments(page = 1) {
  axios.get(`/profile/${props.profile.id}/comments`, { params: { page } })
    .then((res) => {
      comments.value = res.data
    })
}

const createComment = () => {
  isCreatingComment.value = true

  axios.post(`/profile/${props.profile.id}/comments`, payload.value)
    .then(() => {
      toast({
        title: 'Comentário adicionado com sucesso',
        class: 'bg-green-500 text-white'
      })
      getComments()
    })
    .finally(() => isCreatingComment.value = false)
}

onMounted(() => {
  getComments()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Card), { class: "w-full" }, {
    default: _withCtx(() => [
      _createVNode(_unref(CardHeader), null, {
        default: _withCtx(() => [
          _createVNode(_unref(CardTitle), null, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Comentários")
            ])),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_unref(CardContent), null, {
        default: _withCtx(() => [
          (comments.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(comments.value.data, (comment) => {
                  return (_openBlock(), _createBlock(ProfileCommentComponent, {
                    key: comment.id,
                    comment: comment,
                    onDelete: getComments
                  }, null, 8, ["comment"]))
                }), 128))
              ]))
            : (_openBlock(), _createBlock(SpinnerLoader, { key: 1 })),
          (comments.value)
            ? (_openBlock(), _createBlock(PaginationApi, {
                key: 2,
                data: comments.value?.pagination,
                paginate: getComments
              }, null, 8, ["data"]))
            : _createCommentVNode("", true),
          _createElementVNode("form", {
            onSubmit: _withModifiers(createComment, ["prevent"]),
            class: "mt-6"
          }, [
            _createVNode(_unref(Textarea), {
              modelValue: payload.value.content,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((payload.value.content) = $event)),
              placeholder: "Escreva um comentário...",
              rows: 3,
              class: "mb-2"
            }, null, 8, ["modelValue"]),
            _createVNode(_unref(Button), {
              disabled: !payload.value.content || isCreatingComment.value,
              type: "submit"
            }, {
              default: _withCtx(() => [
                (isCreatingComment.value)
                  ? (_openBlock(), _createBlock(_unref(Loader2), {
                      key: 0,
                      class: "w-4 h-4 mr-2 animate-spin"
                    }))
                  : _createCommentVNode("", true),
                _cache[2] || (_cache[2] = _createTextVNode(" Adicionar "))
              ]),
              _: 1
            }, 8, ["disabled"])
          ], 32)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})