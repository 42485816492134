import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center gap-2 pb-2" }
const _hoisted_2 = { class: "percentage-text" }
const _hoisted_3 = { class: "progress-bar-container" }
const _hoisted_4 = { class: "progress-bar" }
const _hoisted_5 = { class: "text-xs" }




export default /*@__PURE__*/_defineComponent({
  __name: 'LessonProgressBar',
  props: {
    percentage: {},
    lessonsLength: {},
    lessonsCompletedLength: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("h6", { class: "m-0" }, "Progresso de conclusão", -1)),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.percentage) + "%", 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: "progress-fill",
          style: _normalizeStyle({ width: `${_ctx.percentage}%` })
        }, null, 4)
      ])
    ]),
    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.lessonsCompletedLength) + "/" + _toDisplayString(_ctx.lessonsLength), 1)
  ], 64))
}
}

})