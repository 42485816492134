export enum CourseStatusEnum {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  // DELETED = 'deleted',
}

export const courseStatusLabels = {
  [CourseStatusEnum.ACTIVE]: 'Publicado',
  [CourseStatusEnum.INACTIVE]: 'Não publicado',
  // [CourseStatusEnum.DELETED]: 'Deletado',
}