import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

import type { BaseChartProps } from '.'
import { ChartCrosshair, ChartLegend, defaultColors } from '@/components/ui/chart'
import { cn } from '@/lib/utils'
import { type BulletLegendItemInterface, CurveType } from '@unovis/ts'
import { Axis, Line } from '@unovis/ts'
import { VisAxis, VisLine, VisXYContainer } from '@unovis/vue'
import { useMounted } from '@vueuse/core'
import { type Component, computed, ref } from 'vue'

type KeyOfT = Extract<keyof T, string>
type Data = typeof props.data[number]


export default /*@__PURE__*/_defineComponent({
  __name: 'LineChart',
  props: {
    data: {},
    categories: {},
    index: {},
    colors: {},
    margin: { default: () => ({ top: 0, bottom: 0, left: 0, right: 0 }) },
    filterOpacity: { default: 0.2 },
    xFormatter: {},
    yFormatter: {},
    showXAxis: { type: Boolean, default: true },
    showYAxis: { type: Boolean, default: true },
    showTooltip: { type: Boolean, default: true },
    showLegend: { type: Boolean, default: true },
    showGridLine: { type: Boolean, default: true },
    customTooltip: {},
    curveType: { default: CurveType.MonotoneX }
  },
  emits: ["legendItemClick"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emits = __emit

const index = computed(() => props.index as KeyOfT)
const colors = computed(() => props.colors?.length ? props.colors : defaultColors(props.categories.length))

const legendItems = ref<BulletLegendItemInterface[]>(props.categories.map((category, i) => ({
  name: category,
  color: colors.value[i],
  inactive: false,
})))

const isMounted = useMounted()

function handleLegendItemClick(d: BulletLegendItemInterface, i: number) {
  emits('legendItemClick', d, i)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_unref(cn)('w-full h-[400px] flex flex-col items-end', _ctx.$attrs.class ?? ''))
  }, [
    (_ctx.showLegend)
      ? (_openBlock(), _createBlock(_unref(ChartLegend), {
          key: 0,
          items: legendItems.value,
          "onUpdate:items": _cache[0] || (_cache[0] = ($event: any) => ((legendItems).value = $event)),
          onLegendItemClick: handleLegendItemClick
        }, null, 8, ["items"]))
      : _createCommentVNode("", true),
    _createVNode(_unref(VisXYContainer), {
      margin: { left: 20, right: 20 },
      data: _ctx.data,
      style: _normalizeStyle({ height: _unref(isMounted) ? '100%' : 'auto' })
    }, {
      default: _withCtx(() => [
        (_ctx.showTooltip)
          ? (_openBlock(), _createBlock(_unref(ChartCrosshair), {
              key: 0,
              colors: colors.value,
              items: legendItems.value,
              index: index.value,
              "custom-tooltip": _ctx.customTooltip
            }, null, 8, ["colors", "items", "index", "custom-tooltip"]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category, i) => {
          return (_openBlock(), _createBlock(_unref(VisLine), {
            key: category,
            x: (d, i) => i,
            y: (d) => d[category],
            "curve-type": _ctx.curveType,
            color: colors.value[i],
            attributes: {
            [_unref(Line).selectors.line]: {
              opacity: legendItems.value.find(item => item.name === category)?.inactive ? _ctx.filterOpacity : 1,
            },
          }
          }, null, 8, ["x", "y", "curve-type", "color", "attributes"]))
        }), 128)),
        (_ctx.showXAxis)
          ? (_openBlock(), _createBlock(_unref(VisAxis), {
              key: 1,
              type: "x",
              "tick-format": _ctx.xFormatter ?? ((v) => _ctx.data[v]?.[index.value]),
              "grid-line": false,
              "tick-line": false,
              "tick-text-color": "hsl(var(--vis-text-color))"
            }, null, 8, ["tick-format"]))
          : _createCommentVNode("", true),
        (_ctx.showYAxis)
          ? (_openBlock(), _createBlock(_unref(VisAxis), {
              key: 2,
              type: "y",
              "tick-line": false,
              "tick-format": _ctx.yFormatter,
              "domain-line": false,
              "grid-line": _ctx.showGridLine,
              attributes: {
          [_unref(Axis).selectors.grid]: {
            class: 'text-muted',
          },
        },
              "tick-text-color": "hsl(var(--vis-text-color))"
            }, null, 8, ["tick-format", "grid-line", "attributes"]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    }, 8, ["data", "style"])
  ], 2))
}
}

})