import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "space-y-4" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "text-center py-4 text-gray-500"
}
const _hoisted_4 = { class: "space-y-4" }
const _hoisted_5 = { class: "space-y-2" }
const _hoisted_6 = { class: "grid sm:grid-cols-2 gap-4" }
const _hoisted_7 = { class: "space-y-2" }
const _hoisted_8 = { class: "space-y-2" }
const _hoisted_9 = { class: "grid sm:grid-cols-2 gap-4" }
const _hoisted_10 = { class: "space-y-2" }
const _hoisted_11 = { class: "space-y-2" }
const _hoisted_12 = { class: "grid sm:grid-cols-2 gap-4" }
const _hoisted_13 = { class: "space-y-2" }
const _hoisted_14 = { class: "space-y-2" }
const _hoisted_15 = { class: "flex gap-2 items-center" }

import { ref, onMounted, computed } from "vue";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select } from "@/components/ui/select";
import { AlertTriangle, Loader2 } from "lucide-vue-next";
import { useToast } from "@/components/ui/toast";
import { useBank } from "@/composables/useBank";
import SelectTrigger from "@/components/ui/select/SelectTrigger.vue";
import SelectValue from "@/components/ui/select/SelectValue.vue";
import SelectContent from "@/components/ui/select/SelectContent.vue";
import SelectItem from "@/components/ui/select/SelectItem.vue";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import SpinnerLoader from "@/components/ui/loaders/SpinnerLoader.vue";
import { useValidation } from "@/composables/useValidation";
import { vMaska } from "maska/vue"
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";

interface Bank {
  id: number;
  name: string;
}

interface BankAccount {
  id: number;
  bankId: string;
  holder: string;
  document: string;
  agency: string;
  account: string;
  accountDigit: string;
  type: 'cashing' | 'saving' | 'digital_wallet';
}

export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileBankAccounts',
  setup(__props) {

const bankAccountTypeLabels = {
  cashing: 'Corrente',
  saving: 'Poupança',
  digital_wallet: 'Carteira digital',
}

const { isCPFValid, isCNPJValid } = useValidation()
const { toast } = useToast()

const isDisabled = computed(() => {
  const { bankId, holder, document, agency, account, accountDigit, type } = newBankAccount.value;

  // Verificar se todos os campos obrigatórios estão preenchidos
  if (!bankId || !holder || !document || !agency || !account || !accountDigit || !type) {
    return true;
  }

  // Verificar o comprimento da agência e do dígito da conta
  if (agency.length !== 4 || accountDigit.length !== 1) {
    return true;
  }

  // Verificar se o tipo da conta é válido
  const validTypes = ['cashing', 'saving', 'digital_wallet'];
  if (!validTypes.includes(type)) {
    return true;
  }

  return false; // Habilitar se todas as validações forem atendidas
});
const { getAllBankAccounts, getAllBanks, createBankAccount, deleteBankAccount, isDeletingBankAccount, isCreatingBankAccount } = useBank()

const banks = ref<Bank[]>([]);
const bankAccounts = ref<BankAccount[]>();
const isDeleteDialogConfirmationOpen = ref(false)
const selectedBankAccountToDeleteId = ref<number>()

const newBankAccount = ref<Omit<BankAccount, 'id'>>({
  bankId: '0',
  holder: '',
  document: '',
  agency: '',
  account: '',
  accountDigit: '',
  type: 'cashing'
});

const getBankName = (bankId: number): string => {
  const bank = banks.value.find(b => b.id === bankId);
  return bank ? bank.name : 'Banco não encontrado';
};

const handleCreateBankAccount = async () => {
  if (newBankAccount.value.document!.length <= 14) {
    if (!isCPFValid(newBankAccount.value.document!)) {
      toast({
        title: 'Cadastro falhou',
        description: 'Documento CPF inválido',
        variant: 'destructive',
        duration: 2000
      })

      return null
    } 
  } else {
    if (!isCNPJValid(newBankAccount.value.document!)) {
      toast({
        title: 'Cadastro falhou',
        description: 'Documento CNPJ inválido',
        variant: 'destructive',
        duration: 2000
      })

      return null
    } 
  }

  const bankAccount = await createBankAccount(newBankAccount.value)
  bankAccounts.value?.push(bankAccount)
}

const handleDeleteBankAccount = async () => {
  await deleteBankAccount(selectedBankAccountToDeleteId.value!)
  isDeleteDialogConfirmationOpen.value = false
  bankAccounts.value = bankAccounts.value?.filter(ba => ba.id != selectedBankAccountToDeleteId.value)
}

onMounted(async () => {
  getAllBankAccounts().then(data => { bankAccounts.value = data })
  getAllBanks().then(data => { banks.value = data })
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (bankAccounts.value)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (bankAccounts.value.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _cache[15] || (_cache[15] = _createElementVNode("h3", { class: "text-lg font-semibold mb-2" }, "Contas Bancárias Cadastradas", -1)),
                _createVNode(_unref(Table), null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(TableHeader), null, {
                      default: _withCtx(() => [
                        _createVNode(_unref(TableRow), null, {
                          default: _withCtx(() => [
                            _createVNode(_unref(TableHead), null, {
                              default: _withCtx(() => _cache[8] || (_cache[8] = [
                                _createTextVNode("Banco")
                              ])),
                              _: 1
                            }),
                            _createVNode(_unref(TableHead), null, {
                              default: _withCtx(() => _cache[9] || (_cache[9] = [
                                _createTextVNode("Agência")
                              ])),
                              _: 1
                            }),
                            _createVNode(_unref(TableHead), null, {
                              default: _withCtx(() => _cache[10] || (_cache[10] = [
                                _createTextVNode("Conta")
                              ])),
                              _: 1
                            }),
                            _createVNode(_unref(TableHead), null, {
                              default: _withCtx(() => _cache[11] || (_cache[11] = [
                                _createTextVNode("Titular")
                              ])),
                              _: 1
                            }),
                            _createVNode(_unref(TableHead), null, {
                              default: _withCtx(() => _cache[12] || (_cache[12] = [
                                _createTextVNode("Tipo")
                              ])),
                              _: 1
                            }),
                            _createVNode(_unref(TableHead), null, {
                              default: _withCtx(() => _cache[13] || (_cache[13] = [
                                _createTextVNode("Ações")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_unref(TableBody), null, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(bankAccounts.value, (account) => {
                          return (_openBlock(), _createBlock(_unref(TableRow), {
                            key: account.id
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(TableCell), null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(getBankName(Number(account.bankId))), 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_unref(TableCell), null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(account.agency), 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_unref(TableCell), null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(account.account) + "-" + _toDisplayString(account.accountDigit), 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_unref(TableCell), null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(account.holder), 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_unref(TableCell), null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(bankAccountTypeLabels[account.type]), 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_unref(TableCell), null, {
                                default: _withCtx(() => [
                                  _createVNode(_unref(Button), {
                                    variant: "destructive",
                                    size: "sm",
                                    onClick: ($event: any) => {selectedBankAccountToDeleteId.value = account.id; isDeleteDialogConfirmationOpen.value = true}
                                  }, {
                                    default: _withCtx(() => _cache[14] || (_cache[14] = [
                                      _createTextVNode(" Excluir ")
                                    ])),
                                    _: 2
                                  }, 1032, ["onClick"])
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, " Nenhuma conta bancária cadastrada. "))
        ], 64))
      : (_openBlock(), _createBlock(SpinnerLoader, { key: 1 })),
    _createElementVNode("form", {
      onSubmit: _withModifiers(handleCreateBankAccount, ["prevent"])
    }, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_unref(Label), { for: "bankId" }, {
            default: _withCtx(() => _cache[16] || (_cache[16] = [
              _createTextVNode("Banco")
            ])),
            _: 1
          }),
          _createVNode(_unref(Select), {
            modelValue: newBankAccount.value.bankId,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((newBankAccount.value.bankId) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(SelectTrigger, {
                id: "status",
                "aria-label": "Selecionar status"
              }, {
                default: _withCtx(() => [
                  _createVNode(SelectValue, { placeholder: "Selecionar" })
                ]),
                _: 1
              }),
              _createVNode(SelectContent, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(banks.value, (bank) => {
                    return (_openBlock(), _createBlock(SelectItem, {
                      key: bank.id,
                      value: String(bank.id)
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(bank.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_unref(Label), { for: "holder" }, {
              default: _withCtx(() => _cache[17] || (_cache[17] = [
                _createTextVNode("Titular da Conta")
              ])),
              _: 1
            }),
            _createVNode(_unref(Input), {
              id: "holder",
              modelValue: newBankAccount.value.holder,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((newBankAccount.value.holder) = $event)),
              required: "",
              placeholder: "ex: João Silva"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_unref(Label), { for: "document" }, {
              default: _withCtx(() => _cache[18] || (_cache[18] = [
                _createTextVNode("CPF/CNPJ")
              ])),
              _: 1
            }),
            _withDirectives(_createVNode(_unref(Input), {
              id: "document",
              modelValue: newBankAccount.value.document,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((newBankAccount.value.document) = $event)),
              required: "",
              placeholder: "ex: 123.456.789-00"
            }, null, 8, ["modelValue"]), [
              [_unref(vMaska), newBankAccount.value.document.length <= 14 ? '###.###.###-###' : '##.###.###/####-##']
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_unref(Label), { for: "agency" }, {
              default: _withCtx(() => _cache[19] || (_cache[19] = [
                _createTextVNode("Agência")
              ])),
              _: 1
            }),
            _createVNode(_unref(Input), {
              id: "agency",
              modelValue: newBankAccount.value.agency,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((newBankAccount.value.agency) = $event)),
              maxlength: "4",
              required: "",
              placeholder: "ex: 1234"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_unref(Label), { for: "account" }, {
              default: _withCtx(() => _cache[20] || (_cache[20] = [
                _createTextVNode("Conta")
              ])),
              _: 1
            }),
            _createVNode(_unref(Input), {
              id: "account",
              modelValue: newBankAccount.value.account,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((newBankAccount.value.account) = $event)),
              required: "",
              placeholder: "ex: 56789"
            }, null, 8, ["modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_unref(Label), { for: "accountDigit" }, {
              default: _withCtx(() => _cache[21] || (_cache[21] = [
                _createTextVNode("Dígito da Conta")
              ])),
              _: 1
            }),
            _createVNode(_unref(Input), {
              id: "accountDigit",
              type: "number",
              modelValue: newBankAccount.value.accountDigit,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((newBankAccount.value.accountDigit) = $event)),
              required: "",
              placeholder: "ex: 0",
              maxlength: "1"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_unref(Label), { for: "type" }, {
              default: _withCtx(() => _cache[22] || (_cache[22] = [
                _createTextVNode("Tipo de Conta")
              ])),
              _: 1
            }),
            _createVNode(_unref(Select), {
              modelValue: newBankAccount.value.type,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((newBankAccount.value.type) = $event)),
              placeholder: "Selecionar"
            }, {
              default: _withCtx(() => [
                _createVNode(SelectTrigger, {
                  id: "type",
                  "aria-label": "Selecionar tipo de conta"
                }, {
                  default: _withCtx(() => [
                    _createVNode(SelectValue, { placeholder: "Selecionar" })
                  ]),
                  _: 1
                }),
                _createVNode(SelectContent, null, {
                  default: _withCtx(() => [
                    _createVNode(SelectItem, { value: "cashing" }, {
                      default: _withCtx(() => _cache[23] || (_cache[23] = [
                        _createTextVNode("Corrente")
                      ])),
                      _: 1
                    }),
                    _createVNode(SelectItem, { value: "saving" }, {
                      default: _withCtx(() => _cache[24] || (_cache[24] = [
                        _createTextVNode("Poupança")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ])
        ]),
        _createVNode(_unref(Button), {
          type: "submit",
          disabled: _unref(isCreatingBankAccount) || isDisabled.value
        }, {
          default: _withCtx(() => [
            (_unref(isCreatingBankAccount))
              ? (_openBlock(), _createBlock(_unref(Loader2), {
                  key: 0,
                  class: "w-4 h-4 mr-2 animate-spin"
                }))
              : _createCommentVNode("", true),
            _cache[25] || (_cache[25] = _createTextVNode(" Adicionar Conta Bancária "))
          ]),
          _: 1
        }, 8, ["disabled"])
      ])
    ], 32),
    (selectedBankAccountToDeleteId.value)
      ? (_openBlock(), _createBlock(_unref(Dialog), {
          key: 2,
          open: isDeleteDialogConfirmationOpen.value,
          "onUpdate:open": _cache[7] || (_cache[7] = (open) => isDeleteDialogConfirmationOpen.value = open)
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(DialogContent), { class: "sm:max-w-md" }, {
              default: _withCtx(() => [
                _createVNode(_unref(DialogHeader), null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_15, [
                      _createVNode(_unref(AlertTriangle), { class: "h-6 w-6" }),
                      _createVNode(_unref(DialogTitle), { class: "text-2xl" }, {
                        default: _withCtx(() => _cache[26] || (_cache[26] = [
                          _createTextVNode("Cuidado")
                        ])),
                        _: 1
                      })
                    ]),
                    _createVNode(_unref(DialogDescription), { class: "py-2" }, {
                      default: _withCtx(() => _cache[27] || (_cache[27] = [
                        _createTextVNode(" Deseja mesmo deletar essa conta? ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _cache[30] || (_cache[30] = _createElementVNode("div", { class: "flex items-center space-x-2" }, null, -1)),
                _createVNode(_unref(DialogFooter), { class: "sm:justify-start" }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(DialogClose), { "as-child": "" }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(Button), {
                          type: "button",
                          variant: "secondary"
                        }, {
                          default: _withCtx(() => _cache[28] || (_cache[28] = [
                            _createTextVNode(" Cancelar ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_unref(Button), {
                      type: "button",
                      disabled: _unref(isDeletingBankAccount),
                      onClick: handleDeleteBankAccount
                    }, {
                      default: _withCtx(() => [
                        (_unref(isDeletingBankAccount))
                          ? (_openBlock(), _createBlock(_unref(Loader2), {
                              key: 0,
                              class: "w-4 h-4 mr-2 animate-spin"
                            }))
                          : _createCommentVNode("", true),
                        _cache[29] || (_cache[29] = _createTextVNode(" Confirmar "))
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["open"]))
      : _createCommentVNode("", true)
  ]))
}
}

})