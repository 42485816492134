import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { type HTMLAttributes, computed } from 'vue'
import {
  ScrollAreaCorner,
  ScrollAreaRoot,
  type ScrollAreaRootProps,
  ScrollAreaViewport,
} from 'radix-vue'
import ScrollBar from './ScrollBar.vue'
import { cn } from '@/lib/utils'


export default /*@__PURE__*/_defineComponent({
  __name: 'ScrollArea',
  props: {
    type: {},
    dir: {},
    scrollHideDelay: {},
    asChild: { type: Boolean },
    as: {},
    class: {}
  },
  setup(__props: any) {

const props = __props

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(ScrollAreaRoot), _mergeProps(delegatedProps.value, {
    class: _unref(cn)('relative overflow-hidden', props.class)
  }), {
    default: _withCtx(() => [
      _createVNode(_unref(ScrollAreaViewport), { class: "h-full w-full rounded-[inherit]" }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }),
      _createVNode(ScrollBar),
      _createVNode(_unref(ScrollAreaCorner))
    ]),
    _: 3
  }, 16, ["class"]))
}
}

})