import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { type HTMLAttributes, computed } from 'vue'
import { ScrollAreaScrollbar, type ScrollAreaScrollbarProps, ScrollAreaThumb } from 'radix-vue'
import { cn } from '@/lib/utils'


export default /*@__PURE__*/_defineComponent({
  __name: 'ScrollBar',
  props: {
    orientation: { default: 'vertical' },
    forceMount: { type: Boolean },
    asChild: { type: Boolean },
    as: {},
    class: {}
  },
  setup(__props: any) {

const props = __props

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(ScrollAreaScrollbar), _mergeProps(delegatedProps.value, {
    class: 
      _unref(cn)('flex touch-none select-none transition-colors',
         _ctx.orientation === 'vertical'
           && 'h-full w-2.5 border-l border-l-transparent p-px',
         _ctx.orientation === 'horizontal'
           && 'h-2.5 flex-col border-t border-t-transparent p-px',
         props.class)
  }), {
    default: _withCtx(() => [
      _createVNode(_unref(ScrollAreaThumb), { class: "relative flex-1 rounded-full bg-border" })
    ]),
    _: 1
  }, 16, ["class"]))
}
}

})