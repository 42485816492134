<template>
  <div class="text-gray-100 p-8 max-w-4xl mx-auto bg-card mt-8 rounded-lg overflow-auto" v-if="rankings">
    <!-- <h1 class="text-4xl font-bold text-center mb-8">Ranking de Membros</h1> -->

    <TopThree v-if="topThree && topThree.length > 2" :winners="topThree" />
    
    <div class="max-w-7xl mx-auto bg-card rounded-lg shadow-xl overflow-hidden">
      <div class="p-6">
        <div class="flex flex-wrap items-center justify-between gap-4 mb-6">
          <!-- <Input 
            placeholder="Pesquisar membro..." 
            class="max-w-xs"
            v-model="searchQuery"
          /> -->
          <!-- <Select v-model="selectedCategory">
            <SelectTrigger class="w-[180px]">
              <SelectValue placeholder="Categoria" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="overall">Geral</SelectItem>
              <SelectItem value="monthly">Mensal</SelectItem>
              <SelectItem value="weekly">Semanal</SelectItem>
            </SelectContent>
          </Select> -->
        </div>
        
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead class="w-[100px]">Posição</TableHead>
              <TableHead>Membro</TableHead>
              <TableHead>Patente</TableHead>
              <TableHead>Pontos</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <template v-if="rankings && rankings.data.length">
              <TableRow 
                v-for="(ranking, index) in rankings.data" 
                :key="ranking.id"
                :class="{
                  'bg-yellow-900/20' : ranking.userId === userAuthenticated.userId
                }"
                class="transition-all hover:bg-gray-700/50"
              >
                <TableCell class="font-medium">
                  <div class="flex items-center gap-2">
                    <div 
                      :class="[
                        'w-8 h-8 rounded-full flex items-center justify-center text-xs font-bold',
                        index === 0 ? 'bg-yellow-500 text-yellow-900' :
                        index === 1 ? 'bg-gray-300 text-gray-800' :
                        index === 2 ? 'bg-orange-500 text-orange-900' :
                        'bg-gray-700'
                      ]"
                    >
                      {{ index + 1 }}
                    </div>
                    <!-- <TrendingUpIcon v-if="ranking.trend === 'up'" class="text-green-500" /> -->
                    <!-- <TrendingDownIcon v-else-if="ranking.trend === 'down'" class="text-red-500" /> -->
                  </div>
                </TableCell>
                <TableCell>
                  <div class="flex items-center gap-3">
                    <Avatar>
                      <AvatarImage v-if="ranking.profile.avatar" :src="ranking.profile.avatar?.bucketLocation" :alt="ranking.profile.name" />
                      <AvatarFallback>{{ ranking.profile.name?.split(' ').map(word => word.charAt(0).toUpperCase()).join('') }}</AvatarFallback>
                    </Avatar>
                    <div>
                      <div class="font-semibold">{{ ranking.profile.name }}</div>
                      <div class="text-sm text-gray-400">{{ ranking.profile.description }}</div>
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <div class="flex items-center gap-2">
                    <ShieldIcon :class="[
                      'w-5 h-5',
                      ranking.amount < 10 ? 'text-gray-500' :
                      ranking.amount < 20 ? 'text-blue-500' :
                      ranking.amount < 30 ? 'text-purple-500' :
                      'text-yellow-500'
                    ]" />
                    {{ ranking.amount }}
                  </div>
                </TableCell>
                <TableCell>
                  <div class="font-mono">{{ ranking.amount.toLocaleString() }}</div>
                </TableCell>
              </TableRow>
            </template>
            <template v-else>
              <TableRow>
                <TableCell colspan="5" class="text-center font-medium py-8">
                  Nada para mostrar por enquanto, continue acompanhando!
                </TableCell>
              </TableRow>
            </template>
          </TableBody>
        </Table>
      </div>
    </div>

    <PaginationApi :data="rankings.pagination" :paginate="(page) => getRankings(tournamentId, page)" />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { Input } from '@/components/ui/input'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { ShieldIcon } from 'lucide-vue-next'
import TopThree from './components/TopThree.vue'
import { useRanking } from '@/composables/useRanking'
import PaginationApi from '@/components/ui/pagination/PaginationApi.vue'
import { TournamentRanking } from '@/interfaces/tournaments/TournamentRanking'
import { useAuth } from '@/composables/useAuth'

const topThree = ref<TournamentRanking[]>()
const props = defineProps<{ tournamentId: number }>()
const { getRankings, rankings } = useRanking()

const { userAuthenticated } = useAuth()

onMounted(() => {
  getRankings(props.tournamentId)
    .then(() => {
      topThree.value = rankings.value!.data
    })
})
</script>

<style scoped>
/* Add any additional styles here */
</style>