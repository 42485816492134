import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/hoopay-logo.png'


const _hoisted_1 = { class: "w-full lg:grid lg:grid-cols-1 min-h-[100vh] pt-14 sm:pt-0" }
const _hoisted_2 = { class: "flex items-center justify-center py-12" }
const _hoisted_3 = { class: "grid gap-4" }
const _hoisted_4 = { class: "grid gap-2" }

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { useToast } from '@/components/ui/toast/use-toast'
import { Toaster } from '@/components/ui/toast'
import { ref } from 'vue'
import { KeyRound, Loader2 } from 'lucide-vue-next'
import { useAuth } from '@/composables/useAuth'


export default /*@__PURE__*/_defineComponent({
  __name: 'ResetPasswordView',
  props: {
    token: {}
  },
  setup(__props: any) {


const { toast } = useToast()
const password = ref('')
const { resetPassword, isAuthLoading } = useAuth()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("form", {
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_unref(resetPassword)(
         { token: _ctx.token, password: password.value },
        () => _unref(toast)({
          title: 'Senha redefinida com sucesso',
          class: 'bg-green-500 text-white'
        }),
        () => _unref(toast)({
          title: 'Não foi possível redefinir a senha',
          description: 'Link de redefinição inválido ou expirado',
          variant: 'destructive'
        })
      )), ["prevent"])),
          class: "mx-auto grid w-full p-4 sm:p-0 sm:w-[390px] gap-6"
        }, [
          _cache[4] || (_cache[4] = _createStaticVNode("<div class=\"grid gap-2 text-center\"><div class=\"flex justify-center mb-4\"><img src=\"" + _imports_0 + "\" height=\"42\" width=\"42\" class=\"\"></div><div class=\"flex items-center gap-3 mx-auto\"><h1 class=\"text-3xl font-bold\"> Redefinir senha </h1></div><p class=\"text-muted-foreground\"> Digite uma nova senha para a sua conta </p></div>", 1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_unref(Label), { for: "password" }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("Senha")
                ])),
                _: 1
              }),
              _createVNode(_unref(Input), {
                id: "password",
                type: "password",
                placeholder: "ex: 123",
                required: "",
                modelValue: password.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((password).value = $event))
              }, null, 8, ["modelValue"])
            ]),
            _createVNode(_unref(Button), {
              type: "submit",
              class: "w-full py-6 mt-6",
              disabled: _unref(isAuthLoading) || !password.value
            }, {
              default: _withCtx(() => [
                (_unref(isAuthLoading))
                  ? (_openBlock(), _createBlock(_unref(Loader2), {
                      key: 0,
                      class: "w-4 h-4 mr-2 animate-spin"
                    }))
                  : _createCommentVNode("", true),
                _cache[3] || (_cache[3] = _createTextVNode(" Salvar "))
              ]),
              _: 1
            }, 8, ["disabled"])
          ])
        ], 32)
      ])
    ]),
    _createVNode(_unref(Toaster))
  ], 64))
}
}

})