import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full lg:w-[821px] space-y-6" }
const _hoisted_2 = {
  key: 2,
  class: "grid gap-6"
}
const _hoisted_3 = { class: "h-[260px] w-full bg-muted rounded-lg" }

import { onBeforeUnmount, onMounted, ref } from 'vue'
import { Button } from '@/components/ui/button'
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from '@/components/ui/card'
import { Textarea } from '@/components/ui/textarea'
import { Loader2 } from 'lucide-vue-next'
import Topic from './components/topic/Topic.vue'
import { useAxios } from '@/composables/useAxios'
import { toast, Toaster } from '@/components/ui/toast'
import { Pagination } from '@/interfaces/Pagination'
import { CommunityTopic } from '@/interfaces/courses/communities/CommunityTopic'

interface CreateFeedTopicPayload {
  content: string,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FeedView',
  setup(__props) {

const topicsWrapper = ref<HTMLElement | null>(null)
const axios = useAxios()
const userAuthenticated = JSON.parse(localStorage.getItem('authUser') ?? '')
const isFeedLoading = ref(false)

const payload = ref<CreateFeedTopicPayload>({
  content: '',
})
const isCreatingFeedTopic = ref(false)
const topics = ref<Pagination<CommunityTopic>>()

// const friendSuggestions = ref([
//   { id: 1, name: 'Carlos Oliveira', avatar: '/placeholder.svg?height=40&width=40', initials: 'CO' },
//   { id: 2, name: 'Ana Rodrigues', avatar: '/placeholder.svg?height=40&width=40', initials: 'AR' },
// ])

// const upcomingEvents = ref([
//   { id: 1, name: 'Meetup de Desenvolvedores', date: '15 de Maio, 19:00' },
//   { id: 2, name: 'Workshop de Vue.js', date: '22 de Maio, 14:00' },
// ])

function createFeedTopic(data: CreateFeedTopicPayload) {
  isCreatingFeedTopic.value = true

  axios.post(`/courses/communities/topics`, { content: data.content, type: 'feed'})
    .then(() => {
      toast({
        title: 'Publicação cadastrado com sucesso',
        class: 'bg-green-500 text-white'
      })
      getFeed()
      payload.value = {
        content: '',
      }
    })
    .finally(() => isCreatingFeedTopic.value = false)
}

function getFeed() {
  const page = topics.value?.pagination.nextPage ?? 1
  isFeedLoading.value = true

  axios.get('/courses/communities/topics/feed', { params: { page } })
    .then((res) => {
      topics.value = { ...res.data, data: topics.value && topics.value?.pagination.nextPage ? [...topics.value.data, ...res.data.data] : res.data.data }
    })
    .finally(() => isFeedLoading.value = false)
}

function handleScroll() {
  if ((topicsWrapper.value?.getBoundingClientRect().bottom ?? 0) < window.innerHeight && (topicsWrapper.value && topics.value?.pagination.nextPage) && !isFeedLoading.value) {
    getFeed()
  }
}

onMounted(() => {
  getFeed()
  window.addEventListener('scroll', handleScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      (_unref(userAuthenticated).roles.find((role) => role === 'ADMIN' || 'PRODUCER'))
        ? (_openBlock(), _createBlock(_unref(Card), {
            key: 0,
            class: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(CardHeader), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(CardTitle), { class: "text-[1.1rem]" }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("Criar Publicação")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_unref(CardContent), { class: "grid gap-6" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(Textarea), {
                    modelValue: payload.value.content,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((payload.value.content) = $event)),
                    placeholder: "No que você está pensando?"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_unref(CardFooter), { class: "flex justify-between" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(Button), {
                    disabled: !payload.value.content || isCreatingFeedTopic.value,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (createFeedTopic(payload.value)))
                  }, {
                    default: _withCtx(() => [
                      (isCreatingFeedTopic.value)
                        ? (_openBlock(), _createBlock(_unref(Loader2), {
                            key: 0,
                            class: "w-4 h-4 mr-2 animate-spin"
                          }))
                        : _createCommentVNode("", true),
                      _cache[3] || (_cache[3] = _createTextVNode(" Publicar "))
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (topics.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "grid gap-6",
            ref_key: "topicsWrapper",
            ref: topicsWrapper
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(topics.value.data, (topic, index) => {
              return (_openBlock(), _createBlock(Topic, {
                key: topic.id,
                topic: topic,
                onDelete: ($event: any) => (topics.value.data.splice(index, 1))
              }, null, 8, ["topic", "onDelete"]))
            }), 128))
          ], 512))
        : _createCommentVNode("", true),
      (isFeedLoading.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1,2,3,4], (i) => {
              return _createElementVNode("div", _hoisted_3)
            }), 64))
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_unref(Toaster))
  ], 64))
}
}

})