<template>
  <div class="inline-flex" :style="{ height: height, width: width }">
    <div
      @dragenter.prevent="dragenter"
      @dragleave.prevent="dragleave"
      @dragover.prevent
      @drop.prevent="drop"
      :class="[
        'relative border-2 border-dashed rounded-lg transition-all ease-in-out duration-300 grid justify-center items-center',
        isDragActive ? 'border-primary bg-primary/10' : 'border-gray-300 hover:border-primary hover:bg-muted-1/2'
      ]"
    >
      <input
        type="file"
        ref="fileInput"
        @input="onFileChange"
        accept="image/png, image/jpg, image/jpeg, image/gif, image/avif"
        class="hidden"
      />
      <div v-if="!file && !defaultPreview" class="text-center p-8">
        <Upload class="mx-auto h-12 w-12 text-gray-400" />
        <p class="mt-2 text-sm text-gray-600">
          Arraste e solte uma imagem ou clique em selecionar
        </p>
        <button
          @click.prevent="$refs.fileInput.click()"
          class="mt-4 px-4 py-2 bg-primary text-white rounded-md hover:bg-primary-dark transition-colors"
        >
          Selecionar
        </button>
      </div>
      <div v-if="file || defaultPreview" class="relative">
        <img
          :src="preview ? preview : defaultPreview"
          alt="Preview"
          class="object-cover rounded-md transition-all duration-300 ease-in-out hover:scale-105"
          :style="{ height: height, width: width }"
        />
        <button
          v-if="preview"
          @click.prevent.stop="resetUpload"
          class="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors"
        >
          <X class="h-4 w-4" />
        </button>
        <button
          v-else
          @click.prevent.stop="$refs.fileInput.click()"
          class="absolute top-2 right-2 p-1 bg-muted text-white rounded-full hover:bg-red-600 transition-colors"
        >
          <Pencil class="h-4 w-4" />
        </button>
      </div>
    </div>
    <!-- <div v-if="file" class="mt-4">
      <div class="flex items-center justify-between mb-2">
        <span class="text-sm font-medium text-gray-700">{{ file.name }}</span>
        <span class="text-sm text-gray-500">{{ Math.round(file.size / 1024) }} KB</span>
      </div>
      <div class="relative pt-1">
        <div class="flex mb-2 items-center justify-between">
          <div>
            <span
              class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-primary bg-primary-50"
            >
              {{ uploadComplete ? "Complete" : "Uploading" }}
            </span>
          </div>
          <div class="text-right">
            <span class="text-xs font-semibold inline-block text-primary">
              {{ uploadProgress }}%
            </span>
          </div>
        </div>
        <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-primary-200">
          <div
            :style="{ width: `${uploadProgress}%` }"
            class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-primary transition-all duration-500 ease-in-out"
          ></div>
        </div>
      </div>
    </div> -->
    <!-- <div v-if="uploadComplete" class="mt-4 flex items-center justify-center text-green-500">
      <Check class="mr-2 h-5 w-5" />
      <span>Upload complete!</span>
    </div> -->
  </div>
</template>

<script lang="ts" setup>
import { HTMLAttributes, ref, watch } from 'vue'
import { Upload, X, Check, Pencil } from 'lucide-vue-next'

withDefaults(defineProps<{ defaultPreview?: string, width?: number | string, height?: number | string }>(), {
  width: '18rem',
  height: '18rem',
})
const emit = defineEmits(['update:modelValue'])

const file = ref<any>(null)
const preview = ref<string | null>(null)
const uploadProgress = ref(0)
const uploadComplete = ref(false)
const isDragActive = ref(false)
const fileInput = ref(null)

const onFileChange = (event: any) => {
  const selectedFile = event.target.files[0]
  if (selectedFile) {
    setFile(selectedFile)
  }
  event.target.value = ''
}

const setFile = (selectedFile: any) => {
  file.value = selectedFile
  preview.value = URL.createObjectURL(selectedFile)
  emit('update:modelValue', selectedFile)
  // simulateUpload()
}

// const simulateUpload = () => {
//   uploadProgress.value = 0
//   uploadComplete.value = false
//   const interval = setInterval(() => {
//     uploadProgress.value += 10
//     if (uploadProgress.value >= 100) {
//       clearInterval(interval)
//       uploadComplete.value = true
//     }
//   }, 300)
// }

const resetUpload = () => {
  file.value = null
  preview.value = null
  uploadProgress.value = 0
  uploadComplete.value = false
  emit('update:modelValue', null)
}

const dragenter = () => {
  isDragActive.value = true
}

const dragleave = () => {
  isDragActive.value = false
}

const drop = (event: any) => {
  isDragActive.value = false
  const droppedFile = event.dataTransfer.files[0]
  if (droppedFile && droppedFile.type.startsWith('image/')) {
    setFile(droppedFile)
  }
}

// Clean up object URL when component is unmounted
watch(preview, (newValue, oldValue) => {
  if (oldValue) {
    URL.revokeObjectURL(oldValue)
  }
})
</script>