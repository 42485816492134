export function useFile() {
  function downloadFile(url: string, fileName: string) {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName; // Nome do arquivo desejado
    link.target = '_blank'
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Remove o link após o clique
  }

  return {
    downloadFile
  }
}