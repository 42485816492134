import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-center p-4 relative pb-0" }
const _hoisted_2 = { class: "w-full relative" }
const _hoisted_3 = { class: "relative z-10 mt-5" }
const _hoisted_4 = { class: "podium-container" }
const _hoisted_5 = { class: "podium" }
const _hoisted_6 = { class: "podium__front podium__left" }
const _hoisted_7 = { class: "text-[17px] font-semibold text-white pb-2" }
const _hoisted_8 = { class: "text-gray-600 font-bold text-[17px]" }
const _hoisted_9 = { class: "podium__image" }
const _hoisted_10 = { class: "w-32 h-32 rounded-full overflow-hidden border-4 border-gray-300" }
const _hoisted_11 = ["src", "alt"]
const _hoisted_12 = { class: "podium__front podium__center" }
const _hoisted_13 = { class: "text-[17px] font-semibold text-white pb-2" }
const _hoisted_14 = { class: "text-gray-600 font-bold text-[17px]" }
const _hoisted_15 = { class: "podium__image" }
const _hoisted_16 = { class: "w-32 h-32 rounded-full overflow-hidden border-4 border-gray-300" }
const _hoisted_17 = ["src", "alt"]
const _hoisted_18 = { class: "podium__front podium__right" }
const _hoisted_19 = { class: "text-[17px] font-semibold text-white pb-2" }
const _hoisted_20 = { class: "text-gray-600 font-bold text-[17px]" }
const _hoisted_21 = { class: "podium__image" }
const _hoisted_22 = { class: "w-32 h-32 rounded-full overflow-hidden border-4 border-gray-300" }
const _hoisted_23 = ["src", "alt"]
const _hoisted_24 = { class: "mt-8 text-center" }
const _hoisted_25 = { class: "inline-block animate-pulse" }

import { ref } from 'vue'
import { StarIcon } from 'lucide-vue-next'
import { TournamentRanking } from '@/interfaces/tournaments/TournamentRanking';


export default /*@__PURE__*/_defineComponent({
  __name: 'TopThree',
  props: {
    winners: {}
  },
  setup(__props: any) {



const formatCurrency = (value: number) => {
  return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "text-4xl font-bold text-center text-white mb-8" }, "Top 3 Traders", -1)),
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "absolute w-full h-full rounded-full pointer-events-none lightbulb" }, null, -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.winners[1].profile.name), 1),
              _createElementVNode("p", _hoisted_8, _toDisplayString(formatCurrency(_ctx.winners[1].amount)), 1),
              _cache[0] || (_cache[0] = _createElementVNode("div", { class: "" }, "2", -1)),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("img", {
                    src: _ctx.winners[1].profile.avatar?.bucketLocation,
                    alt: _ctx.winners[1].profile.name,
                    class: "w-full h-full object-cover"
                  }, null, 8, _hoisted_11)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("h3", _hoisted_13, _toDisplayString(_ctx.winners[0].profile.name), 1),
              _createElementVNode("p", _hoisted_14, _toDisplayString(formatCurrency(_ctx.winners[0].amount)), 1),
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "" }, "1", -1)),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("img", {
                    src: _ctx.winners[0].profile.avatar?.bucketLocation,
                    alt: _ctx.winners[0].profile.name,
                    class: "w-full h-full object-cover"
                  }, null, 8, _hoisted_17)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("h3", _hoisted_19, _toDisplayString(_ctx.winners[2].profile.name), 1),
              _createElementVNode("p", _hoisted_20, _toDisplayString(formatCurrency(_ctx.winners[2].amount)), 1),
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "" }, "3", -1)),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("img", {
                    src: _ctx.winners[2].profile.avatar?.bucketLocation,
                    alt: _ctx.winners[2].profile.name,
                    class: "w-full h-full object-cover"
                  }, null, 8, _hoisted_23)
                ])
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_24, [
        _createElementVNode("span", _hoisted_25, [
          _createVNode(_unref(StarIcon), { class: "h-8 w-8 text-yellow-400" })
        ])
      ])
    ])
  ]))
}
}

})