import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "grid gap-1" }

import { isVNode } from 'vue'
import { useToast } from './use-toast'
import { Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from '.'


export default /*@__PURE__*/_defineComponent({
  __name: 'Toaster',
  setup(__props) {

const { toasts } = useToast()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(ToastProvider), null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(toasts), (toast) => {
        return (_openBlock(), _createBlock(_unref(Toast), _mergeProps({
          key: toast.id,
          ref_for: true
        }, toast), {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              (toast.title)
                ? (_openBlock(), _createBlock(_unref(ToastTitle), { key: 0 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(toast.title), 1)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true),
              (toast.description)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (isVNode(toast.description))
                      ? (_openBlock(), _createBlock(_unref(ToastDescription), { key: 0 }, {
                          default: _withCtx(() => [
                            (_openBlock(), _createBlock(_resolveDynamicComponent(toast.description)))
                          ]),
                          _: 2
                        }, 1024))
                      : (_openBlock(), _createBlock(_unref(ToastDescription), { key: 1 }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(toast.description), 1)
                          ]),
                          _: 2
                        }, 1024))
                  ], 64))
                : _createCommentVNode("", true),
              _createVNode(_unref(ToastClose))
            ]),
            (_openBlock(), _createBlock(_resolveDynamicComponent(toast.action)))
          ]),
          _: 2
        }, 1040))
      }), 128)),
      _createVNode(_unref(ToastViewport))
    ]),
    _: 1
  }))
}
}

})