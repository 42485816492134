export enum LessonStatusEnum {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  // DELETED = 'deleted',
}

export const lessonStatusLabels = {
  [LessonStatusEnum.ACTIVE]: 'Publicado',
  [LessonStatusEnum.INACTIVE]: 'Não publicado',
  // [LessonStatusEnum.DELETED]: 'Deletado',
}