import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "overflow-hidden rounded-md" }
const _hoisted_2 = ["src", "alt", "width", "height"]
const _hoisted_3 = { class: "space-y-1 text-sm" }
const _hoisted_4 = { class: "font-medium leading-none" }
const _hoisted_5 = { class: "text-xs text-muted-foreground" }

import { playlists } from '../data/playlists'
import { PlusCircleIcon } from 'lucide-vue-next'
import { cn } from '@/lib/utils'
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSeparator,
  ContextMenuSub,
  ContextMenuSubContent,
  ContextMenuSubTrigger,
  ContextMenuTrigger,
} from '@/components/ui/context-menu';
import { Course } from '@/interfaces/Course';
import { CourseMediaTypeEnum } from '@/enums/course/CourseMediaTypeEnum';
import { computed } from 'vue';

interface AlbumArtworkProps {
  course: Course
  aspectRatio?: 'portrait' | 'square'
  width?: number
  height?: number
}

export default /*@__PURE__*/_defineComponent({
  __name: 'CourseArtwork',
  props: {
    course: {},
    aspectRatio: { default: 'portrait' },
    width: {},
    height: {}
  },
  setup(__props: any) {

const props = __props

const courseImage = computed(() => props.course.media.find((m => m.type === CourseMediaTypeEnum.MAIN)))

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_unref(cn)('space-y-3', _ctx.$attrs.class ?? ''))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: courseImage.value?.bucketLocation,
        alt: _ctx.course.title,
        width: _ctx.width,
        height: _ctx.height,
        onError: _cache[0] || (_cache[0] = ($event: any) => (($event.target as HTMLInputElement).src = '/assets/img/no-image.png')),
        class: _normalizeClass(_unref(cn)(
          'h-auto w-auto object-cover transition-all hover:scale-105',
          _ctx.aspectRatio === 'portrait' ? 'aspect-[3/4]' : 'aspect-square',
        ))
      }, null, 42, _hoisted_2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.course.title), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.course.introduction), 1)
    ])
  ], 2))
}
}

})