import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "w-2.5 h-2.5 mr-2" }
const _hoisted_3 = {
  width: "100%",
  height: "100%",
  viewBox: "0 0 30 30"
}
const _hoisted_4 = ["stroke", "fill"]
const _hoisted_5 = { class: "font-semibold ml-4" }

import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'


export default /*@__PURE__*/_defineComponent({
  __name: 'ChartTooltip',
  props: {
    title: {},
    data: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Card), { class: "text-sm" }, {
    default: _withCtx(() => [
      (_ctx.title)
        ? (_openBlock(), _createBlock(_unref(CardHeader), {
            key: 0,
            class: "p-3 border-b"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(CardTitle), null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.title), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_unref(CardContent), { class: "p-3 min-w-[180px] flex flex-col gap-1" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, key) => {
            return (_openBlock(), _createElementBlock("div", {
              key: key,
              class: "flex justify-between"
            }, [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("span", _hoisted_2, [
                  (_openBlock(), _createElementBlock("svg", _hoisted_3, [
                    _createElementVNode("path", {
                      d: " M 15 15 m -14, 0 a 14,14 0 1,1 28,0 a 14,14 0 1,1 -28,0",
                      stroke: item.color,
                      fill: item.color,
                      "stroke-width": "1"
                    }, null, 8, _hoisted_4)
                  ]))
                ]),
                _createElementVNode("span", null, _toDisplayString(item.name), 1)
              ]),
              _createElementVNode("span", _hoisted_5, _toDisplayString(item.value), 1)
            ]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})