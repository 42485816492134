import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { type HTMLAttributes, computed } from 'vue'
import {
  DialogClose,
  DialogContent,
  type DialogContentEmits,
  type DialogContentProps,
  DialogOverlay,
  DialogPortal,
  useForwardPropsEmits,
} from 'radix-vue'
import { X } from 'lucide-vue-next'
import { cn } from '@/lib/utils'


export default /*@__PURE__*/_defineComponent({
  __name: 'DialogScrollContent',
  props: {
    forceMount: { type: Boolean },
    trapFocus: { type: Boolean },
    disableOutsidePointerEvents: { type: Boolean },
    asChild: { type: Boolean },
    as: {},
    class: {}
  },
  emits: ["escapeKeyDown", "pointerDownOutside", "focusOutside", "interactOutside", "openAutoFocus", "closeAutoFocus"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emits = __emit

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(DialogPortal), null, {
    default: _withCtx(() => [
      _createVNode(_unref(DialogOverlay), { class: "fixed inset-0 z-50 grid place-items-center overflow-y-auto bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" }, {
        default: _withCtx(() => [
          _createVNode(_unref(DialogContent), _mergeProps({
            class: 
          _unref(cn)(
            'relative z-50 grid w-full max-w-lg my-8 gap-4 border border-border bg-background p-6 shadow-lg duration-200 sm:rounded-lg md:w-full',
            props.class,
          )
        
          }, _unref(forwarded), {
            onPointerDownOutside: _cache[0] || (_cache[0] = (event) => {
          const originalEvent = event.detail.originalEvent;
          const target = originalEvent.target as HTMLElement;
          if (originalEvent.offsetX > target.clientWidth || originalEvent.offsetY > target.clientHeight) {
            event.preventDefault();
          }
        })
          }), {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default"),
              _createVNode(_unref(DialogClose), { class: "absolute top-3 right-3 p-0.5 transition-colors rounded-md hover:bg-secondary" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(X), { class: "w-4 h-4" }),
                  _cache[1] || (_cache[1] = _createElementVNode("span", { class: "sr-only" }, "Close", -1))
                ]),
                _: 1
              })
            ]),
            _: 3
          }, 16, ["class"])
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}
}

})