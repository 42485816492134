<template>
  <div class="flex items-center justify-center p-4 relative pb-0">
    <div class="w-full relative">
      <h1 class="text-4xl font-bold text-center text-white mb-8">Top 3 Traders</h1>

      <!-- Luz focal vinda do teto -->
      <div class="absolute w-full h-full rounded-full pointer-events-none lightbulb"></div>

      <!-- Pódio -->
      <div class="relative z-10 mt-5">
        <div class="podium-container">
          <div class="podium">
            <div class="podium__front podium__left">
              <h3 class="text-[17px] font-semibold text-white pb-2">{{ winners[1].profile.name }}</h3>
              <p class="text-gray-600 font-bold text-[17px]">{{ formatCurrency(winners[1].amount) }}</p>

              <div class="">2</div>
              <div class="podium__image">
                <div class="w-32 h-32 rounded-full overflow-hidden border-4 border-gray-300">
                  <img :src="winners[1].profile.avatar?.bucketLocation" :alt="winners[1].profile.name" class="w-full h-full object-cover" />
                </div>
              </div>
            </div>

            <div class="podium__front podium__center">
              <h3 class="text-[17px] font-semibold text-white pb-2">{{ winners[0].profile.name }}</h3>
              <p class="text-gray-600 font-bold text-[17px]">{{ formatCurrency(winners[0].amount) }}</p>

              <div class="">1</div>
              <div class="podium__image">
                <div class="w-32 h-32 rounded-full overflow-hidden border-4 border-gray-300">
                  <img :src="winners[0].profile.avatar?.bucketLocation" :alt="winners[0].profile.name" class="w-full h-full object-cover" />
                </div>
              </div>
            </div>

            <div class="podium__front podium__right">
              <h3 class="text-[17px] font-semibold text-white pb-2">{{ winners[2].profile.name }}</h3>
              <p class="text-gray-600 font-bold text-[17px]">{{ formatCurrency(winners[2].amount) }}</p>

              <div class="">3</div>
              <div class="podium__image">
                <div class="w-32 h-32 rounded-full overflow-hidden border-4 border-gray-300">
                  <img :src="winners[2].profile.avatar?.bucketLocation" :alt="winners[2].profile.name" class="w-full h-full object-cover" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Efeito de brilho -->
      <div class="mt-8 text-center">
        <span class="inline-block animate-pulse">
          <StarIcon class="h-8 w-8 text-yellow-400" />
        </span>
        <!-- <p class="text-white text-lg mt-2">Parabéns aos nossos top vendedores!</p> -->
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { StarIcon } from 'lucide-vue-next'
import { TournamentRanking } from '@/interfaces/tournaments/TournamentRanking';

defineProps<{ winners: TournamentRanking[] }>()

const formatCurrency = (value: number) => {
  return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)
}
</script>

<style scoped>
/* Animação de brilho */
@keyframes float {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0px);
  }
}

.animate-float {
  animation: float 3s ease-in-out infinite;
}

.lightbulb {
  width: 15rem;
  height: 10rem;
  aspect-ratio: 1 / 1;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  filter: blur(6rem) brightness(150%);
  top: 0;
  left: 50%;
}

/* PODIUM */
.podium-container {
  --_podium-size: 4px;

  @media only screen and (min-width: 600px) {
    --_podium-size: 7.5px;
  }

  @media only screen and (min-width: 960px) {
    --_podium-size: 10px;
  }

  perspective: calc(var(--_podium-size) * 50);
  margin-top: calc(var(--_podium-size) * 20);
  display: grid;
  place-items: center;
}

.podium {
  display: grid;
  align-items: end;
  grid-template-columns: repeat(3, 1fr);
  transform-style: preserve-3d;
  font-size: calc(var(--_podium-size) * 12);
  font-weight: 900;
  gap: 12px;
  transform: rotateX(-20deg);
  text-align: center;
}

.podium__front {
  background: red;
  padding-inline: calc(var(--_podium-size) * 5);
  padding-bottom: calc(var(--_podium-size) * 3);
  position: relative;
  transform-style: preserve-3d;
  transform-origin: top;
  transition: transform 0.25s;
}

.podium__front:hover {
  transform: translate3d(0, 0, 15px);
}

.podium__center {
  transform: translate3d(0, 0, 5px);
}

.podium__center:hover {
  transform: translate3d(0, 0, 20px);
}

.podium__front::after {
  transform-style: preserve-3d;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: top;
  transform: rotatex(-90deg);
}

.podium__left::before,
.podium__right::before {
  background: #394c0c;
  background: linear-gradient(to left, #394c0c, transparent);
  transform-style: preserve-3d;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: right;
  transform: rotatey(-90deg);
}

.podium__right::before {
  background: linear-gradient(to right, #394c0c, transparent);
  transform-origin: left;
  transform: rotatey(90deg);
}

.podium__left {
  padding-top: calc(var(--_podium-size) * 1);
  background: #81ac1c;
}

.podium__left::after {
  background: #739919;
  background: linear-gradient(#739919, transparent);
}

.podium__center {
  padding-top: calc(var(--_podium-size) * 5);
  background: #90c020;
}

.podium__center::after {
  background: #81ac1c;
  background: linear-gradient(#90c020, transparent);
}

.podium__right {
  padding-top: calc(var(--_podium-size));
  background: #739919;
}

.podium__right::after {
  background: #648616;
  background: linear-gradient(#648616, transparent);
}

.podium__image {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translate(-50%, -80%) translatez(-55px) rotatey(0);
  transform-style: preserve-3d;
  transform-origin: center;
}

.podium__image>img {
  width: calc(var(--_podium-size) * 10);
}

.podium__image div::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(var(--_podium-size) * 6);
  border-radius: 100%;
  filter: blur(20px);
  background: #0e1303;
  background: linear-gradient(#0e1303, transparent);
  transform: rotatex(90deg);
}

.podium__front:hover>.podium__image {
  animation: rotate 10s linear infinite;
}

@keyframes rotate {
  to {
    transform: translate(-50%, -80%) translateZ(-55px) rotateY(360deg);
  }
}

@media (max-width: 400px) {
  .podium {
    gap: 8px;
    transform: rotateX(-20deg) scale(0.8);
  }
}

@media (min-width: 640px) {
  .podium {
    gap: 8px;
    transform: rotateX(-20deg) scale(0.9);
  }
}

@media (min-width: 1024px) {
  .podium {
    gap: 12px;
    transform: rotateX(-20deg) scale(1);
  }
}
</style>