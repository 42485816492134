import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col sm:gap-4" }
const _hoisted_2 = { class: "grid flex-1 items-start gap-4 sm:py-0 md:gap-8 w-full max-w-[1700px] mx-auto" }
const _hoisted_3 = { class: "mx-auto grid w-full flex-1 auto-rows-max gap-4" }
const _hoisted_4 = ["onSubmit"]
const _hoisted_5 = { class: "grid gap-6 md:grid-cols-2" }
const _hoisted_6 = { class: "md:col-span-2" }

import { Button } from '@/components/ui/button'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { toast } from '@/components/ui/toast'
import * as z from 'zod'
import { vMaska } from "maska/vue"
import { toTypedSchema } from '@vee-validate/zod'

import { Loader2 } from 'lucide-vue-next'
import { useAxios } from '@/composables/useAxios'
import { ref } from 'vue'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import Toaster from '@/components/ui/toast/Toaster.vue'
import router from '@/router'

type FormSchemaType = z.infer<typeof formSchema>


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateCourseUserView',
  props: {
    courseId: {}
  },
  setup(__props: any) {

const formValues = ref({ phoneNumber: '', document: '' })
const props = __props
const isCreatingCourseUser = ref(false)
const axios = useAxios()
const requiredMessage = {
  required_error: "Obrigatório",
}
const formSchema = z.object({
  name: z.string(requiredMessage),
  email: z.string(requiredMessage).email("Email inválido"),
  password: z.string(requiredMessage).min(8, "Senha deve ter no mínimo 8 caracteres"),
  phoneNumber: z.string().optional(),
  document: z.string().optional(),
});
function createCourse(payload: FormSchemaType) {
  isCreatingCourseUser.value = true

  if (!payload.phoneNumber) delete payload.phoneNumber

  axios.post(`/courses/${props.courseId}/users`, payload)
    .then((res) => {
      toast({
        title: 'Aluno cadastrado com sucesso',
        class: 'bg-green-500 text-white'
      })
    })
    .catch(() => {
      toast({
        title: 'Esse email já foi cadastrado',
        description: 'Tente um outro email',
        variant: 'destructive'
      })
    })
    .finally(() => isCreatingCourseUser.value = false)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", _hoisted_2, [
      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "space-y-2" }, [
        _createElementVNode("h1", { class: "text-3xl font-bold tracking-tighter text-[#000] dark:text-white" }, "Adicionar novo aluno"),
        _createElementVNode("p", { class: "text-sm text-[#666] dark:text-[#888]" }, "Informe os dados do aluno")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(Form), {
          as: "",
          "keep-values": "",
          "validation-schema": _unref(toTypedSchema)(_unref(formSchema))
        }, {
          default: _withCtx(({ meta, values, validate }) => [
            _createElementVNode("form", {
              onSubmit: _withModifiers(() => {
            validate();
            if (meta.valid) {
              createCourse(values as FormSchemaType)
            }
          }, ["prevent"]),
              class: "grid gap-4 lg:gap-8 relative"
            }, [
              _createVNode(_unref(Card), { class: "relative" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(CardContent), { class: "pt-6" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_unref(FormField), { name: "name" }, {
                          default: _withCtx(({ componentField }) => [
                            _createVNode(_unref(FormItem), null, {
                              default: _withCtx(() => [
                                _createVNode(_unref(FormLabel), null, {
                                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                                    _createTextVNode("Nome")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_unref(FormControl), null, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(Input), _mergeProps({
                                      type: "text",
                                      placeholder: "Nome completo"
                                    }, componentField), null, 16)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_unref(FormMessage))
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 1
                        }),
                        _createVNode(_unref(FormField), { name: "email" }, {
                          default: _withCtx(({ componentField }) => [
                            _createVNode(_unref(FormItem), null, {
                              default: _withCtx(() => [
                                _createVNode(_unref(FormLabel), null, {
                                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                                    _createTextVNode("Email")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_unref(FormControl), null, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(Input), _mergeProps({
                                      type: "email",
                                      placeholder: "ex: exemplo@dominio.com"
                                    }, componentField), null, 16)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_unref(FormMessage))
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 1
                        }),
                        _createVNode(_unref(FormField), { name: "password" }, {
                          default: _withCtx(({ componentField }) => [
                            _createVNode(_unref(FormItem), null, {
                              default: _withCtx(() => [
                                _createVNode(_unref(FormLabel), null, {
                                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                                    _createTextVNode("Senha")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_unref(FormControl), null, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(Input), _mergeProps({
                                      type: "password",
                                      placeholder: "Mínimo 8 caracteres"
                                    }, componentField), null, 16)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_unref(FormMessage))
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 1
                        }),
                        _createVNode(_unref(FormField), { name: "phoneNumber" }, {
                          default: _withCtx(({ componentField }) => [
                            _createVNode(_unref(FormItem), null, {
                              default: _withCtx(() => [
                                _createVNode(_unref(FormLabel), null, {
                                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                                    _createTextVNode("Número de Telefone")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_unref(FormControl), null, {
                                  default: _withCtx(() => [
                                    _withDirectives(_createVNode(_unref(Input), _mergeProps({
                                      type: "tel",
                                      placeholder: "(XX) X XXXX-XXXX"
                                    }, componentField, {
                                      modelValue: formValues.value.phoneNumber,
                                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formValues.value.phoneNumber) = $event))
                                    }), null, 16, ["modelValue"]), [
                                      [_unref(vMaska), '(##) # ####-####']
                                    ])
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_unref(FormMessage))
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 1
                        }),
                        _createVNode(_unref(FormField), { name: "document" }, {
                          default: _withCtx(({ componentField }) => [
                            _createVNode(_unref(FormItem), null, {
                              default: _withCtx(() => [
                                _createVNode(_unref(FormLabel), null, {
                                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                                    _createTextVNode("CPF ou CNPJ")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_unref(FormControl), null, {
                                  default: _withCtx(() => [
                                    _withDirectives(_createVNode(_unref(Input), _mergeProps({
                                      type: "text",
                                      placeholder: "Informe CPF ou CNPJ"
                                    }, componentField, {
                                      modelValue: formValues.value.document,
                                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((formValues.value.document) = $event))
                                    }), null, 16, ["modelValue"]), [
                                      [_unref(vMaska), formValues.value.document.length <= 14 ? '###.###.###-###' : '##.###.###/####-##']
                                    ])
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_unref(FormMessage))
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 1
                        }),
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_unref(Button), {
                            disabled: 
                      (formValues.value.document && formValues.value.document.length !== 14 && formValues.value.document.length !== 18) ||
                      (!!formValues.value.phoneNumber && formValues.value.phoneNumber.length !== 16)
                    ,
                            type: "submit",
                            class: "w-full md:w-auto"
                          }, {
                            default: _withCtx(() => [
                              (isCreatingCourseUser.value)
                                ? (_openBlock(), _createBlock(_unref(Loader2), {
                                    key: 0,
                                    class: "w-4 h-4 mr-2 animate-spin"
                                  }))
                                : _createCommentVNode("", true),
                              _cache[7] || (_cache[7] = _createTextVNode(" Adicionar "))
                            ]),
                            _: 1
                          }, 8, ["disabled"])
                        ])
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ], 40, _hoisted_4)
          ]),
          _: 1
        }, 8, ["validation-schema"])
      ])
    ]),
    _createVNode(Toaster)
  ]))
}
}

})