import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { type HTMLAttributes, computed } from 'vue'
import {
  DialogClose,
  DialogContent,
  type DialogContentEmits,
  type DialogContentProps,
  DialogOverlay,
  DialogPortal,
  useForwardPropsEmits,
} from 'radix-vue'
import { X } from 'lucide-vue-next'
import { type SheetVariants, sheetVariants } from '.'
import { cn } from '@/lib/utils'

interface SheetContentProps extends DialogContentProps {
  class?: HTMLAttributes['class']
  side?: SheetVariants['side']
}


export default /*@__PURE__*/_defineComponent({
  ...{
  inheritAttrs: false,
},
  __name: 'SheetContent',
  props: {
    class: {},
    side: {},
    forceMount: { type: Boolean },
    trapFocus: { type: Boolean },
    disableOutsidePointerEvents: { type: Boolean },
    asChild: { type: Boolean },
    as: {}
  },
  emits: ["escapeKeyDown", "pointerDownOutside", "focusOutside", "interactOutside", "openAutoFocus", "closeAutoFocus"],
  setup(__props: any, { emit: __emit }) {



const props = __props

const emits = __emit

const delegatedProps = computed(() => {
  const { class: _, side, ...delegated } = props

  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(DialogPortal), null, {
    default: _withCtx(() => [
      _createVNode(_unref(DialogOverlay), { class: "fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" }),
      _createVNode(_unref(DialogContent), _mergeProps({
        class: _unref(cn)(_unref(sheetVariants)({ side: _ctx.side }), props.class)
      }, { ..._unref(forwarded), ..._ctx.$attrs }), {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default"),
          _createVNode(_unref(DialogClose), { class: "absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-secondary" }, {
            default: _withCtx(() => [
              _createVNode(_unref(X), { class: "w-4 h-4 text-muted-foreground" })
            ]),
            _: 1
          })
        ]),
        _: 3
      }, 16, ["class"])
    ]),
    _: 3
  }))
}
}

})