<template>
  <div class="w-full lg:grid lg:grid-cols-1 min-h-[100vh] pt-14 sm:pt-0">
    <div class="flex items-center justify-center py-12">
      <form @submit.prevent="sendResetPasswordLink(
        email,
        () => toast({
          title: 'Email enviado com sucesso',
          class: 'bg-green-500 text-white'
        }),
        () => toast({
          title: 'Email inválido!',
          description: 'Este email não está cadastrado na plataforma',
          variant: 'destructive'
        })
      )" class="mx-auto grid w-full p-4 sm:p-0 sm:w-[390px] gap-14">
        <div class="grid gap-2 text-center">
          <!-- <LockKeyhole class="h-28 w-28 mx-auto pb-4" /> -->
           <div class="flex justify-center mb-4">
             <img src="@/assets/hoopay-logo.png" height="42" width="42" class="" />
           </div>
          <div class="flex items-center gap-3 mx-auto">
            <h1 class="text-3xl font-bold">
              Recuperar a senha
            </h1>
          </div>
          <p class="text-muted-foreground">
            Digite seu email para enviarmos o link de redefinição de senha
          </p>
        </div>
        <div class="grid gap-4">
          <div class="grid gap-2">
            <Label for="email">Email</Label>
            <Input
              id="email"
              type="email"
              placeholder="ex: email@exemplo.com"
              required
              v-model="email"
            />
          </div>
          <Button type="submit" class="w-full mt-6 py-6" :disabled="isAuthLoading || !email"> 
            <Loader2 v-if="isAuthLoading" class="w-4 h-4 mr-2 animate-spin" />
            Enviar
          </Button>
        </div>
      </form>
    </div>
  </div>
  <Toaster />
</template>

<script setup lang="ts">
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { useToast } from '@/components/ui/toast/use-toast'
import { Toaster } from '@/components/ui/toast'
import { ref } from 'vue'
import { Loader2, LockKeyhole } from 'lucide-vue-next'
import { useAuth } from '@/composables/useAuth'

const { toast } = useToast()
const email = ref('')
const { sendResetPasswordLink, isAuthLoading } = useAuth()
</script>