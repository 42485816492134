<template>
  <router-view/>
</template>

<script setup lang="ts">
import { useColorMode } from '@vueuse/core'


const mode = useColorMode()
mode.value = 'dark'
</script>

<style lang="scss">
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>
