import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import type { HTMLAttributes } from 'vue'
import type { LabelProps } from 'radix-vue'
import { useFormField } from './useFormField'
import { cn } from '@/lib/utils'
import { Label } from '@/components/ui/label'


export default /*@__PURE__*/_defineComponent({
  __name: 'FormLabel',
  props: {
    for: {},
    asChild: { type: Boolean },
    as: {},
    class: {}
  },
  setup(__props: any) {

const props = __props

const { error, formItemId } = useFormField()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Label), {
    class: _normalizeClass(_unref(cn)(
      _unref(error) && 'text-destructive',
      props.class,
    )),
    for: _unref(formItemId)
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["class", "for"]))
}
}

})