import {
  TrendingUpIcon,
  AwardIcon,
  BarChart4Icon,
  StarIcon,
  ZapIcon,
  PencilIcon,
  LoaderIcon,
} from "lucide-vue-next";

export enum AchievementCriteriaTypeEnum {
  REVENUE = 'revenue',
  REFERRAL = 'referral',
  RANK = 'rank',
  TRADES = 'trades',
  RATING = 'rating',
  WINNING_STREAK = 'winning_streak',
  MARKET_ANALYSIS = 'market_analysis',
}

export const achievementCriteriaTypeIcons = {
  [AchievementCriteriaTypeEnum.REVENUE]: TrendingUpIcon,
  [AchievementCriteriaTypeEnum.REFERRAL]: AwardIcon,
  [AchievementCriteriaTypeEnum.RANK]: BarChart4Icon,
  [AchievementCriteriaTypeEnum.TRADES]: LoaderIcon,
  [AchievementCriteriaTypeEnum.RATING]: StarIcon,
  [AchievementCriteriaTypeEnum.WINNING_STREAK]: ZapIcon,
  [AchievementCriteriaTypeEnum.MARKET_ANALYSIS]: PencilIcon,
};

/**examples
 * 
 * import {
  TrendingUpIcon,
  AwardIcon,
  BarChart4Icon,
  StarIcon,
  ZapIcon,
  Pencil,
  ImageUp,
  Loader2,
} from "lucide-vue-next";
 */