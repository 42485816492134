<template>
  <div class="flex flex-col sm:gap-4">
    <main class="grid flex-1 items-start gap-4 sm:py-0 md:gap-8 w-full max-w-[1700px] mx-auto">
      <div class="mx-auto grid w-full flex-1 auto-rows-max gap-4">
        <!-- <div class="flex items-center gap-4">
          <Button variant="outline" size="icon" class="h-7 w-7">
            <ChevronLeft class="h-4 w-4" />
            <span class="sr-only">Back</span>
          </Button>
          <h1 class="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
            Pro Controller
          </h1>
          <Badge variant="outline" class="ml-auto sm:ml-0">
            In stock
          </Badge>
          <div class="hidden items-center gap-2 md:ml-auto md:flex">
            <Button variant="outline" size="sm">
              Discard
            </Button>
            <Button size="sm">
              Save Product
            </Button>
          </div>
        </div> -->
        <div class="absolute left-0 right-0 w-full h-auto">
          <input class="hidden" type="file" @change="onBackgroundImageChange" id="backgroundImage" accept="image/png, image/jpg, image/jpeg, image/gif, image/avif">
          <img v-if="mediaPreviews.backgroundImage" :src="mediaPreviews.backgroundImage" class="object-cover w-full max-w-full h-80 absolute" />
          <div v-else class="object-cover w-full max-w-full h-80 absolute bg-zinc-500"></div>
          <label for="backgroundImage" class="absolute top-8 -translate-y-1/2 -translate-x-1/2 left-1/2 bg-muted rounded-full p-4 cursor-pointer">
            <ImageUp v-if="!mediaPreviews.backgroundImage" class="w-4 h-4" />
            <Edit2 v-else class="w-4 h-4" />
          </label>
        </div>
      <Form
        v-slot="{ meta, values, validate }"
        as="" keep-values :validation-schema="toTypedSchema(formSchema)"
      >
        <form @submit.prevent="() => {
          validate();
          if (meta.valid) {
            createCourse(values as FormSchemaType)
          }
        }" class="grid gap-4 md:grid-cols-[1fr_250px] lg:grid-cols-3 lg:gap-8 relative mt-[14rem]">
          <div class="grid auto-rows-max items-start gap-4 lg:col-span-2 lg:gap-8">
            <Card class="pt-[240px] relative">
              <!-- <CardHeader>
                <CardTitle>Adicionar novo curso</CardTitle>
                <CardDescription>Informe os dados do curso</CardDescription>
              </CardHeader> -->
              <CardContent>
                <div class="grid gap-6">
                  <div class="absolute left-1/2 -translate-x-1/2 -top-[130px]">
                    <FormField v-slot="{ componentField }" name="image">
                      <ImageInput v-model="form.image" v-bind="componentField" :width="'250px'" :height="'333px'" />
                      <FormMessage />
                    </FormField>
                  </div>
                  <div class="grid gap-3">
                    <FormField v-slot="{ componentField }" name="title" class="col-span-1">
                      <FormItem>
                        <FormLabel>Título</FormLabel>
                        <FormControl>
                          <Input
                            type="text"
                            class="w-full"
                            placeholder="ex: Design criativo, de ideias a realidade!"
                            v-bind="componentField"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    </FormField>
                  </div>
                  <div class="grid gap-3">
                    <FormField v-slot="{ componentField }" name="introduction" class="col-span-1">
                      <FormItem>
                        <FormLabel>Texto de Introdução</FormLabel>
                        <FormControl>
                          <Textarea
                            placeholder="ex: Neste curso, você irá explorar o fascinante mundo do design..."
                            class="min-h-32"
                            v-bind="componentField"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    </FormField>
                  </div>
                  <div class="grid gap-3">
                    <FormField v-slot="{ componentField }" name="description" class="col-span-1">
                      <FormItem>
                        <FormLabel>Descrição</FormLabel>
                        <FormControl>
                          <Textarea
                            placeholder="ex: Aprenda métodos práticos, fundamentos, a receber feedback..."
                            class="min-h-32"
                            v-bind="componentField"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    </FormField>
                  </div>
                  <div class="grid gap-3">
                    <FormField v-slot="{ componentField }" name="productId">
                      <FormLabel for="productId">Produto</FormLabel>
                      <Select v-bind="componentField">
                        <SelectTrigger id="productId" aria-label="Selecionar o produto">
                          <SelectValue placeholder="Selecionar" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem v-for="product in products" :value="String(product.id)">{{ product.title }}</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormField>
                  </div>
                  <!-- <div class="grid gap-3">
                    <FormField v-slot="{ componentField }" name="duration" class="col-span-1">
                      <FormItem>
                        <FormLabel>Duração</FormLabel>
                        <FormControl>
                          <Input
                            type="number"
                            class="w-full"
                            placeholder="ex: 10"
                            v-bind="componentField"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    </FormField>
                  </div>
                  <div class="grid gap-3">
                    <FormField v-slot="{ componentField }" name="limitClientsFree" class="col-span-1">
                      <FormItem>
                        <FormLabel>Clientes gratuitos</FormLabel>
                        <FormControl>
                          <Input
                            type="number"
                            class="w-full"
                            placeholder="ex: 10"
                            v-bind="componentField"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    </FormField>
                  </div> -->
                  <div class="flex justify-between">
                    <div class="flex items-center space-x-2">
                      <FormField v-slot="{ componentField }" name="upcoming">
                        <Switch v-bind="componentField" />
                        <Label for="upcoming">Em breve</Label>
                      </FormField>
                    </div>
                    <div class="flex items-center space-x-2">
                      <FormField v-slot="{ componentField }" name="certification">
                        <Switch v-bind="componentField" />
                        <Label for="certification">Certificado</Label>
                      </FormField>
                    </div>
                    <div class="flex items-center space-x-2">
                      <FormField v-slot="{ componentField }" name="featuredInStore">
                        <Switch v-bind="componentField" />
                        <Label for="featuredInStore">Loja</Label>
                      </FormField>
                    </div>
                  </div>
                  <Button type="submit">
                    <Loader2 v-if="isCreatingCourse" class="w-4 h-4 mr-2 animate-spin" />
                    Adicionar
                  </Button>
                </div>
              </CardContent>
            </Card>
          </div>
          <div class="grid auto-rows-max items-start gap-4 lg:gap-8">
            <Card>
              <CardHeader>
                <CardTitle>Configurações</CardTitle>
              </CardHeader>
              <CardContent>
                <div class="grid gap-6 min-w-[300px]">
                  <div class="grid gap-3">
                    <FormField v-slot="{ componentField }" name="status">
                      <FormLabel for="status">Status</FormLabel>
                      <Select v-bind="componentField">
                        <SelectTrigger id="status" aria-label="Selecionar status">
                          <SelectValue placeholder="Selecionar" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem v-for="status in CourseStatusEnum" :value="status">{{ courseStatusLabels[status] }}</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormField>
                  </div>
                  <div class="grid gap-3">
                    <FormField v-slot="{ componentField }" name="commentsType">
                      <FormLabel for="commentsType">Comentários</FormLabel>
                      <Select v-bind="componentField">
                        <SelectTrigger id="commentsType" aria-label="Selecionar tipo de comentários">
                          <SelectValue placeholder="Selecionar" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem v-for="type in CourseCommentsTypeEnum" :value="type">{{ commentsTypeLabels[type] }}</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormField>
                  </div>
                  <div class="grid gap-3">
                    <FormField v-slot="{ componentField }" name="communityType">
                      <FormLabel for="communityType">Tipo de comunidade</FormLabel>
                      <Select v-bind="componentField">
                        <SelectTrigger id="communityType" aria-label="Selecionar tipo de comunidade">
                          <SelectValue placeholder="Selecionar" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="Aberto">Aberto</SelectItem>
                          <SelectItem value="Fechado">Fechado</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormField>
                  </div>
                </div>
              </CardContent>
            </Card>
            <Card class="overflow-hidden">
              <CardHeader>
                <CardTitle>Mídias</CardTitle>
                <CardDescription>Vídeo do curso</CardDescription>
              </CardHeader>
              <CardContent>
                <div class="flex flex-col gap-6">
                  <div class="grid gap-3">
                    <FormField v-slot="{ componentField }" name="video">
                      <FormLabel>Vídeo de Introdução</FormLabel>
                      <Input
                        type="text"
                        class="w-full"
                        placeholder="ex: https://youtube.com..."
                        v-bind="componentField"
                      />
                      <FormMessage />
                    </FormField>
                  </div>
                  <div class="grid gap-3">
                    <FormField v-slot="{ componentField }" name="videoOriginType">
                      <FormLabel for="contentType">Origem do vídeo</FormLabel>
                      <Select v-bind="componentField">
                        <SelectTrigger id="contentType" aria-label="Selecionar">
                          <SelectValue placeholder="Selecionar" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem v-for="type in CourseVideoTypeEnum" :value="type">{{ courseVideoTypeLabels[type] }}</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormField>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>          
        </form>
      </Form>
      </div>
    </main>
    <Toaster />
  </div>
</template>

<script lang="ts" setup>
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { Label } from '@/components/ui/label'
import { toast } from '@/components/ui/toast'
import * as z from 'zod'
import { toTypedSchema } from '@vee-validate/zod'

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Switch } from '@/components/ui/switch'
import { Edit2, ImageUp, Loader2 } from 'lucide-vue-next'
import { useAxios } from '@/composables/useAxios'
import { onMounted, ref } from 'vue'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import Toaster from '@/components/ui/toast/Toaster.vue'
import router from '@/router'
import { Product } from '@/interfaces/Product'
import ImageInput from '@/components/ui/input/ImageInput.vue'
import { CourseVideoTypeEnum, courseVideoTypeLabels } from '@/enums/course/CourseVideoTypeEnum'
import { CourseStatusEnum, courseStatusLabels } from '@/enums/course/CourseStatusEnum'
import { CourseCommentsTypeEnum, commentsTypeLabels } from '@/enums/course/CourseCommentsTypeEnum'

const products = ref<Product[]>()
const isCreatingCourse = ref(false)
const axios = useAxios()
const form = ref<Record<string, File | undefined>>({
  image: undefined,
  backgroundImage: undefined
})
const mediaPreviews = ref({
  image: '',
  backgroundImage: ''
})
const requiredMessage = {
  required_error: "Obrigatório",
}
const formSchema = z.object({
  title: z.string(requiredMessage),
  image: z.any(requiredMessage).refine(file => !!form.value.image, {
    message: "Obrigatório."
  }),
  backgroundImage: z.any(requiredMessage),
  video: z.string(requiredMessage).url('Url deve ser válida'),
  videoOriginType: z.nativeEnum(CourseVideoTypeEnum, requiredMessage),
  introduction: z.string(requiredMessage),
  description: z.string(requiredMessage),
  upcoming: z.boolean().default(false).default(false),
  commentsType: z.string(requiredMessage),
  communityType: z.string(requiredMessage),
  // limitClientsFree: z.number(requiredMessage),
  status: z.nativeEnum(CourseStatusEnum).default(CourseStatusEnum.ACTIVE).optional(),
  certification: z.boolean().default(true).default(false),
  // duration: z.number().optional(),
  featuredInStore: z.boolean().default(false),
  productId: z.string(requiredMessage)
});
type FormSchemaType = z.infer<typeof formSchema>

function createCourse(payload: FormSchemaType) {
  isCreatingCourse.value = true
  const formData = new FormData();

  formData.append('title', payload.title);
  formData.append('image', form.value.image!);
  if (form.value.backgroundImage) {
    formData.append('backgroundImage', form.value.backgroundImage);
  }
  formData.append('video', payload.video);
  formData.append('videoOriginType', payload.videoOriginType);
  formData.append('introduction', payload.introduction);
  formData.append('description', payload.description);
  formData.append('upcoming', payload.upcoming ? 'true' : 'false');
  formData.append('commentsType', payload.commentsType);
  formData.append('communityType', payload.communityType);
  formData.append('status', String(payload.status));
  formData.append('certification', payload.certification ? 'true' : 'false');
  formData.append('featuredInStore', payload.featuredInStore ? 'true' : 'false');
  formData.append('productId', payload.productId);

  axios.post('/courses', formData)
    .then((res) => {
      router.push({ name: 'courses.edit', params: { courseId: res.data.id } })

      toast({
        title: 'Curso cadastrado com sucesso',
        class: 'bg-green-500 text-white'
      })
    })
    .finally(() => isCreatingCourse.value = false)
}

function getProducts() {
  axios.get('/products')
    .then((res) => {
      products.value = res.data
    })
}

function onBackgroundImageChange(e: Event) {
  const file = (e.target as HTMLInputElement).files![0]

  form.value.backgroundImage = file

  mediaPreviews.value.backgroundImage = URL.createObjectURL(file)
}

onMounted(() => {
  getProducts()
})
</script>