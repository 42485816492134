import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { Slot } from 'radix-vue'
import { useFormField } from './useFormField'


export default /*@__PURE__*/_defineComponent({
  __name: 'FormControl',
  setup(__props) {

const { error, formItemId, formDescriptionId, formMessageId } = useFormField()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Slot), {
    id: _unref(formItemId),
    "aria-describedby": !_unref(error) ? `${_unref(formDescriptionId)}` : `${_unref(formDescriptionId)} ${_unref(formMessageId)}`,
    "aria-invalid": !!_unref(error)
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["id", "aria-describedby", "aria-invalid"]))
}
}

})