<template>
  <div class="sm:container w-full mx-auto sm:p-4 space-y-6 max-w-full">
    <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-6" v-if="user">
      <div class="md:col-span-2">
        <ProfileBox @update="getProfile" :user="user" />
      </div>
      <div class="space-y-9">
        <CommentsSection :profile="user" />
      </div>
    </div>
    <SpinnerLoader v-else />
  </div>
  <Toaster />
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { Toaster } from '@/components/ui/toast'
import ProfileBox from './components/ProfileBox.vue'
import { useAxios } from '@/composables/useAxios'
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue'
import CommentsSection from './components/commentsSection/CommentsSection.vue'
import { Profile } from '@/interfaces/profiles/Profile'

const props = defineProps<{ profileId: number }>()
const axios = useAxios()
const user = ref<Profile>()

function getProfile() {
  axios.get(`/profile/${props.profileId}`)
    .then((res) => {
      user.value = { ...res.data, phoneNumber: res.data.phoneNumber?.slice(2,) }
    })
}

onMounted(async () => {
  getProfile()
  // const response = await fetch('/api/user/profile.js')
  // user.value = await response.json()
  // user.value = {
  //   id: 1,
  //   name: 'John Doe',
  //   avatar: 'https://example.com/avatar.jpg',
  //   initials: 'JD',
  //   description: 'Passionate trader and investor',
  //   achievements: ['Top Trader', '1000 Trades', '5 Star Rating'],
  //   isTrader: true,
  //   biography:
  //     "I've been trading for over 10 years, specializing in forex and cryptocurrencies.",
  //   performance: [
  //     { date: '2023-01', value: 1000 },
  //     { date: '2023-02', value: 1200 },
  //     { date: '2023-03', value: 900 },
  //     { date: '2023-04', value: 1500 },
  //     { date: '2023-05', value: 1800 },
  //     { date: '2023-06', value: 1600 },
  //   ],
  // };
})
</script>
