import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-background flex flex-col" }
const _hoisted_2 = { class: "flex-grow container mx-auto px-4 py-8" }
const _hoisted_3 = ["innerHTML"]

import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card'
import { ScrollArea } from '@/components/ui/scroll-area'
import { useTenant } from '@/composables/useTenant';


export default /*@__PURE__*/_defineComponent({
  __name: 'TermsOfUseView',
  setup(__props) {

const { tenant } = useTenant()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("header", { class: "" }, [
      _createElementVNode("div", { class: "container mx-auto px-4 py-6" }, [
        _createElementVNode("h1", { class: "text-2xl font-bold text-foreground text-center" }, "Termos de Uso")
      ])
    ], -1)),
    _createElementVNode("main", _hoisted_2, [
      _createVNode(_unref(Card), { class: "max-w-3xl mx-auto" }, {
        default: _withCtx(() => [
          _createVNode(_unref(CardHeader), null, {
            default: _withCtx(() => [
              _createVNode(_unref(CardTitle), { class: "text-xl font-semibold" }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode("Por favor, leia atentamente nossos termos")
                ])),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_unref(CardContent), null, {
            default: _withCtx(() => [
              _createVNode(_unref(ScrollArea), { class: "h-[60vh] pr-4" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "space-y-4 text-muted-foreground",
                    innerHTML: _unref(tenant).termsOfUse
                  }, null, 8, _hoisted_3)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ]))
}
}

})