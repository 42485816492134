export enum ModuleStatusEnum {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  // DELETED = 'deleted',
}

export const moduleStatusLabels = {
  [ModuleStatusEnum.ACTIVE]: 'Publicado',
  [ModuleStatusEnum.INACTIVE]: 'Não publicado',
  // [ModuleStatusEnum.DELETED]: 'Deletado',
}