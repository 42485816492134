export enum CourseCommentsTypeEnum {
  OPEN = 'open',
  MODERATED = 'moderated',
  INACTIVE = 'inactive'
}

export const commentsTypeLabels = {
  [CourseCommentsTypeEnum.OPEN]: 'Livre',
  [CourseCommentsTypeEnum.MODERATED]: 'Moderado',
  [CourseCommentsTypeEnum.INACTIVE]: 'Desativado',
};