import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-background p-4 rounded-lg" }
const _hoisted_2 = { class: "flex gap-2 items-center" }

import { Button } from '@/components/ui/button';
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { toast } from '@/components/ui/toast';
import { useAxios } from '@/composables/useAxios';
import { ProfileMediaTypeEnum } from '@/enums/profiles/ProfileMediaTypeEnum';
import { ProfileComment } from '@/interfaces/profiles/ProfileComment';
import { AlertTriangle, Loader2, MoreHorizontalIcon, Trash2 } from 'lucide-vue-next';
import { computed, ref } from 'vue';
import ProfileComponent from '../../ProfileComponent.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileComment',
  props: {
    comment: {}
  },
  emits: ['delete'],
  setup(__props: any, { emit: __emit }) {

const props = __props
const authorAvatar = computed(() =>
  props.comment.author.media?.find((m) => m.type === ProfileMediaTypeEnum.AVATAR)
);
const isDeletingComment = ref(false)
const axios = useAxios()
const isDeleteConfirmationDialogOpen = ref(false)
const emit = __emit

function deleteComment() {
  isDeletingComment.value = true

  axios.delete(`/profile/${props.comment.authorId}/comments/${props.comment.id}`)
    .then(() => {
      toast({
        title: 'Comentário excluido com sucesso',
        class: 'bg-green-500 text-white'
      })
      isDeleteConfirmationDialogOpen.value = false
      emit('delete')
    })
    .finally(() => isDeletingComment.value = false)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(ProfileComponent, {
        topic: { ..._ctx.comment, author: { ..._ctx.comment.author, avatar: authorAvatar.value } },
        onDelete: _cache[0] || (_cache[0] = ($event: any) => (isDeleteConfirmationDialogOpen.value = true))
      }, null, 8, ["topic"]),
      _createElementVNode("p", null, _toDisplayString(_ctx.comment.content), 1)
    ]),
    _createVNode(_unref(Dialog), {
      open: isDeleteConfirmationDialogOpen.value,
      "onUpdate:open": _cache[2] || (_cache[2] = (open) => isDeleteConfirmationDialogOpen.value = open)
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(DialogContent), { class: "sm:max-w-md" }, {
          default: _withCtx(() => [
            _createVNode(_unref(DialogHeader), null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_unref(AlertTriangle), { class: "h-6 w-6" }),
                  _createVNode(_unref(DialogTitle), { class: "text-2xl" }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode("Excluir Comentário")
                    ])),
                    _: 1
                  })
                ]),
                _createVNode(_unref(DialogDescription), { class: "py-2" }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode(" Você está prestes a excluir esta comentário. Essa ação é irreversível e todos os dados relacionados a este comentário serão permanentemente removidos. ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "flex items-center space-x-2" }, null, -1)),
            _createVNode(_unref(DialogFooter), { class: "sm:justify-start" }, {
              default: _withCtx(() => [
                _createVNode(_unref(DialogClose), { "as-child": "" }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(Button), {
                      type: "button",
                      variant: "secondary"
                    }, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode(" Cancelar ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_unref(Button), {
                  type: "button",
                  disabled: isDeletingComment.value,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (deleteComment()))
                }, {
                  default: _withCtx(() => [
                    (isDeletingComment.value)
                      ? (_openBlock(), _createBlock(_unref(Loader2), {
                          key: 0,
                          class: "w-4 h-4 mr-2 animate-spin"
                        }))
                      : _createCommentVNode("", true),
                    _cache[6] || (_cache[6] = _createTextVNode(" Excluir Comentário "))
                  ]),
                  _: 1
                }, 8, ["disabled"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["open"])
  ], 64))
}
}

})