import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

import type { HTMLAttributes } from 'vue'
import { useFormField } from './useFormField'
import { cn } from '@/lib/utils'


export default /*@__PURE__*/_defineComponent({
  __name: 'FormDescription',
  props: {
    class: {}
  },
  setup(__props: any) {

const props = __props

const { formDescriptionId } = useFormField()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("p", {
    id: _unref(formDescriptionId),
    class: _normalizeClass(_unref(cn)('text-sm text-muted-foreground', props.class))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}
}

})