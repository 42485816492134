import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import type { HTMLAttributes } from 'vue'
import { useVModel } from '@vueuse/core'
import { cn } from '@/lib/utils'


export default /*@__PURE__*/_defineComponent({
  __name: 'Textarea',
  props: {
    class: {},
    defaultValue: {},
    modelValue: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emits = __emit

const modelValue = useVModel(props, 'modelValue', emits, {
  passive: true,
  defaultValue: props.defaultValue,
})

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("textarea", {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(modelValue) ? (modelValue).value = $event : null)),
    class: _normalizeClass(_unref(cn)('flex min-h-20 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50', props.class))
  }, null, 2)), [
    [_vModelText, _unref(modelValue)]
  ])
}
}

})