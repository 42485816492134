export function useDate() {
  function timeAgo(date: string) {
    const now = new Date();
    const seconds = Math.floor((now.getTime() - new Date(date).getTime()) / 1000);
    let interval = Math.floor(seconds / 31536000);

    if (interval >= 1) return `há ${interval} ano${interval > 1 ? 's' : ''}`;
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) return `há ${interval} mês${interval > 1 ? 'es' : ''}`;
    interval = Math.floor(seconds / 86400);
    if (interval >= 1) return `há ${interval} dia${interval > 1 ? 's' : ''}`;
    interval = Math.floor(seconds / 3600);
    if (interval >= 1) return `há ${interval} hora${interval > 1 ? 's' : ''}`;
    interval = Math.floor(seconds / 60);
    if (interval >= 1) return `há ${interval} minuto${interval > 1 ? 's' : ''}`;
    return "agora mesmo";
  }

  return {
    timeAgo
  }
}