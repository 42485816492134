import { io } from 'socket.io-client';

// Hook customizado para usar WebSocket (Socket.IO)
export const useWebSocket = () => {
    // URL do servidor WebSocket (pode ser configurada em .env)
    const websocketUrl = process.env.VUE_APP_API_BASE_URL;

    // Conectar ao servidor quando o componente for montado
    // onMounted(() => {
    console.log('Conectando ao WebSocket em:', websocketUrl);

    const socket = io(websocketUrl, {
        auth: {
            token: localStorage.getItem('authToken')
        }
    });

    // if (socket.value) {
    //     // Quando a conexão for bem-sucedida
    //     socket.value.on('connect', () => {
    //         console.log('Conectado ao servidor WebSocket!');
    //     });

    //     // Quando o cliente for desconectado
    //     socket.value.on('disconnect', () => {
    //         console.log('Desconectado do servidor WebSocket');
    //     });
    // }
    // });

    // Desconectar ao desmontar o componente
    // onUnmounted(() => {
    //     if (socket.value) {
    //         socket.value.disconnect();
    //         console.log('Desconectado do WebSocket');
    //     }
    // });

    return socket;
};
