import { ref } from "vue"
import { useAxios } from "./useAxios"

export function useChat() {
  const axios = useAxios()
  const isSendingMessage = ref(false)

  const sendMessage = async (message: string, userId: number, onSuccess = () => {}) => {
    isSendingMessage.value = true

    const response = await axios.post(`/chat/messages/${userId}`, { message })

    onSuccess()

    isSendingMessage.value = false

    return response.data
  }

  return {
    sendMessage,
    isSendingMessage
  }
}