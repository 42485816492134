<template>
  <Card class="w-full">
    <CardHeader>
      <CardTitle>Comentários</CardTitle>
    </CardHeader>
    <CardContent>
      <div class="space-y-4" v-if="comments">
        <ProfileCommentComponent v-for="comment in comments.data" :key="comment.id" :comment="comment" @delete="getComments" />
      </div>
      <SpinnerLoader v-else />
      <PaginationApi v-if="comments" :data="comments?.pagination" :paginate="getComments" />
      <form @submit.prevent="createComment" class="mt-6">
        <Textarea
          v-model="payload.content"
          placeholder="Escreva um comentário..."
          :rows="3"
          class="mb-2"
        />
        <Button :disabled="!payload.content || isCreatingComment" type="submit">
          <Loader2 v-if="isCreatingComment" class="w-4 h-4 mr-2 animate-spin" />
          Adicionar
        </Button>
      </form>
    </CardContent>
  </Card>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card'
import { Textarea } from '@/components/ui/textarea'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/toast'
import { useAxios } from '@/composables/useAxios'
import { Profile } from '@/interfaces/profiles/Profile'
import { Pagination } from '@/interfaces/Pagination'
import { ProfileComment } from '@/interfaces/profiles/ProfileComment'
import ProfileCommentComponent from './components/ProfileComment.vue'
import PaginationApi from '@/components/ui/pagination/PaginationApi.vue'
import SpinnerLoader from '@/components/ui/loaders/SpinnerLoader.vue'
import { Loader2 } from 'lucide-vue-next'

const axios = useAxios()
const props = defineProps<{ profile: Profile }>()
const comments = ref<Pagination<ProfileComment>>()
const payload = ref({ content: '' })
const { toast } = useToast()
const isCreatingComment = ref(false)

function getComments(page = 1) {
  axios.get(`/profile/${props.profile.id}/comments`, { params: { page } })
    .then((res) => {
      comments.value = res.data
    })
}

const createComment = () => {
  isCreatingComment.value = true

  axios.post(`/profile/${props.profile.id}/comments`, payload.value)
    .then(() => {
      toast({
        title: 'Comentário adicionado com sucesso',
        class: 'bg-green-500 text-white'
      })
      getComments()
    })
    .finally(() => isCreatingComment.value = false)
}

onMounted(() => {
  getComments()
})
</script>