<template>
  <div class="min-h-screen flex items-center justify-center p-4">
    <div class="w-full max-w-md space-y-8">
      <!-- Logo and Header -->
      <div class="flex flex-col items-center justify-center">
        <img v-if="tenantLogo" :src="tenantLogo.bucketLocation" height="38" width="38" class="" />
        <h1 class="mt-4 text-2xl font-semibold text-gray-200">Área de Membros</h1>
        <p class="mt-2 text-sm text-gray-400">Preencha os dados abaixo para se cadastrar</p>
      </div>

      <!-- Registration Form -->
      <form 
        @submit.prevent="handleSubmit" 
        class="mt-8 space-y-6 bg-card p-8 rounded-lg backdrop-blur-sm"
      >
        <div class="space-y-4">
          <!-- Nome Completo -->
          <div class="space-y-2">
            <Label for="fullName">Nome Completo</Label>
            <Input id="fullName" v-model="form.name" placeholder="Seu nome completo" />
          </div>

          <!-- Email -->
          <div class="space-y-2">
            <Label for="email">Email</Label>
            <Input id="email" v-model="form.email" type="email" placeholder="seu@email.com" />
          </div>

          <!-- CNPJ -->
          <div class="space-y-2">
            <Label for="cnpj">CPF/CNPJ</Label>
            <Input id="cnpj" v-model="form.document" v-maska="(form.document?.length ?? 0) <= 14 ? '###.###.###-###' : '##.###.###/####-##'" placeholder="00.000.000/0000-00" />
          </div>

          <!-- Razão Social -->
          <div v-if="(form.document?.length ?? 0) > 14" class="space-y-2">
            <Label for="companyName">Razão Social</Label>
            <Input id="companyName" v-model="form.organizationName" placeholder="Nome da empresa" />
          </div>

          <!-- Senha -->
          <div class="space-y-2">
            <Label for="password">Senha</Label>
            <Input id="password" v-model="form.password" type="password" placeholder="Deve conter pelo menos 8 caracteres" />
          </div>

          <!-- WhatsApp -->
          <div class="space-y-2">
            <Label for="whatsapp">WhatsApp DDD + número</Label>
            <Input id="whatsapp" v-model="form.phoneNumber" placeholder="+55 (00) 00000-0000" v-maska="'(##) # ####-####'" />
          </div>

          <!-- Terms Checkbox -->
          <div class="flex items-center space-x-2">
            <Checkbox id="terms" v-model:checked="acceptTerms" />
            <Label for="terms" class="text-sm text-gray-300">
              Eu aceito os <RouterLink :to="{ name: 'terms-of-use' }" class="text-primary hover:underline">Termos e Condições</RouterLink>
            </Label>
          </div>
        </div>

        <!-- Submit Button -->
        <Button type="submit" class="w-full" :disabled="isDisabled">
          <Loader2 v-if="isAuthLoading" class="w-4 h-4 mr-2 animate-spin" />
          Cadastrar
        </Button>

        <!-- Divider -->
        <div class="relative">
          <div class="absolute inset-0 flex items-center">
            <div class="w-full border-t border-gray-700"></div>
          </div>
          <div class="relative flex justify-center text-sm">
            <span class="px-2 bg-zinc-700 text-gray-400 rounded-md">ou</span>
          </div>
        </div>

        <!-- Login Link -->
        <Button @click="$router.push('login')" variant="outline" class="w-full">
          Entrar
        </Button>
      </form>
    </div>
    <Toaster />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Checkbox } from '@/components/ui/checkbox'
import { Button } from '@/components/ui/button'
import { RegisterPayload, useAuth } from '@/composables/useAuth'
import { useToast } from '@/components/ui/toast'
import { vMaska } from "maska/vue"
import router from '@/router'
import { useValidation } from '@/composables/useValidation'
import { Loader2 } from 'lucide-vue-next'
import { useTenant } from '@/composables/useTenant'
import Toaster from '@/components/ui/toast/Toaster.vue'

const { toast } = useToast()
const { register, isAuthLoading } = useAuth()
const { isCPFValid, isCNPJValid } = useValidation()
const { tenantLogo, initializeTenant } = useTenant()
const form = ref<Partial<RegisterPayload>>({})
const acceptTerms = ref(false)
const isDisabled = computed(() => 
  !form.value.name || 
  !form.value.email ||
  (!form.value.organizationName && (form.value.document?.length ?? 0) > 14) ||
  !form.value.password ||
  form.value.password.length < 8 ||
  !form.value.phoneNumber || 
  form.value.phoneNumber?.length < 16 ||
  !acceptTerms.value
)

function handleSubmit() {
  if (form.value.document!.length <= 14) {
    if (!isCPFValid(form.value.document!)) {
      toast({
        title: 'Cadastro falhou',
        description: 'Documento CPF inválido',
        variant: 'destructive',
        duration: 2000
      })

      return null
    } 
  } else {
    if (!isCNPJValid(form.value.document!)) {
      toast({
        title: 'Cadastro falhou',
        description: 'Documento CNPJ inválido',
        variant: 'destructive',
        duration: 2000
      })

      return null
    } 
  }
  register(
    { 
      ...form.value,
      organizationName: form.value.organizationName ? form.value.organizationName : form.value.name
    } as RegisterPayload,
    () => {
      toast({
        title: 'Conta cadastrada com sucesso',
        class: 'bg-green-500 text-white',
        duration: 1200
      })
      router.push({ name: 'login' }) 
    },
    () => toast({
      title: 'Cadastro falhou',
      description: 'Dados inválidos!',
      variant: 'destructive'
    })
  )
}

onMounted(() => {
  initializeTenant()
})
</script>