import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "flex items-start justify-between p-4 rounded-xl",
  style: {"background":"-webkit-radial-gradient(\n        center,\n        rgb(27 27 27),\n        rgb(18, 18, 18)\n      ) !important"}
}
const _hoisted_2 = { class: "flex items-start gap-2" }
const _hoisted_3 = { class: "text-sm text-zinc-400 m-0" }
const _hoisted_4 = { class: "text-xs pt-1" }
const _hoisted_5 = {
  key: 0,
  class: "grid"
}
const _hoisted_6 = { class: "text-zinc-400 text-xs" }
const _hoisted_7 = { class: "flex gap-1 pt-1" }

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { ProfileMediaTypeEnum } from '@/enums/profiles/ProfileMediaTypeEnum';
import { LessonComment } from '@/interfaces/lesson/LessonComment';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'LessonComment',
  props: {
    comment: {}
  },
  emits: ['edit', 'delete'],
  setup(__props: any) {

const props = __props

const userAuthenticated = JSON.parse(localStorage.getItem('authUser') ?? '')
const avatar = computed(() =>
  props.comment.author.media.find((m) => m.type === ProfileMediaTypeEnum.AVATAR)
);

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_Pencil = _resolveComponent("Pencil")!
  const _component_Trash2 = _resolveComponent("Trash2")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_RouterLink, {
        to: { name: 'profile', params: { profileId: _ctx.comment.author.id } }
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(Avatar), null, {
            default: _withCtx(() => [
              (avatar.value)
                ? (_openBlock(), _createBlock(_unref(AvatarImage), {
                    key: 0,
                    src: avatar.value.bucketLocation,
                    alt: _ctx.comment.author.name
                  }, null, 8, ["src", "alt"]))
                : _createCommentVNode("", true),
              _createVNode(_unref(AvatarFallback), null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.comment.author.name.split(' ').map(word => word.charAt(0).toUpperCase()).join('')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["to"]),
      _createElementVNode("div", null, [
        _createVNode(_component_RouterLink, {
          to: { name: 'profile', params: { profileId: _ctx.comment.author.id } }
        }, {
          default: _withCtx(() => [
            _createElementVNode("h6", _hoisted_3, _toDisplayString(_ctx.comment.author.name), 1)
          ]),
          _: 1
        }, 8, ["to"]),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.comment.content), 1)
      ])
    ]),
    (_ctx.comment.userId == _unref(userAuthenticated).userId)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(new Date(_ctx.comment.createdAt).toLocaleDateString()), 1),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("button", {
              class: "inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 w-10 rounded-lg",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('edit')))
            }, [
              _createVNode(_component_Pencil, { class: "w-4 h-4" })
            ]),
            _createElementVNode("button", {
              class: "inline-flex items-center justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 w-10 rounded-lg",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('delete')))
            }, [
              _createVNode(_component_Trash2, { class: "w-4 h-4" })
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})