import { Tenant } from "@/interfaces/tenants/Tenant"
import { useAxios } from "./useAxios"
import { computed, ref } from "vue"
import { TenantMediaTypeEnum } from "@/enums/tenants/media/TenantMediaTypeEnum"

const axios = useAxios()
const tenantCacheJson = localStorage.getItem('tenant')
const tenant = ref<Tenant>(tenantCacheJson ? JSON.parse(tenantCacheJson) : undefined)
const tenantLogo = computed(() => tenant.value?.media.find(m => m.type === TenantMediaTypeEnum.LOGO))

export function useTenant() {
  async function initializeTenant(): Promise<void> {
    if (!tenant.value) {
      const response = await axios.get('/tenant')
  
      localStorage.setItem('tenant', JSON.stringify(response.data))
  
      tenant.value = response.data
    }

    const favicon = tenant.value?.media.find(m => m.type === TenantMediaTypeEnum.FAVICON)

    if (favicon) {
      const faviconLink = document.createElement('link')
      faviconLink.href = favicon.bucketLocation
      faviconLink.rel = 'icon'

      document.head.appendChild(faviconLink)
    }
  }

  return {
    initializeTenant,
    tenant,
    tenantLogo
  }
}