import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-center p-8"
}
const _hoisted_2 = {
  key: 1,
  class: "relative"
}
const _hoisted_3 = ["src"]

import { HTMLAttributes, ref, watch } from 'vue'
import { Upload, X, Check, Pencil } from 'lucide-vue-next'


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageInput',
  props: {
    defaultPreview: {},
    width: { default: '18rem' },
    height: { default: '18rem' }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {


const emit = __emit

const file = ref<any>(null)
const preview = ref<string | null>(null)
const uploadProgress = ref(0)
const uploadComplete = ref(false)
const isDragActive = ref(false)
const fileInput = ref(null)

const onFileChange = (event: any) => {
  const selectedFile = event.target.files[0]
  if (selectedFile) {
    setFile(selectedFile)
  }
  event.target.value = ''
}

const setFile = (selectedFile: any) => {
  file.value = selectedFile
  preview.value = URL.createObjectURL(selectedFile)
  emit('update:modelValue', selectedFile)
  // simulateUpload()
}

// const simulateUpload = () => {
//   uploadProgress.value = 0
//   uploadComplete.value = false
//   const interval = setInterval(() => {
//     uploadProgress.value += 10
//     if (uploadProgress.value >= 100) {
//       clearInterval(interval)
//       uploadComplete.value = true
//     }
//   }, 300)
// }

const resetUpload = () => {
  file.value = null
  preview.value = null
  uploadProgress.value = 0
  uploadComplete.value = false
  emit('update:modelValue', null)
}

const dragenter = () => {
  isDragActive.value = true
}

const dragleave = () => {
  isDragActive.value = false
}

const drop = (event: any) => {
  isDragActive.value = false
  const droppedFile = event.dataTransfer.files[0]
  if (droppedFile && droppedFile.type.startsWith('image/')) {
    setFile(droppedFile)
  }
}

// Clean up object URL when component is unmounted
watch(preview, (newValue, oldValue) => {
  if (oldValue) {
    URL.revokeObjectURL(oldValue)
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "inline-flex",
    style: _normalizeStyle({ height: _ctx.height, width: _ctx.width })
  }, [
    _createElementVNode("div", {
      onDragenter: _withModifiers(dragenter, ["prevent"]),
      onDragleave: _withModifiers(dragleave, ["prevent"]),
      onDragover: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
      onDrop: _withModifiers(drop, ["prevent"]),
      class: _normalizeClass([
        'relative border-2 border-dashed rounded-lg transition-all ease-in-out duration-300 grid justify-center items-center',
        isDragActive.value ? 'border-primary bg-primary/10' : 'border-gray-300 hover:border-primary hover:bg-muted-1/2'
      ])
    }, [
      _createElementVNode("input", {
        type: "file",
        ref_key: "fileInput",
        ref: fileInput,
        onInput: onFileChange,
        accept: "image/png, image/jpg, image/jpeg, image/gif, image/avif",
        class: "hidden"
      }, null, 544),
      (!file.value && !_ctx.defaultPreview)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_unref(Upload), { class: "mx-auto h-12 w-12 text-gray-400" }),
            _cache[3] || (_cache[3] = _createElementVNode("p", { class: "mt-2 text-sm text-gray-600" }, " Arraste e solte uma imagem ou clique em selecionar ", -1)),
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$refs.fileInput.click()), ["prevent"])),
              class: "mt-4 px-4 py-2 bg-primary text-white rounded-md hover:bg-primary-dark transition-colors"
            }, " Selecionar ")
          ]))
        : _createCommentVNode("", true),
      (file.value || _ctx.defaultPreview)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("img", {
              src: preview.value ? preview.value : _ctx.defaultPreview,
              alt: "Preview",
              class: "object-cover rounded-md transition-all duration-300 ease-in-out hover:scale-105",
              style: _normalizeStyle({ height: _ctx.height, width: _ctx.width })
            }, null, 12, _hoisted_3),
            (preview.value)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  onClick: _withModifiers(resetUpload, ["prevent","stop"]),
                  class: "absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors"
                }, [
                  _createVNode(_unref(X), { class: "h-4 w-4" })
                ]))
              : (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$refs.fileInput.click()), ["prevent","stop"])),
                  class: "absolute top-2 right-2 p-1 bg-muted text-white rounded-full hover:bg-red-600 transition-colors"
                }, [
                  _createVNode(_unref(Pencil), { class: "h-4 w-4" })
                ]))
          ]))
        : _createCommentVNode("", true)
    ], 34)
  ], 4))
}
}

})