import { ref } from "vue";
import { useAxios } from "./useAxios";
import { Notification } from "@/interfaces/notifications/Notification";
import { Pagination } from "@/interfaces/Pagination";
import { NotificationStatusEnum } from "@/enums/notifications/NotificationStatusEnum";

const axios = useAxios()
let alreadyreadAllNotifications = false
const isLoadingNotifications = ref(false)
export const notifications = ref<Pagination<Notification>>()

export function useNotification() {
  async function initializeNotifications() {
    const response = await axios.get('/notifications')

    notifications.value = response.data
  }

  async function readNotification(id: number) {
    await axios.get(`/notifications/${id}`)
  }

  async function readAllNotifications() {
    if (!alreadyreadAllNotifications) {
      await axios.patch('/notifications/read')
      notifications.value!.data = notifications.value!.data.map((n) => ({ ...n, status: NotificationStatusEnum.READ }))

      alreadyreadAllNotifications = true
    }
  }

  async function getMoreNotifications(): Promise<Pagination<Notification>> {
    isLoadingNotifications.value = true

    const response = await axios.get('/notifications', { params: { page: notifications.value?.pagination.nextPage } })

    isLoadingNotifications.value = false
    
    return response.data
  }

  async function deleteAllNotifications() {
    await axios.delete('/notifications')
    notifications.value!.data = []
  }

  return {
    notifications,
    initializeNotifications,
    getMoreNotifications,
    readNotification,
    readAllNotifications,
    deleteAllNotifications,
    isLoadingNotifications
  }
}