import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "hidden lg:block w-[285px] pr-6 relative" }
const _hoisted_2 = { class: "space-y-2 sticky top-[100px]" }

import { useAuth } from '@/composables/useAuth';
import { useAxios } from '@/composables/useAxios';
import { AclEnum } from '@/enums/acl/AclEnum';
import { communities } from '@/states/communities';
import { FolderPlusIcon, GlobeIcon } from 'lucide-vue-next';
import { onMounted } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'FeedMenu',
  setup(__props) {

const { hasRoles } = useAuth()
const axios = useAxios()

function getCommunities() {
  axios.get('/courses/communities')
    .then((res) => {
      communities.value = res.data
    })
}

onMounted(() => {
  getCommunities()
})

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("aside", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createVNode(_component_RouterLink, {
        class: "inline-flex items-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2 w-full justify-start",
        to: { name: 'feed' },
        "active-class": 'bg-accent'
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(GlobeIcon)), { class: "h-5 w-5 mr-2" })),
          _cache[0] || (_cache[0] = _createTextVNode(" Explorar "))
        ]),
        _: 1
      }),
      (_unref(hasRoles)([_unref(AclEnum).PRODUCER, _unref(AclEnum).ADMIN]))
        ? (_openBlock(), _createBlock(_component_RouterLink, {
            key: 0,
            class: "inline-flex items-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2 w-full justify-start",
            to: { name: 'courses.communities.create' },
            "active-class": 'bg-accent'
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(FolderPlusIcon)), { class: "h-5 w-5 mr-2" })),
              _cache[1] || (_cache[1] = _createTextVNode(" Adicionar comunidade "))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_unref(communities))
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, _renderList(5, (i) => {
            return _createElementVNode("a", {
              key: i,
              class: "inline-flex h-10 px-4 py-2 w-full rounded-md animate-pulse bg-accent/20"
            })
          }), 64))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_unref(communities), (community) => {
            return (_openBlock(), _createBlock(_component_RouterLink, {
              class: "truncate inline-flex items-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2 w-full justify-start",
              "active-class": 'bg-accent',
              to: { name: 'courses.communities.show', params: { courseId: community.courseId, communityId: community.id } },
              key: community.id
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(community.name), 1)
              ]),
              _: 2
            }, 1032, ["to"]))
          }), 128))
    ])
  ]))
}
}

})